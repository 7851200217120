import moment from 'moment'

const utf8BOM = new Uint8Array([0xef, 0xbb, 0xbf])

function downloadCSV(
  csv,
  { filename = `export-${moment().format('YYYY-MM-DD')}.csv` } = {},
) {
  if (csv == null) return

  const blob = new window.Blob([utf8BOM, csv], {
    type: 'text/csv;charset=utf-8',
  })

  const data = window.URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.setAttribute('href', data)
  link.setAttribute('download', filename)
  link.click()
}

function downloadJSON(obj) {
  if (obj == null) return

  const filename = `export-${moment().format('YYYY-MM-DD')}.json`

  const blob = new window.Blob([utf8BOM, JSON.stringify(obj, null, 2)], {
    type: 'application/json;charset=utf-8',
  })

  const data = window.URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.setAttribute('href', data)
  link.setAttribute('download', filename)
  link.click()
}

function downloadBlob(blob) {
  if (blob == null) return

  const filename = `export-${moment().format('YYYY-MM-DD')}.xlsx`

  const data = window.URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.setAttribute('href', data)
  link.setAttribute('download', filename)
  link.click()
}

export { downloadCSV, downloadJSON, downloadBlob }
