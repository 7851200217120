import { gql } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { Form, Field } from 'react-final-form'
import { TextField, Checkbox } from 'final-form-material-ui'
import {
  DialogContent,
  FormControlLabel,
  Grid,
  DialogTitle,
  DialogActions,
  Button,
} from '@material-ui/core'

import CompanyAutoSuggest from '~/components/Companies/AutoSuggest'

import { GET_DEPOTS } from '~/components/Depots/queries'

const CREATE_DEPOT = gql`
  mutation CreateDepot($input: CreateRentalCompanyInput!) {
    createDepot: createRentalCompany(input: $input) {
      depot: rentalCompany {
        name
        id
      }
    }
  }
`

const ADD_DEPOT_TO_COMPANY = gql`
  mutation AddDepotToCompany($depotId: ID!, $companyId: ID!) {
    addDepotToCompany(companyId: $companyId, depotId: $depotId) {
      company {
        id
        name
        depots {
          id
          name
        }
      }
    }
  }
`

const CreateDepotForm = ({ client, onClose, companyId, isDemo, name }) => {
  const onSubmit = async (values) => {
    const {
      data: {
        createDepot: { depot },
      },
    } = await client.mutate({
      mutation: CREATE_DEPOT,
      variables: { input: { name: values.name, isDemo: values.isDemo } },
    })

    if (values.companyId) {
      await client.mutate({
        mutation: ADD_DEPOT_TO_COMPANY,
        variables: { depotId: depot.id, companyId: values.companyId },
        refetchQueries: [
          {
            query: GET_DEPOTS,
          },
        ],
      })
    }
    if (onClose) onClose()
  }
  return (
    <Form
      validate={(values) => {
        let errors = {}
        if (!values.name) {
          errors.name = 'Required'
        }
        if (!values.companyId && values.isDemo !== true) {
          errors.companyId = 'Required'
        }

        return errors
      }}
      initialValues={{
        name: name || '',
        isDemo: Boolean(isDemo || false),
        companyId: companyId || null,
      }}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        pristine,
        invalid,
        values,
        submitting,
        form,
      }) => (
        <form onSubmit={handleSubmit}>
          <DialogTitle>Create depot</DialogTitle>
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Field
                  render={({ input, meta }) => {
                    const showError =
                      ((meta.submitError && !meta.dirtySinceLastSubmit) ||
                        meta.error) &&
                      meta.touched

                    return (
                      <CompanyAutoSuggest
                        disabled={Boolean(companyId)}
                        companyId={input.value}
                        onChange={(companyId) =>
                          input.onChange({
                            target: { name: 'companyId', value: companyId },
                          })
                        }
                        error={showError && meta.error}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        fullWidth
                      />
                    )
                  }}
                  name="companyId"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  required
                  component={TextField}
                  variant="outlined"
                  name="name"
                  label="Name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Field type="checkbox" component={Checkbox} name="isDemo" />
                  }
                  label="Demo depot"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={invalid || submitting}
              type="submit"
              color="primary"
            >
              Create
            </Button>
          </DialogActions>
        </form>
      )}
    />
  )
}

export default withApollo(CreateDepotForm)
