import { useState } from 'react'

import Fab from '~/components/Mui/Fab'

import CreateProjectDialog from './Dialog'

const CreateProjectFab = ({ companyId, name, isDemo, isArchived }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <CreateProjectDialog
        companyId={companyId}
        name={name}
        isDemo={isDemo}
        isArchived={isArchived}
        open={open}
        onClose={() => setOpen(false)}
      />
      <Fab text="Create project" setOpen={setOpen} />
    </>
  )
}

export default CreateProjectFab
