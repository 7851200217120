import { gql } from '@apollo/client'

export const REMOVE_DEPOT_FROM_COMPANY = gql`
  mutation removeDepotFromCompany($companyId: ID!, $depotId: ID!) {
    removeDepotFromCompany(companyId: $companyId, depotId: $depotId) {
      company {
        id
        depots {
          id
          name
        }
      }
    }
  }
`

export const ADD_USER_TO_COMPANY = gql`
  mutation addUserToCompany(
    $companyId: ID!
    $userId: ID!
    $administrator: Boolean
  ) {
    addUserToCompany(
      companyId: $companyId
      userId: $userId
      administrator: $administrator
    ) {
      success
      message
      companyUser {
        administrator
        user {
          id
          name
        }
        company {
          name
        }
      }
    }
  }
`

export const EDIT_COMPANY_USER = gql`
  mutation editCompanyUser(
    $companyId: ID!
    $userId: ID!
    $administrator: Boolean
    $isRemoved: Boolean
  ) {
    editCompanyUser(
      companyId: $companyId
      userId: $userId
      administrator: $administrator
      isRemoved: $isRemoved
    ) {
      success
      companyUser {
        isRemoved
        administrator
        user {
          id
          name
        }
      }
    }
  }
`
