import { useCallback } from 'react'
import { AppBar, Toolbar, Hidden, IconButton, Tooltip } from '@material-ui/core'

import LogoutButton from './LogoutButton'

import { Menu as MenuIcon, Fullscreen, FullscreenExit } from 'mdi-material-ui'
import Breadcrumbs from './Breadcrumbs'
import { TunnelPlaceholder } from 'react-tunnels'
import useHoverIntent from '~/lib/useHoverIntent'

const ProjectAppBar = ({
  className,
  handleDrawerToggle,
  isFullscreen,
  toggleFullscreen,
}) => {
  const handlerIn = useCallback(() => handleDrawerToggle(), [])
  const handlerOut = useCallback(() => handleDrawerToggle(), [])
  const { ref } = useHoverIntent(
    { handlerIn, handlerOut },
    {
      timeout: 200,
      interval: 1,
      sensitivity: 10,
    },
  )
  return (
    <AppBar color="primary" className={className} elevation={1}>
      <Toolbar variant="dense">
        <Hidden lgUp={!isFullscreen} implementation="css">
          {handleDrawerToggle ? (
            <IconButton
              onClick={handleDrawerToggle}
              style={{ color: '#FFF' }}
              ref={ref}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
        </Hidden>
        <Tooltip title="Toggle fullscreen">
          <IconButton onClick={toggleFullscreen}>
            {isFullscreen ? (
              <FullscreenExit style={{ color: 'white' }} />
            ) : (
              <Fullscreen style={{ color: 'white' }} />
            )}
          </IconButton>
        </Tooltip>
        <Breadcrumbs />
        <div style={{ flex: 1 }} />
        <TunnelPlaceholder id="AppBar" />
        <div style={{ flex: 1 }} />

        {/* <SettingsButton /> */}

        <LogoutButton />
      </Toolbar>
    </AppBar>
  )
}

export default ProjectAppBar
