export default {
  indicatorContainer: (styles) => ({ ...styles, padding: '0' }),
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
  control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: '#fff',
    border: 0,
    borderBottom: isFocused ? '2px solid #000' : '2px solid #bbb',
    ':hover': { borderColor: '#000' },
    fontWeight: 'bold',
    borderRadius: 0,
    boxShadow: 0,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#fff'
        : isFocused
        ? '#eee'
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? '#000' > 2
          ? '#000'
          : '#000'
        : data.color,
      ':hover': {
        backgroundColor: '#eee',
        color: '#000',
      },
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: '#fff',
    }
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: '#555',
    ':hover': {
      backgroundColor: data.color,
      color: '#000',
    },
  }),
}
