import { useMutation, gql } from '@apollo/client'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Typography,
  Button,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Link } from 'react-router-dom'

const mutation = gql`
  mutation DisconnectProjectItemFromGlobalItem($projectItemId: ID!) {
    connectProjectItemsToGlobalItem(
      projectItemIds: [$projectItemId]
      globalItemId: null
    ) {
      success
      message
      projectItems {
        id
        globalItem {
          id
        }
        images(settings: { width: 100, height: 100 }) {
          id
          url
        }
      }
    }
  }
`

const DisconnectGlobalItem = ({ item, projectId, style }) => {
  const [mutate, { loading }] = useMutation(mutation, {
    refetchQueries: ['Item'],
    awaitRefetchQueries: true,
  })

  const disconnect = () => mutate({ variables: { projectItemId: item.id } })

  return (
    <Accordion style={style}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Global item</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {loading && <CircularProgress />}
          <Button
            style={{ borderColor: 'red', color: 'red' }}
            variant="outlined"
            disabled={!item.globalItem || loading}
            onClick={disconnect}
          >
            {item.globalItem
              ? 'Click here to disconnect global item'
              : 'No global item connected'}
          </Button>
          <br />
          <br />
          {projectId && (
            <Link
              to={`/global-items?projectId=${projectId}`}
              target="_BLANK"
              rel="noopener noreferrer"
            >
              <span>Open global items for project</span>
            </Link>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default DisconnectGlobalItem
