import { Query } from '@apollo/client/react/components'

import { GET_PROJECTS_HEAVY, GET_PROJECTS } from './queries'
import dotProp from 'dot-prop'

export default function withProjects(
  WrappedComponent,
  { skipLoading, includeArchivedProjects, query } = {},
) {
  return (props) => {
    const variables = {
      includeArchivedProjects: props.isArchived || includeArchivedProjects,
      ...props.variables,
    }
    return (
      <Query
        query={
          query
            ? query
            : variables.loadHeavyFields
            ? GET_PROJECTS_HEAVY
            : GET_PROJECTS
        }
        variables={variables}
      >
        {({ data, loading, client }) => {
          if (!skipLoading && (!data || loading)) return null

          return (
            <WrappedComponent
              projects={dotProp.get(data, 'projects', [])}
              {...props}
              loading={loading || props.loading}
            />
          )
        }}
      </Query>
    )
  }
}
