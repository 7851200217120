import { memo, useState, useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'
import InfiniteList from '../InfiniteList'
import withProjects from '~/components/Projects/withProjects'
import ProjectPicker from '~/components/Projects/auto-suggest'
import NumberOfImagelessProjectItems from '~/components/GlobalItems/NumberOfImagelessProjectItems'

import { useMatchContext } from '../MatchContext'
import {
  ListItem,
  ListItemText,
  Checkbox,
  Tooltip,
  IconButton,
  Button,
  Paper,
  Toolbar,
  Grid,
  ButtonGroup,
  Divider,
} from '@material-ui/core'
import { GET_PROJECTS_WITH_IMAGE_COUNT } from './queries'
import useDebounce from '~/lib/useDebounce'
import ImagesStatus from '../ImagesStatus'
import useProjectItems from '../useProjectItems'
import {
  Earth as GlobalItemIcon,
  Link as LinkIcon,
  LinkOff as LinkOffIcon,
} from 'mdi-material-ui'
import { useQueryParam, BooleanParam } from 'use-query-params'
import useGlobalItemProjectItems from '../../useGlobalItemProjectItems'
import GlobalItemFilter from './GlobaItemFilter'
import SearchField from '~/components/Mui/SearchField'

const ProjectsAutoSuggest = withProjects(ProjectPicker, {
  query: GET_PROJECTS_WITH_IMAGE_COUNT,
})

const Row = memo(({ index, style, data, matchContext = {} }) => {
  const {
    setProjectItemIds,
    projectItemIds,
    lastSelectedId,
    setLastSelectedId,
    showListImages,
    setGlobalItemsSearchString,
  } = matchContext

  const item = data[index]

  const selected = projectItemIds.includes(item.id)
  const onSelect = (event) => {
    let _selectedRowKeys = [...projectItemIds]
    const selected = _selectedRowKeys.includes(item.id)
    if (event.stopPropagation) {
      event.stopPropagation()
      event.preventDefault()
    }

    if (lastSelectedId && event.shiftKey) {
      const allIds = data.map(({ id }) => id)
      const start = allIds.findIndex((id) => id === lastSelectedId)
      const end = index

      const idsToMark =
        start < end
          ? allIds.slice(start, end + 1)
          : allIds.slice(end, start + 1)

      _selectedRowKeys = Array.from(new Set(_selectedRowKeys.concat(idsToMark)))
    } else {
      if (!selected) {
        _selectedRowKeys.push(item.id)
      } else {
        const index = _selectedRowKeys.indexOf(item.id)
        if (index > -1) {
          _selectedRowKeys.splice(index, 1)
        }
      }
    }
    window.requestAnimationFrame(() => {
      setLastSelectedId(item.id)
      setProjectItemIds(_selectedRowKeys)
      setGlobalItemsSearchString(item.shortDescription)
    })
  }

  const imgSrc =
    showListImages && item.images && item.images[0] ? item.images[0].url : null

  let descriptionPlusRentedFrom = null
  if (item.shortDescription) {
    descriptionPlusRentedFrom = item?.rentedFromCompany?.name
      ? `${item.shortDescription} (${item.rentedFromCompany.name})`
      : item.shortDescription
  }

  return (
    <div style={style} key={item.id}>
      <ListItem
        dense
        style={{ height: '100%' }}
        selected={selected}
        disableRipple
        button
        onClick={onSelect}
      >
        <input
          type="checkbox"
          checked={selected}
          onChange={onSelect}
          onClick={onSelect}
          style={{
            width: '18px',
            height: '18px',
            cursor: 'pointer',
            marginRight: '10px',
          }}
        />
        {showListImages && !imgSrc && (
          <div
            style={{
              width: '60px',
              height: '60px',
              background: imgSrc ? 'lightblue' : 'red',
              opacity: imgSrc ? 1 : 0.2,
              marginRight: '10px',
              borderRadius: '3px',
              border: '1px solid #ccc',
              overflow: 'hidden',
            }}
          ></div>
        )}
        {imgSrc && (
          <img
            alt={item.id}
            height="60px"
            src={imgSrc}
            style={{
              width: '60px',
              height: '60px',
              background: imgSrc ? 'lightblue' : 'red',
              opacity: imgSrc ? 1 : 0.2,
              marginRight: '10px',
              borderRadius: '3px',
              outline: '1px solid',
              overflow: 'hidden',
            }}
          />
        )}
        <ListItemText primary={descriptionPlusRentedFrom || <i>Unnamed</i>} />
        {item.globalItem ? (
          <GlobalItemIcon style={{ width: '20px', height: '20px' }} />
        ) : null}
        <ImagesStatus
          style={{ width: '20px', height: '20px' }}
          images={item.images}
        />
      </ListItem>
      <Divider />
    </div>
  )
})

const EmptyState = ({ children, hasFilter }) => (
  <div
    style={{
      padding: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      flexDirection: 'column',
    }}
  >
    <span
      style={{
        fontSize: '18px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: '#999',
      }}
    >
      {children || 'No items found'}
    </span>
    {hasFilter && <span style={{ fontSize: '12px' }}>Filter is on</span>}
  </div>
)

const ItemList = ({
  projectId,
  searchString,
  hasGlobalItem,
  showRootItemsOnly,
  filter,
  projectItemsFilter,
}) => {
  const matchContext = useMatchContext()
  const ClonedRow = useMemo(
    () => (props) => <Row matchContext={matchContext} {...props} />,
    [matchContext],
  )
  const { projectItems, fetchNextPage, pageInfo, loading } = useProjectItems({
    projectId,
    searchString,
    hasGlobalItem,
    showRootItemsOnly,
    filter,
    showOnlyRentedItems: true,
    reverseSort: true,
    sortOrder: 'created',
    ...projectItemsFilter,
  })
  if (!loading && projectItems.length === 0) {
    const hasFilter = Boolean(
      filter !== 'all_really' ||
        searchString ||
        !hasGlobalItem ||
        (projectItemsFilter && projectItemsFilter.globalItemId),
    )
    return <EmptyState hasFilter={hasFilter} />
  }
  return (
    <InfiniteList
      Row={ClonedRow}
      itemSize={80}
      data={projectItems}
      loadMoreItems={fetchNextPage}
      hasNextPage={pageInfo.hasNextPage}
    />
  )
}

export const AllItemsList = ({
  searchString,
  hasGlobalItem,
  showRootItemsOnly,
  projectItemsFilter,
  globalItemId,
  emptyStateText = null,
}) => {
  const matchContext = useMatchContext()

  const ClonedRow = useMemo(
    () => (props) => <Row matchContext={matchContext} {...props} />,
    [matchContext],
  )

  const { projectItems, fetchNextPage, pageInfo, loading } =
    useGlobalItemProjectItems({
      searchString,
      hasGlobalItem,
      showRootItemsOnly,
      ...projectItemsFilter,
      showOnlyRentedItems: true,
      globalItemId,
    })

  if (!loading && projectItems.length === 0) {
    const hasFilter = Boolean(
      searchString ||
        showRootItemsOnly ||
        (projectItemsFilter &&
          projectItemsFilter.globalItemId &&
          !globalItemId),
    )
    return <EmptyState hasFilter={hasFilter}>{emptyStateText}</EmptyState>
  }
  return (
    <InfiniteList
      Row={ClonedRow}
      itemSize={80}
      data={projectItems}
      loadMoreItems={fetchNextPage}
      hasNextPage={pageInfo.hasNextPage}
    />
  )
}

const FILTERS = new Map([
  ['all', { label: 'All' }],
  ['all_orders', { label: 'All orders' }],
  ['all_really', { label: 'All really' }],
  ['all_rented_in_app', { label: 'All rented in app' }],
  ['declined_orders', { label: 'Declined orders' }],
  ['due', { label: 'Past due' }],
  ['may_return', { label: 'Returnable' }],
  ['no_end', { label: 'Without end date' }],
  ['returned', { label: 'Returned' }],
  ['waiting_orders', { label: 'Waiting orders' }],
])

const ProjectItemsList = () => {
  const {
    projectItemIds,
    setProjectItemIds,
    projectId,
    setProjectId,
    projectItemsFilter,
  } = useMatchContext()

  const [browseAllItems = !projectId, setBrowseAllItems] = useQueryParam(
    'browseAllItems',
    BooleanParam,
  )

  const globalItemIdFilter =
    projectItemsFilter && projectItemsFilter.globalItemId

  const [filter, setFilter] = useState('all_really')
  const [hasGlobalItem, setHasGlobalItem] = useState(false)
  const [showRootItemsOnly, setShowRootItemsOnly] = useState(false)

  const [searchString, setSearchString] = useState('')
  const [debouncedSearchString, setDebouncedSearchString] = useDebounce(
    searchString,
    300,
  )
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Paper elevation={3} square>
        <Toolbar variant="dense">
          <Grid container justify="center">
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <Button
                variant={browseAllItems ? 'outlined' : 'contained'}
                onClick={() => setBrowseAllItems(false)}
              >
                Single project
              </Button>
              <Button
                variant={browseAllItems ? 'contained' : 'outlined'}
                onClick={() => setBrowseAllItems(true)}
              >
                All projects <NumberOfImagelessProjectItems />
              </Button>
            </ButtonGroup>
          </Grid>
        </Toolbar>
      </Paper>
      <GlobalItemFilter />
      {!browseAllItems && (
        <div style={{ margin: '15px', display: 'flex' }}>
          <ProjectsAutoSuggest onChange={setProjectId} value={projectId} />
        </div>
      )}
      <div style={{ margin: '15px', display: 'flex' }}>
        <SearchField
          variant="outlined"
          label="Search items"
          placeholder=""
          value={searchString}
          onChangeText={setSearchString}
          fullWidth
        />
        {!browseAllItems && (
          <select
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
          >
            {Array.from(FILTERS.entries()).map(([filter, { label }]) => (
              <option value={filter} key={filter}>
                {label}
              </option>
            ))}
          </select>
        )}
      </div>
      <div style={{ display: 'flex' }}>
        <ListItem dense style={{ paddingLeft: '8px' }}>
          <Checkbox
            color="secondary"
            checked={false}
            indeterminate={projectItemIds.length > 0}
            disabled={projectItemIds.length === 0}
            onChange={() => setProjectItemIds([])}
          />

          {!globalItemIdFilter && (
            <Tooltip
              title={
                hasGlobalItem
                  ? 'Hide items with global items'
                  : 'Show items with global items'
              }
              placement="left"
            >
              <IconButton onClick={() => setHasGlobalItem(!hasGlobalItem)}>
                {hasGlobalItem ? <LinkIcon /> : <LinkOffIcon />}
              </IconButton>
            </Tooltip>
          )}
          <Button onClick={() => setShowRootItemsOnly(!showRootItemsOnly)}>
            {showRootItemsOnly ? 'Show children' : 'Hide children'}
          </Button>
        </ListItem>
      </div>
      <div style={{ flex: 1, width: '100%' }}>
        {browseAllItems ? (
          <AllItemsList
            showRootItemsOnly={showRootItemsOnly}
            hasGlobalItem={!globalItemIdFilter ? hasGlobalItem : undefined}
            searchString={debouncedSearchString}
            projectItemsFilter={projectItemsFilter}
            globalItemId={globalItemIdFilter}
          />
        ) : projectId ? (
          <ItemList
            showRootItemsOnly={showRootItemsOnly}
            hasGlobalItem={!globalItemIdFilter ? hasGlobalItem : undefined}
            projectId={projectId}
            searchString={debouncedSearchString}
            filter={filter}
            projectItemsFilter={projectItemsFilter}
          />
        ) : (
          <EmptyState style={{ padding: '17px' }}>
            Select project or change to all items
          </EmptyState>
        )}
      </div>
    </div>
  )
}

export default ProjectItemsList
