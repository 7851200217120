import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from '@emotion/styled'

const StyledDiv = styled.div`
  border: 1px solid #ccc;
  padding: 15px;
  cursor: pointer;
  border-radius: 5px;
`

const FileDropArea = ({ onFiles, onDragLeave, onDragEnter }) => {
  const onDrop = (acceptedFiles) => {
    onFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          original: file,
        }),
      ),
    )
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDragLeave,
    onDrop,
    onDragEnter,
  })
  return (
    <StyledDiv {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
    </StyledDiv>
  )
}

export default FileDropArea
