import { createContext, useContext, useState } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import useLocalStorage from '~/lib/useLocalStorage'
import FilterParam from '~/lib/FilterParam'
const MatchContext = createContext()

export const useMatchContext = () => {
  const value = useContext(MatchContext)

  return value
}

export const MatchContextProvider = ({ children }) => {
  const [globalItemId, setGlobalItemId] = useQueryParam(
    'globalItemId',
    StringParam,
  )

  const [projectId, setProjectId] = useQueryParam('projectId', StringParam)
  const [
    projectItemsFilter = { globalItemId: undefined },
    setProjectItemsFilter,
  ] = useQueryParam('projectItemsFilter', FilterParam)

  const [showExtra, setShowExtra] = useLocalStorage(
    'global-items-show-extra',
    false,
  )
  const [showListImages, setShowListImages] = useLocalStorage(
    'global-items-show-list-images',
    true,
  )

  const [lastSelectedId, setLastSelectedId] = useState(null)
  const [projectItemIds, setProjectItemIds] = useState([])
  const [globalItemsSearchString, setGlobalItemsSearchString] = useState('')

  const toggleProjectItemId = (projectItemId) => {
    if (projectItemIds.includes(projectItemId)) {
      return setProjectItemIds(
        projectItemIds.filter((id) => id !== projectItemId),
      )
    }
    return setProjectItemIds([...projectItemIds, projectItemId])
  }

  return (
    <MatchContext.Provider
      value={{
        globalItemId,
        globalItemsSearchString,
        lastSelectedId,
        projectId,
        projectItemIds,
        projectItemsFilter,
        setGlobalItemId,
        setGlobalItemsSearchString,
        setLastSelectedId,
        setProjectId,
        setProjectItemIds,
        setProjectItemsFilter,
        setShowExtra,
        setShowListImages,
        showExtra,
        showListImages,
        toggleProjectItemId,
      }}
    >
      {children}
    </MatchContext.Provider>
  )
}

export default MatchContext
