import { gql } from '@apollo/client'

export const ALL_PROJECTS = gql`
  query allProjects {
    projects(includeArchivedProjects: true) {
      id
      name
    }
  }
`

export const PROJECT_RENTAL_COMPANIES = gql`
  query projectRentalCompanies($projectId: ID!) {
    project(id: $projectId) {
      rentalCompanies {
        id
        name
      }
    }
  }
`

export const INVALIDATE_ALL_COST_CACHES = gql`
  mutation invalidateAllCostCaches($projectId: ID) {
    invalidateAllCostCaches(projectId: $projectId) {
      success
      message
    }
  }
`

export const ITEM_COSTS_DATA = gql`
  query itemsCostsData(
    $projectId: ID!
    $startDate: String
    $endDate: String
    $rentalCompanyId: ID
    $showAsGroups: Boolean
  ) {
    project(id: $projectId) {
      id
      name
      eqlInvolvementTime
      costs {
        currency
        interval
      }
      costsAtStart: costs(date: $startDate) {
        before {
          amount
        }
      }
      costsAtEnd: costs(date: $endDate) {
        before {
          amount
        }
        daily {
          amount
        }
        sinceEqlInvolvementTime {
          amount
        }
        cachedAt
      }
      items(
        activeAfter: $startDate
        activeBefore: $endDate
        filter: all_really
        rentalCompanyId: $rentalCompanyId
        showRootItemsOnly: $showAsGroups
      ) {
        id
        shortDescription
        count
        start
        itemIdRc
        stop
        interval
        initialAmount
        intervalAmount
        numberOfCostPoints(beginTime: $startDate, endTime: $endDate)
        costsAtStart: costs(date: $startDate) {
          before {
            amount
          }
          daily {
            amount
          }
          intervals
        }
        costsAtEnd: costs(date: $endDate) {
          before {
            amount
          }
          daily {
            amount
          }
          intervals
          isActive
          cachedAt
        }
      }
    }
  }
`
