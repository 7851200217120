import { gql, useQuery } from '@apollo/client'

const NumberOfImagelessProjectItems = () => {
  const { data, loading } = useQuery(
    gql`
      query NumberOfImagelessProjectItems {
        imagelessEquipmentCount(hasImage: false)
      }
    `,
  )
  if (loading || !data) return null
  return <>{data.imagelessEquipmentCount}</>
}

export default NumberOfImagelessProjectItems
