import { gql } from '@apollo/client'

export const FullProjectFragment = gql`
  fragment FullProjectFragment on Project {
    id
    name
    companyName

    isDemo
    isArchived

    start
    stop
    eqlInvolvementTime

    projectCode

    createdBy {
      id
      name
      isSuperAdmin
    }

    createdAt

    archivedBy {
      id
      name
      isSuperAdmin
    }

    archivedAt

    coordinates {
      latitude
      longitude
    }
    itemSources {
      id
      rentalCompany {
        id
        name
      }
      active
      archived
      dataSource {
        name
        id
      }
    }
    grossFloorArea
    projectSize
    rentalBudget

    primaryCompany {
      id
      name
    }

    builderProjectIds
  }
`

export const ProjectUserConnectionsFragment = gql`
  fragment ProjectUserConnectionsFragment on Project {
    userConnections(includeFormerMembers: true) {
      isRemoved
      isMuted
      permissions {
        administrator
      }
      createdAt
      createdBy {
        id
        name
      }
      user {
        id
        name
        firstName
        lastName
        phoneNumbers
        email
        isSuperAdmin
        sessionsCount(projectId: $projectId)
        latestProjectUserEvent {
          project {
            id
            name
          }
          created
        }
      }
    }
  }
`
