import { Select, Input, MenuItem } from '@material-ui/core'

const DATA_SOURCE_TYPES = ['sheets', 'generic']
export const TypeSelect = ({
  values,
  setFieldTouched,
  handleChange,
  id,
  includeNone = false,
}) => {
  return (
    <Select
      id={id}
      name="type"
      value={values.type}
      onBlur={() => setFieldTouched('type', true)}
      required
      onChange={handleChange()}
      input={<Input id="type" value={values.type} />}
    >
      {includeNone && (
        <MenuItem value={''}>
          <em>None</em>
        </MenuItem>
      )}
      {DATA_SOURCE_TYPES.map((type) => (
        <MenuItem key={type} value={type}>
          {type}
        </MenuItem>
      ))}
    </Select>
  )
}
