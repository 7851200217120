import { Button } from '@material-ui/core'

import DangerDialog from './Dialog'

const DangerDelete = ({ entity, open, setOpen, onClick, loading }) => {
  return (
    <div className="px-4 py-8 w-full flex flex-row items-center justify-between">
      <div className="flex flex-col">
        <h4 className="m-0">Delete {entity}</h4>
        <p className="m-0">
          Deleting a {entity} is permanent and there's no way to recover it.
        </p>
      </div>
      <div className="text-red-500">
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => setOpen(true)}
        >
          Delete {entity}
        </Button>
      </div>
      <DangerDialog
        entity={entity}
        open={open}
        setOpen={setOpen}
        onClick={onClick}
        loading={loading}
      />
    </div>
  )
}

export default DangerDelete
