import { gql } from '@apollo/client'

export const GET_GLOBAL_ITEM = gql`
  query globalItem($id: ID!) {
    globalItem(id: $id) {
      id
      name
      images(settings: { width: 100, height: 100 }) {
        id
        url
      }
    }
  }
`
