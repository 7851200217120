import React, { useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TextField,
  Button,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import useUpdateProjectItems from '~/components/Items/hooks/useUpdateProjectItems'

const ChangeHasBeenReturned = ({ item }) => {
  const { updateProjectItems } = useUpdateProjectItems()

  const [returnClaimedAt, setReturnedClaimedAt] = useState('')

  const updateItem = async () => {
    try {
      await updateProjectItems({
        variables: {
          ids: [item.id],
          updates: {
            returnClaimedAt,
          },
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Change returned date</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          type="date"
          onChange={(e) => setReturnedClaimedAt(e.target.value)}
          defaultValue={item?.returnClaimedAt?.date || ''}
        />
        <Typography>{item?.projectItem?.returnClaimedBy?.name}</Typography>
        <Button onClick={updateItem}>Update return claimed at</Button>
      </AccordionDetails>
    </Accordion>
  )
}

export default ChangeHasBeenReturned
