import { gql } from '@apollo/client'
export const CREATE_DATA_SOURCE = gql`
  mutation CreateDataSource($input: CreateDataSourceInput!) {
    createDataSource(input: $input) {
      success
      message
      dataSource {
        id
        name
        type
        connectionString
        active
      }
    }
  }
`

export const UPDATE_DATA_SOURCE = gql`
  mutation updateDataSource($input: UpdateDataSourceInput!) {
    updateDataSource(input: $input) {
      success
      message
      dataSource {
        id
        name
        type
        connectionString
        active
        archived
      }
    }
  }
`

export const ADD_ITEMSOURCE_TO_PROJECT = gql`
  mutation addItemSourceToProject($input: AddItemSourceToProjectInput!) {
    addItemSourceToProject(input: $input) {
      id
      success
      message
    }
  }
`

export const UPDATE_ITEM_SOURCE = gql`
  mutation updateItemSource($input: UpdateItemSourceInput!) {
    updateItemSource(input: $input) {
      id
      success
      message
    }
  }
`
