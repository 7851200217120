import { useMemo, useState } from 'react'

import FuzzySearch from 'fuzzy-search'

export default function useFrontendSearch({ data, keys = ['name'] }) {
  const [searchString, setSearchString] = useState('')

  const searchResults = useMemo(() => {
    if (!searchString) return data

    const searcher = new FuzzySearch(data, keys, { sort: true })

    const results = searcher.search(searchString)

    return results
  }, [data, keys, searchString])

  return { searchString, setSearchString, searchResults }
}
