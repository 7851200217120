import { ImageOffOutline, Image, ImageMultiple } from 'mdi-material-ui'

const ImagesStatus = ({ images, style }) => {
  const noImages = !images || images.length === 0
  if (noImages) return <ImageOffOutline style={style} />
  if (images.length > 1) return <ImageMultiple style={style} />
  return <Image style={style} />
}

export default ImagesStatus
