import { useQuery } from '@apollo/client'
import { GET_DEPOTS } from './queries'
import dotProp from 'dot-prop'

export default function useDepots() {
  const { data, loading } = useQuery(GET_DEPOTS)
  const depots = dotProp.get(data, 'depots', [])

  return { depots, loading }
}
