import moment from 'moment'
import { getColor } from '~/components/Items/Table/cells/predictedStopRenderer'
import { useTranslation } from 'react-i18next'

const DateDisplay = ({ date, label, color }) => (
  <div>
    <span>{label}</span>
    <div
      className="w-32 h-8 flex items-center justify-center border border-solid border-gray-500 rounded-md"
      style={color ? { borderColor: color } : null}
    >
      <b style={{ color }}>{date ? moment(date).format('D MMM YYYY') : '-'}</b>
    </div>
  </div>
)

const getStopColor = (item) => {
  if (!item) return null
  return getColor(item, item.stop)
}

const ItemDates = ({ item }) => {
  const { t } = useTranslation('items')
  return (
    <div className="flex flex-row">
      <DateDisplay date={item && item.start} label={t('columns.start_date')} />
      <div className="w-4" />
      <DateDisplay
        date={item && item.stop}
        label={
          item
            ? item.isMarkedAsReturnable
              ? t('columns.was_done')
              : t('columns.end_date')
            : t('columns.date')
        }
        color={getStopColor(item)}
      />
      <div className="w-4" />
      <DateDisplay
        date={item && item.predictedStop}
        label={t('columns.predicted_stop')}
        color={getStopColor(item)}
      />
    </div>
  )
}

export default ItemDates
