import { gql } from '@apollo/client'

export const GET_DATA_SOURCES = gql`
  query DataSources(
    $offset: Int
    $limit: Int
    $sortOrder: DataSourceSortOrder
    $reverseSort: Boolean
    $searchString: String
    $showDeprecated: Boolean = true
    $filter: DataSourceType
    $archived: Boolean = false
  ) {
    dataSources(
      limit: $limit
      offset: $offset
      sortOrder: $sortOrder
      reverseSort: $reverseSort
      searchString: $searchString
      showDeprecated: $showDeprecated
      filter: $filter
      archived: $archived
    ) {
      id
      name
      type
      connectionString
      active
      archived
      lastGeneratedAt
      lastSyncedAt
      latestItemCreationAt
      lastImportWasSuccessful
      lastImportedMessage
    }
  }
`

export const GET_ALL_DATA_SOURCES = gql`
  query DataSources {
    dataSources(sortOrder: name) {
      id
      name
      type
      connectionString
      active
      projectsCount
      customersCount
      dataSourceItemsCount
      allowedRentalCompanies {
        id
        name
      }
    }
  }
`

export const GET_DATA_IMPORT_HANDLERS = gql`
  query getImportHandlers {
    dataImportHandlers {
      name
      folderName
      preventParallel
      schedule
      timezone
      nextImport
      nextImportTime
      prevImport
      prevImportTime
      prevPrevImport
      prevPrevImportTime
    }
  }
`
