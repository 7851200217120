import { gql } from '@apollo/client'

export const StandardDataSourceFragment = gql`
  fragment StandardDataSource on DataSource {
    id
    name
    type
  }
`

export const StandardUserFragment = gql`
  fragment StandardUser on User {
    id
    name
    firstName
    lastName
    initials
    email
    phoneNumbers
    profilePic(settings: { width: 100, height: 100 }) {
      id
      url
    }
  }
`

export const StandardTranslatedLogEntryFragment = gql`
  fragment StandardTranslatedLogEntry on TranslatedLogEntry {
    id
    markdown
    occurredAt
  }
`

export const ProjectItemCardFragment = gql`
  fragment ProjectItemCard on ProjectItem {
    id
    description
    count
    brand
    model
    canBeSplit
    canChangeHomePlace
    placeLastChangedAt
    locationLastReportedAt
    acquisitionDate
    acquisitionCost
    createdAt: created
    translatedLog(limit: 5) {
      id
      ...StandardTranslatedLogEntry
    }
    place {
      id
      name
      type
      visibility
    }
    homePlace {
      id
      name
      type
      visibility
    }
    item {
      id
      isSplittable
    }
    ownerType
    hasChildren
    children {
      id
      description
      brand
      model
      count
      itemIdRc
      sku
      parentId
      translatedLog(limit: 5) {
        id
        ...StandardTranslatedLogEntry
      }
      images(settings: { width: 220, height: 220 }) {
        id
        url
      }
      place {
        id
        name
        type
        visibility
      }
    }
    current {
      id
      description
      brand
      model
      images(settings: { width: 220, height: 220 }) {
        id
        url
      }
    }
    parentId
    itemIdRc
    serialNumber
    sku
    start
    stop
    images(settings: { width: 220, height: 220 }) {
      id
      url
      attachment {
        id
        entityType
      }
    }
    attachments {
      id
      name
      url
      extension
    }
    assignedUser {
      id
      ...StandardUser
    }
    comments {
      info {
        count
      }
    }
    interval
    intervalAmount
    costs {
      before {
        amount
      }
      daily {
        amount
      }
      comparable {
        amount
      }
      total {
        amount
      }
      interval
    }
    costsWithChildren: costs(includeCostsOfChildren: true) {
      before {
        amount
      }
      daily {
        amount
      }
      comparable {
        amount
      }
      total {
        amount
      }
      interval
    }
  }

  ${StandardTranslatedLogEntryFragment}
  ${StandardUserFragment}
`

export const ProjectItemTableFragment = gql`
  fragment ProjectItemTable on ProjectItem {
    id
    description
    count
    brand
    model
    canBeSplit
    canChangeHomePlace
    hasChildren
    placeLastChangedAt
    locationLastReportedAt
    ownerType
    children {
      id
      description
      brand
      model
      count
      itemIdRc
      sku
      parentId
      place {
        id
        name
        type
        visibility
      }
      images(settings: { width: 220, height: 220 }) {
        id
        url
        attachment {
          id
          entityType
        }
      }
    }
    current {
      id
      description
      brand
      model
      images(settings: { width: 220, height: 220 }) {
        id
        url
      }
    }
    place {
      id
      name
      type
      visibility
    }
    homePlace {
      id
      name
      type
      visibility
    }
    item {
      id
      isSplittable
    }
    parentId
    itemIdRc
    serialNumber
    sku
    start
    stop
    predictedStop
    rentalCompany {
      id
      name
      logo {
        id
        url
      }
    }
    images(settings: { width: 220, height: 220 }) {
      id
      url
    }
    assignedUser {
      id
      ...StandardUser
    }
    comments {
      info {
        count
      }
    }
  }

  ${StandardUserFragment}
`

export const StandardCompanyItemFragment = gql`
  fragment StandardCompanyItem on Item {
    id
    acquisitionCost
    acquisitionDate
    isSplittable
    projectItems {
      id
      description: description
      count
      assignedUser {
        id
        ...StandardUser
      }
      place {
        id
        name
        type
        visibility
      }
      homePlace {
        id
        name
        type
      }
    }
    places {
      id
      name
      type
      visibility
    }
    homePlace {
      id
      name
      type
    }
    attachments {
      id
      name
      url
      extension
    }
    brand
    children {
      id
      description
      brand
      quantity
      model
    }
    commentsAndInfo {
      info {
        count
      }
      comments {
        id
      }
    }
    company {
      id
      name
    }
    createdAt
    description
    id
    images(settings: { width: 220, height: 220 }) {
      id
      url
    }
    itemNumber
    model
    parent {
      id
      brand
      model
    }
    quantity
    serialNumber
  }

  ${StandardUserFragment}
`
