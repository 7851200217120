import { TextField, IconButton, InputAdornment } from '@material-ui/core'
import { Search as SearchIcon, Clear as ClearIcon } from '@material-ui/icons'

export default function SearchField({
  searchString,
  setSearchString,
  placeholder,
  fullWidth,
  appBarSearch,
  ...textFieldProps
}) {
  return (
    <TextField
      size="small"
      variant="outlined"
      placeholder={placeholder || 'Search..'}
      value={searchString}
      onChange={(event) => setSearchString(event.target.value)}
      InputProps={{
        classes: appBarSearch
          ? {
              root: 'bg-white text-black h-8',
            }
          : undefined,
        startAdornment: (
          <InputAdornment disablePointerEvents position="start">
            <SearchIcon className="opacity-50" />
          </InputAdornment>
        ),
        endAdornment: searchString && (
          <InputAdornment position="end" className="absolute right-2">
            <IconButton size="small" onClick={() => setSearchString('')}>
              <ClearIcon className="opacity-50" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      fullWidth={appBarSearch || fullWidth}
      {...textFieldProps}
    />
  )
}
