import { gql } from '@apollo/client'

export const PROJECT_ITEM_FRAGMENT = gql`
  fragment StandardProjectItem on ProjectItem {
    id
    costs(includeCostsOfChildren: true) {
      daily {
        amount
      }
      before {
        amount
      }
      total {
        amount
      }
      interval
    }
    needsReturnDate
    isPastDue
    shouldBeReturned
    predictedStop
    tags {
      id
      name
      color
    }
    returnInfo {
      done
      gone
      broken
      returnMessage
    }

    assignedUser {
      id
      name
      initials
      profilePic(settings: { width: 100, height: 100 }) {
        id
        url
      }
    }
  }
`
