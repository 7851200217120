import { gql } from '@apollo/client'

export const GET_PROJECTS_WITH_IMAGE_COUNT = gql`
  query Projects(
    $includeArchivedProjects: Boolean
    $includeDemoProjects: Boolean
  ) {
    projects(
      includeArchivedProjects: $includeArchivedProjects
      includeDemoProjects: $includeDemoProjects
    ) {
      id
      name
      withoutImages: itemsCount(
        hasImages: false
        filter: all_really
        ownerType: external
        showRootItemsOnly: false
      )
    }
  }
`
