import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { TextField, Select } from '@material-ui/core'
import { green } from '~/lib/colors'

const getDefaultSize = (value) => {
  if (value) {
    if (value >= 1000000) return '1000000'
    if (value >= 10000) return '1000'
    if (value < 100000) return '1'
  }

  return '1'
}

const SIZES = [
  ['1', 'KR'],
  ['1000', 'TKR'],
  ['1000000', 'MKR'],
]

function FFAmountField(props) {
  const {
    autoComplete,
    helperText,
    input: { name, value, onChange, ...input },
    InputProps,
    meta: { dirty, error, submitError, submitFailed },

    ...other
  } = props

  const inputRef = useRef(null)

  const [size, setSize] = useState(getDefaultSize(value))

  const handleSizeChange = (event) => {
    const nextSize = event.target.value

    setSize(nextSize)
    inputRef.current.focus()
  }

  const sizeValue = value ? value / size : ''

  return (
    <div
      css={{
        display: 'flex',
        '>div:first-of-type fieldset': {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
        '>div:last-of-type fieldset': {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeft: 0,
        },
        input: {
          textAlign: 'right',
          '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            appearance: 'none',
          },
        },
        select: { fontWeight: '500' },
      }}
    >
      <TextField
        error={Boolean((dirty || submitFailed) && (error || submitError))}
        {...input}
        {...other}
        css={
          dirty &&
          !error && {
            '>label': { color: green[700] },
            fieldset: { borderColor: green[700], borderWidth: '1.5px' },
          }
        }
        value={sizeValue}
        id={name}
        name={name}
        onChange={(event) => {
          const nextValue = event.target.value
          const realValue = nextValue * size
          onChange({ target: { value: realValue } })
        }}
        InputProps={{
          inputProps: {
            autoComplete,
          },
          ...InputProps,
        }}
        helperText={dirty || submitFailed ? error || submitError : helperText}
        inputRef={inputRef}
      />

      <Select
        style={{ minWidth: '80px', marginLeft: '-1px' }}
        native
        value={size}
        variant={other.variant}
        onChange={handleSizeChange}
      >
        {SIZES.map(([value, label]) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </Select>
    </div>
  )
}

FFAmountField.displayName = 'FFAmountField'

FFAmountField.propTypes = {
  autoComplete: PropTypes.string,
  helperText: PropTypes.node,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  InputProps: PropTypes.object,
  meta: PropTypes.shape({
    dirty: PropTypes.bool.isRequired,
    error: PropTypes.string,
    submitError: PropTypes.string,
    submitFailed: PropTypes.bool.isRequired,
  }).isRequired,
}

export default FFAmountField
