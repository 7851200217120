import BaseTable, { AutoResizer } from 'react-base-table'
import tailwindColors from 'tailwindcss/colors'

import { components } from './components'
import useSelectionColumn from './components/useSelectionColumn'

const DataGrid = (props) => {
  const {
    data,
    onRowSelect,
    onSelectedRowsChange,
    rowHeight = 72,
    disableCellPadding,
    ...rest
  } = props

  const columns = useSelectionColumn({ ...props })

  return (
    <AutoResizer
      css={{
        ...(disableCellPadding && {
          '.BaseTable__row-cell': {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
          },
        }),
        '.BaseTable__row': {
          '&:hover': {
            background: tailwindColors.gray[100],
          },
        },
        '.BaseTable__row.row-selected': {
          background: tailwindColors.gray[200],
          '&:hover': {
            background: tailwindColors.gray[100],
          },
        },
        '.BaseTable__row.row-child': {
          background: tailwindColors.gray[50],
          '&:hover': {
            background: tailwindColors.gray[100],
          },
        },
      }}
    >
      {({ width, height }) => (
        <BaseTable
          {...rest}
          width={width}
          height={height}
          data={data}
          columns={columns}
          rowHeight={rowHeight}
          onRowSelect={onRowSelect}
          onSelectedRowsChange={onSelectedRowsChange}
          components={components}
        />
      )}
    </AutoResizer>
  )
}

export default DataGrid
