import { useMemo, useCallback } from 'react'

const fallbackFormatter = { format: (value) => value }

const ONE_MILLION = 1000000

const useFormatAmount = () => {
  const numberFormatter = useMemo(() => {
    if (typeof window === 'undefined' || typeof window.Intl === 'undefined') {
      return fallbackFormatter
    }

    const lang = 'sv'

    return new Intl.NumberFormat(lang, {
      style: 'currency',
      currency: 'SEK',
      minimumFractionDigits: 0,
    })
  }, [])

  return useCallback(
    (value) => {
      if (value > 10) {
        value = Math.floor(value)
      }
      if (value >= ONE_MILLION) {
        return numberFormatter
          .format(value / ONE_MILLION)
          .replace('kr', 'Mkr')
          .replace('SEK', 'MSEK')
      }

      return numberFormatter.format(value)
    },
    [numberFormatter],
  )
}
export default useFormatAmount
