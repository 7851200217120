import { gql } from '@apollo/client'

export const GET_ITEM_SOURCES = gql`
  query ItemSources($projectId: ID!, $archived: Boolean = false) {
    itemSources(projectId: $projectId, archived: $archived) {
      id
      active
      syncType
      connectionString
      customerIds
      projectIds
      autoTags
      archived
      publicNote
      publicLastUpdatedAt
      rentalCompany {
        id
        name
      }
      syncLog(first: 1) {
        startedAt
        endedAt
        success
        errorMessage
      }
      dataSource {
        id
        name
        type
        importSessionStart
        dataImportStatus {
          id
          loggedAt
          lastUpdated
          status
          diagnostics {
            requiredFields
            recommendedFields
            ignoreRules
            rowsWithErrors
            missingFields {
              key
              value
            }
            invalidDateRows
            duplicateSyncIds
            errors
            warnings
            time
          }
        }
      }
    }
  }
`

export const SYNC_ITEM_SOURCE = gql`
  mutation syncItemSource($itemSourceId: ID!) {
    syncItemSource(itemSourceId: $itemSourceId) {
      success
      message
      itemSource {
        id
        latestSyncAttemptAt
        latestSyncSuccessAt
        consecutiveSyncFailures
        projectItemsCounts {
          visibleCount
        }
      }
    }
  }
`
