import { useQuery, gql } from '@apollo/client'
import dotProp from 'dot-prop'

const GET_PROJECT_FEATURES = gql`
  query withProjectFeatures($projectId: ID!) {
    project(id: $projectId) {
      id
      featureToggles {
        name
        enabled
      }
    }
  }
`

const useProjectFeatureToggles = (projectId) => {
  const { data } = useQuery(GET_PROJECT_FEATURES, {
    fetchPolicy: 'cache-first',
    variables: { projectId },
  })
  const featureToggles = dotProp.get(data, 'project.featureToggles', [])

  return featureToggles
    .filter((feature) => feature.enabled)
    .map((feature) => feature.name)
}

export default useProjectFeatureToggles
