import { Query } from '@apollo/client/react/components'

import { GET_PROJECT_ITEMS } from './queries'
import dotProp from 'dot-prop'

const formatData = (data = {}) => {
  const { project } = data
  return project && project.itemsPaginated
    ? project.itemsPaginated.edges.map((edge) => edge.node)
    : []
}

const updateQuery = (previousResult, { fetchMoreResult }) => {
  if (!previousResult || !previousResult.project) {
    console.warn('No previousResult')
    return fetchMoreResult
  }

  const newEdges = fetchMoreResult.project.itemsPaginated.edges
  const pageInfo = fetchMoreResult.project.itemsPaginated.pageInfo

  if (newEdges.length === 0) {
    console.warn('got 0', newEdges.length)
  }

  return newEdges.length
    ? {
        // Put the new comments at the end of the list and update `pageInfo`
        // so we have the new `endCursor` and `hasNextPage` values
        project: {
          ...previousResult.project,
          itemsPaginated: {
            __typename: previousResult.project.itemsPaginated.__typename,
            edges: [
              ...previousResult.project.itemsPaginated.edges,
              ...newEdges,
            ],
            pageInfo,
          },
        },
      }
    : previousResult
}

export default function withItems(WrappedComponent) {
  return function (props) {
    const {
      filter,
      limit,
      searchString,
      sortState = {},
      predictedStop,
      projectId,
      companyId,
      showHidden,
      showRootItemsOnly,
      showOnlyOwnItems,
      showOnlyRentedItems,
      ...restProps
    } = props

    const variables = {
      filter,
      searchString,
      predictedStop,
      projectId,
      companyId,
      first: limit || 50,
      showHidden,
      showRootItemsOnly,
      showOnlyOwnItems,
      showOnlyRentedItems,
      ...sortState,
    }

    return (
      <Query
        notifyOnNetworkStatusChange
        query={GET_PROJECT_ITEMS}
        variables={variables}
        skip={!projectId}
        fetchPolicy="cache-and-network"
      >
        {({ data, loading, fetchMore, networkStatus }) => {
          const pageInfo = dotProp.get(
            data,
            'project.itemsPaginated.pageInfo',
            null,
          )
          const fetchNextPage = async (
            { endCursor, hasNextPage } = pageInfo || {},
            limit,
          ) => {
            if (hasNextPage !== true) return
            const response = await fetchMore({
              variables: {
                ...variables,
                after: endCursor,
                limit: limit || variables.limit,
              },
              updateQuery,
            })

            return response
          }

          const fetchAllPages = async () => {
            if (pageInfo.hasNextPage === false) return
            let currentPageInfo = { ...pageInfo }
            while (currentPageInfo.hasNextPage === true) {
              const { data } = await fetchNextPage(currentPageInfo, 100)
              currentPageInfo = dotProp.get(
                data,
                'project.itemsPaginated.pageInfo',
                null,
              )
            }
          }

          return (
            <WrappedComponent
              fetchNextPage={fetchNextPage}
              fetchAllPages={fetchAllPages}
              items={formatData(data)}
              loading={loading}
              pageInfo={pageInfo}
              companyId={companyId}
              filter={filter}
              variables={variables}
              sortState={sortState}
              projectId={projectId}
              {...restProps}
            />
          )
        }}
      </Query>
    )
  }
}
