import { Component } from 'react'
import Button from '@material-ui/core/Button'
import dotProp from 'dot-prop'
import moment from 'moment'
import Papa from 'papaparse'
import { downloadCSV } from '~/lib/download'
import useFormatAmount from '~/lib/useFormatAmount'

class ExportCsvButton extends Component {
  state = { allFetched: false, exporting: false }
  export = async () => {
    this.setState({ exporting: true })
    while (this.props.pageInfo.hasNextPage) {
      await this.props.fetchMore()
    }
    this.setState({ allFetched: true })
    this.saveCsv()
    this.timeout = setTimeout(
      () => this.setState({ allFetched: false, exporting: false }),
      3000,
    )
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  saveCsv = () => {
    try {
      const { items, columns, formatAmount, project } = this.props
      const csv = Papa.unparse(
        items.map((item) => {
          const row = {}
          for (const column of columns) {
            if (column.cellDataGetter) {
              row[column.title] = column.cellDataGetter({
                rowData: item,
                dataKey: column.dataKey,
                isExport: true,
              })
            } else {
              row[column.title] = dotProp.get(item, column.dataKey)
            }

            if (column.type === 'date') {
              row[column.title] = row[column.title]
                ? moment(row[column.title]).format('YYYY-MM-DD')
                : ''
            } else if (column.type === 'amount') {
              row[column.title] = row[column.title]
            }
          }
          return row
        }),

        {
          delimiter: '\t',
          quoteChar: '"',
          quotes: true,
          newline: '\r\n',
        },
      )

      const filename =
        project && project.name
          ? `${project.name}-export-${moment().format('YYYY-MM-DD')}.csv`
          : `export-${moment().format('YYYY-MM-DD')}.csv`

      downloadCSV(csv, { filename })
    } catch (error) {
      window.alert('Download failed, check console')
      throw error
    }
  }
  renderText() {
    const { exporting, allFetched } = this.state
    if (!exporting) return 'Export'
    if (!allFetched) return 'Fetching all rows'
    if (allFetched) return 'All rows fetched, downloading CSV'
    return 'Export!'
  }
  render() {
    const { isLoading } = this.props

    return (
      <Button
        size="small"
        color="primary"
        variant="outlined"
        disabled={isLoading}
        onClick={this.export}
      >
        {this.renderText()}
      </Button>
    )
  }
}

export default (props) => {
  const formatAmount = useFormatAmount()
  return <ExportCsvButton {...props} formatAmount={formatAmount} />
}
