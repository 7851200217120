import { memo, useState, useMemo } from 'react'
import useGlobalItems from '../../useGlobalItems'
import {
  TextField,
  Button,
  ListItem,
  ListItemText,
  CircularProgress,
  InputAdornment,
  ListItemIcon,
  Paper,
  Toolbar,
  Grid,
  Divider,
} from '@material-ui/core'
import useDebounce from '~/lib/useDebounce'
import InfiniteList from '../InfiniteList'
import { useMatchContext } from '../MatchContext'
import CreateGlobalItemButton from '~/components/GlobalItem/Create/Button'

import ImagesStatus from '../ImagesStatus'

const breakTitle = (title) => {
  return title.length >= 45 ? title.slice(0, 42) + '...' : title
}

const Row = memo(({ index, style, data, isScolling, matchContext }) => {
  const { globalItemId, setGlobalItemId, showListImages } = matchContext
  const item = data[index]
  const selected = globalItemId === item.id
  const imgSrc =
    showListImages && item && item.images && item.images[0]
      ? item.images[0].url
      : null
  return (
    <div style={style} key={item.id}>
      <ListItem
        style={{ height: '100%' }}
        button
        selected={selected}
        onClick={() => setGlobalItemId(selected ? null : item.id)}
      >
        {showListImages && !imgSrc && (
          <div
            style={{
              width: '60px',
              height: '60px',
              background: imgSrc ? 'lightblue' : 'red',
              opacity: imgSrc ? 1 : 0.2,
              marginRight: '10px',
              borderRadius: '3px',
              border: '1px solid #ccc',
              overflow: 'hidden',
            }}
          ></div>
        )}
        {imgSrc && (
          <img
            alt={item.id}
            height="60px"
            src={imgSrc}
            style={{
              width: '60px',
              height: '60px',
              background: imgSrc ? 'lightblue' : 'red',
              opacity: imgSrc ? 1 : 0.2,
              marginRight: '10px',
              borderRadius: '3px',
              outline: '1px solid',
              overflow: 'hidden',
            }}
          />
        )}
        <ListItemText
          title={item.name}
          primary={breakTitle(item.name) || <i>Unnamed</i>}
        />

        <ImagesStatus images={item.images} />
      </ListItem>
      <Divider />
    </div>
  )
})
const GlobalItemsList = () => {
  const matchContext = useMatchContext()
  const { globalItemsSearchString, setGlobalItemsSearchString } = matchContext
  const { setGlobalItemId } = matchContext
  const ClonedRow = useMemo(
    () => (props) => <Row matchContext={matchContext} {...props} />,
    [matchContext],
  )
  const [debouncedSearchString, setDebouncedSearchString] = useDebounce(
    globalItemsSearchString,
    300,
  )
  const [globalItems, { loading, refetch, loadNextPage, hasNextPage }] =
    useGlobalItems({
      searchString: debouncedSearchString,
    })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Paper elevation={3} square>
        <Toolbar variant="dense">
          <Grid container>Global Items</Grid>
        </Toolbar>
      </Paper>
      <div style={{ margin: '15px', display: 'flex' }}>
        <TextField
          variant="outlined"
          label="Search Global Items"
          value={globalItemsSearchString}
          onChange={(event) => setGlobalItemsSearchString(event.target.value)}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" disablePointerEvents>
                {(loading ||
                  debouncedSearchString !== globalItemsSearchString) && (
                  <CircularProgress size={24} />
                )}
              </InputAdornment>
            ),
          }}
        />
        <div style={{ width: '15px ' }} />
        <CreateGlobalItemButton
          name={globalItemsSearchString}
          onDone={(globalItem) => {
            refetch().then(() => setGlobalItemId(globalItem.id))
          }}
        />
      </div>
      <div style={{ flex: 1, width: '100%' }}>
        <InfiniteList
          data={globalItems}
          itemSize={64}
          Row={ClonedRow}
          loadMoreItems={loadNextPage}
          hasNextPage={hasNextPage}
        />
      </div>
    </div>
  )
}

export default GlobalItemsList
