import { Button, Toolbar, IconButton, Tooltip } from '@material-ui/core'
import { useMatchContext } from '../MatchContext'
import dotProp from 'dot-prop'
import useGlobalItem from '~/components/GlobalItem/useGlobalItem'
import { Close } from 'mdi-material-ui'

const GlobalItemFilterButton = ({ setProjectItemsFilter, globalItemId }) => {
  const { globalItem } = useGlobalItem(globalItemId)
  console.log(globalItemId)
  return (
    <Toolbar>
      Showing items connected to:{' '}
      <b>{globalItem && globalItem.name ? globalItem.name : 'UNDEFINED'}</b>
      <Tooltip title="Remove global item filter">
        <IconButton onClick={() => setProjectItemsFilter({})}>
          <Close />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )
}

const GlobalItemFilter = () => {
  const { projectItemsFilter, setProjectItemsFilter } = useMatchContext()
  if (!projectItemsFilter || !projectItemsFilter.globalItemId) return null

  return (
    <GlobalItemFilterButton
      setProjectItemsFilter={setProjectItemsFilter}
      globalItemId={projectItemsFilter.globalItemId}
    />
  )
}

export default GlobalItemFilter
