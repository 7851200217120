import moment from 'moment'
import { Tooltip } from '@material-ui/core'

export const getColor = (item, date) => {
  const { isMarkedAsReturnable, hasBeenReturned } = item

  if (hasBeenReturned) return '#9B9B9B'

  if (isMarkedAsReturnable) return '#56C485'
  if (moment().isAfter(date)) return '#FF9200'
  return '#000000'
}

const predictedStopRenderer = ({ cellData: date, rowData: item }) => {
  if (!date) {
    return <span>-</span>
  }

  const color = getColor(item, date)
  const daysAgo = moment(date).fromNow()

  return (
    <Tooltip title={daysAgo}>
      <b style={{ color }}>{date}</b>
    </Tooltip>
  )
}

export default predictedStopRenderer
