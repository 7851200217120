import Select from 'react-select'

const ProjectPicker = ({ projects = [], value, excludeIds, onChange }) => {
  const selectedProject =
    value && projects.find((project) => project.id === value)

  return (
    <Select
      className="w-full z-10"
      styles={{
        control: (base) => ({
          ...base,
          height: 56,
          minHeight: '100%',
        }),
      }}
      isClearable
      value={
        selectedProject
          ? {
              value: selectedProject.id,
              label: `${selectedProject.name} (${selectedProject.withoutImages})`,
            }
          : null
      }
      onChange={(event) => {
        if (event && event.value) {
          onChange(event.value)
        } else {
          onChange(null)
        }
      }}
      placeholder="Select project..."
      options={projects
        .filter((project) =>
          excludeIds ? !excludeIds.includes(project.id) : true,
        )
        .map((project) => ({
          value: project.id,
          label: Number.isInteger(project.withoutImages)
            ? `${project.name} (${project.withoutImages})`
            : project.name,
        }))}
    />
  )
}

export default ProjectPicker
