import request from 'superagent'

const uploadAttachment = async (input) => {
  const file = input.original || input
  const endpoint = process.env.GATSBY_UPLOAD_ENDPOINT.replace(
    'upload',
    'upload-attachments',
  )
  const req = request
    .post(endpoint)
    .withCredentials()
    .set('Accept', 'application/json')

  req.attach(file.name, file)

  return new Promise((resolve, reject) => {
    req.end((error, res) => {
      if (error || !res.ok) {
        console.error('Oh no! Error: ' + JSON.stringify(error))
        reject(error)
      } else {
        const [attachment] = res.body.attachments
        resolve(attachment.id)
      }
    })
  })
}

export default uploadAttachment
