import { useState } from 'react'
import useSyncItemSource from './useSyncItemSource'
import { CircularProgress, Button } from '@material-ui/core'

const SyncItemSourceButton = ({ itemSource, disableSnackbars }) => {
  const [syncItemSource, { loading }] = useSyncItemSource()
  const [sending, setSending] = useState(null)
  const buttonContent =
    sending === itemSource.id ? <CircularProgress size={20} /> : 'Sync'
  return (
    <Button
      disabled={loading || !itemSource.active}
      color={'primary'}
      onClick={() => {
        syncItemSource(itemSource, setSending, { disableSnackbars })
      }}
    >
      {buttonContent}
    </Button>
  )
}

export default SyncItemSourceButton
