import { ReactNode } from 'react'

import {
  VanUtility,
  PackageVariantClosed as ContainerIcon,
  Crane as ProjectIcon,
  Warehouse as StorageIcon,
  HelpCircleOutline as UnknownIcon,
  MapMarkerOutline as OtherIcon,
} from 'mdi-material-ui'

import { PlaceType } from '~/types/generated/globalTypes'

export const placeTypes = Object.values(PlaceType).map((type) => {
  const unreachable = (type: never) => {
    throw new Error(`Unknown Place type: ${type}`)
  }

  let icon: ReactNode

  switch (type) {
    case PlaceType.CAR:
      icon = <VanUtility />
      break

    case PlaceType.CONTAINER:
      icon = <ContainerIcon />
      break

    case PlaceType.PROJECT:
      icon = <ProjectIcon />
      break

    case PlaceType.STORAGE:
      icon = <StorageIcon />
      break

    case PlaceType.OTHER:
      icon = <OtherIcon />

    case PlaceType.UNKNOWN:
      icon = <UnknownIcon />
      break

    default:
      unreachable(type)
  }

  return { type, icon }
})
