import { gql } from '@apollo/client'

export const ADD_ATTACHMENT_TO_PROJECT_ITEM = gql`
  mutation ($projectItemId: ID!, $attachmentId: ID!) {
    addAttachment(entityId: $projectItemId, attachmentId: $attachmentId) {
      success
      message
      projectItem {
        id
        attachments {
          id
          createdAt
          createdBy {
            id
            name
          }
          extension
          mimeType
          size
          id
          isDeleted
          name
          url
        }
      }
    }
  }
`
