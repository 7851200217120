import { useRef, useEffect } from 'react'
import hoverintent from 'hoverintent'

const useHoverIntent = ({ ref: _ref = null, handlerIn, handlerOut }, opts) => {
  const ref = useRef(_ref)

  useEffect(() => {
    if (ref.current) {
      // Save a reference to the method
      const hoverListener = hoverintent(ref.current, handlerIn, handlerOut)

      if (opts) {
        hoverListener.options(opts)
      }

      // Remove hoverintent listeners
      return hoverListener.remove
    }
  }, [ref, handlerIn, handlerOut, opts])

  return { ref }
}

export default useHoverIntent
