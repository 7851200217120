import { PureComponent, useCallback, useEffect, useMemo, useState } from 'react'

import { Checkbox } from '@material-ui/core'
import { Column } from 'react-base-table'

class SelectionCell extends PureComponent<any> {
  _handleChange = (e) => {
    const { rowData, rowIndex, column } = this.props
    const { onChange } = column

    onChange({
      selected: !this.checked,
      rowData,
      rowIndex,
      shiftKey: e.shiftKey,
    })
  }

  get checked() {
    const { rowData, column } = this.props
    const { selectedRowKeys, rowKey } = column
    return selectedRowKeys.includes(rowData[rowKey])
  }

  render() {
    if (this.props.rowData.parentId) return null
    return (
      <Checkbox
        size="small"
        color="primary"
        checked={this.checked}
        onClick={this._handleChange}
        style={{ padding: 0 }}
      />
    )
  }
}

export default function useSelectionColumn({
  data,
  columns,
  selectable,
  selectedRowKeys,
  fetchMore,
  isLoadingMore,
  setIsLoadingMore,
  onRowSelect,
  onSelectedRowsChange,
  setSelectedIds,
  resetSelectedIds,
  selectionColumnProps,
}) {
  const [lastClickedId, setLastClickedId] = useState(null)
  const [isSelectingAll, setIsSelectingAll] = useState(false)
  const [selectedAll, setSelectedAll] = useState(false)

  const handleSelectChange = useCallback(
    ({ selected, rowData, rowIndex, shiftKey }) => {
      let _selectedRowKeys = [...selectedRowKeys]
      const key = rowData.id

      if (lastClickedId && shiftKey) {
        const allIds = data.map((row) => row.id)
        const start = allIds.findIndex((id) => id === lastClickedId)
        const end = allIds.findIndex((id) => id === rowData.id)

        const idsToMark =
          start < end
            ? allIds.slice(start, end + 1)
            : allIds.slice(end, start + 1)

        if (!selected) {
          _selectedRowKeys = _selectedRowKeys.filter(
            (id) => !idsToMark.includes(id) && id !== rowData.id,
          )
        } else {
          _selectedRowKeys = [...new Set(_selectedRowKeys.concat(idsToMark))]
        }
      } else if (selected) {
        if (!_selectedRowKeys.includes(key)) _selectedRowKeys.push(key)
      } else {
        const index = _selectedRowKeys.indexOf(key)
        if (index > -1) {
          _selectedRowKeys.splice(index, 1)
        }
      }

      if (onRowSelect) {
        onRowSelect({ selected, rowData, rowIndex })
      }
      if (onSelectedRowsChange) {
        onSelectedRowsChange(_selectedRowKeys)
      }
      setLastClickedId(rowData.id)
    },
    [lastClickedId, onRowSelect, onSelectedRowsChange, data, selectedRowKeys],
  )

  const isSelecting = useMemo(
    () => Boolean(selectedRowKeys?.length),
    [selectedRowKeys],
  )

  const ids = useMemo(() => data?.map(({ id }) => id), [data])

  const deselectAll = useCallback(() => {
    resetSelectedIds()
    setSelectedAll(false)
  }, [])

  const selectAll = useCallback(async () => {
    const timeout = setTimeout(() => {
      setIsLoadingMore(true)
    }, 250)
    await fetchMore({ variables: { limit: 0, offset: ids?.length } })
    setIsSelectingAll(true)
    clearTimeout(timeout)
  }, [ids])

  useEffect(() => {
    if (isSelectingAll) {
      setSelectedIds(ids)
      setSelectedAll(true)
      setIsSelectingAll(false)
      setIsLoadingMore(false)
    }
  }, [ids, isSelectingAll])

  useEffect(() => {
    if (!isSelecting && selectedAll) {
      setSelectedAll(false)
    }
  }, [isSelecting, selectedAll])

  const selectionColumn = useMemo(() => {
    if (selectable) {
      return {
        width: 42,
        flexShrink: 0,
        resizable: false,
        frozen: Column.FrozenDirection.LEFT,
        cellRenderer: SelectionCell,
        headerRenderer: () => (
          <Checkbox
            size="small"
            color="primary"
            style={{ padding: 0 }}
            checked={selectedAll}
            disabled={isLoadingMore}
            onClick={isSelecting ? deselectAll : selectAll}
            indeterminate={isSelecting}
          />
        ),
        key: '__selection__',
        rowKey: 'id',
        selectedRowKeys,
        onChange: handleSelectChange,
        ...selectionColumnProps,
      }
    }
  }, [
    selectable,
    handleSelectChange,
    selectedRowKeys,
    isSelecting,
    selectedAll,
    deselectAll,
    selectAll,
  ])

  const _columns = useMemo(() => {
    if (selectionColumn) {
      return [selectionColumn, ...columns]
    }

    return columns
  }, [columns, selectionColumn])

  return _columns
}
