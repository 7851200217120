import PageHeader from '~/components/Layout/components/PageHeader'
import SearchField from '~/components/Mui/SearchField'
import { FormControlLabel, Switch } from '@material-ui/core'

const DataSourceProjectsHeader = ({
  heading,
  searchString,
  setSearchString,
  hideOld,
  setHideOld,
}) => {
  const setSwitch = (val) => {
    setHideOld(val)
  }
  return (
    <PageHeader
      title="Projects"
      toolbar={
        <>
          <FormControlLabel
            control={
              <Switch
                checked={hideOld}
                onChange={(prop) => {
                  setSwitch(!hideOld)
                }}
                value={hideOld}
                color="primary"
              />
            }
            label="Hide not seen within 30 days"
          />
          <SearchField
            value={searchString}
            style={{ marginTop: '4px' }}
            onChange={(event) => {
              const value = event.target.value
              setSearchString(value)
            }}
            placeholder="Search projects"
            autoFocus={true}
          />
        </>
      }
    />
  )
}

export default DataSourceProjectsHeader
