import { KeyboardDatePicker, Day } from '@material-ui/pickers'
import moment from 'moment'
import { green } from '~/lib/colors'

const minDate = moment('2015-01-01').toDate()

function FFDateField(props) {
  const {
    autoComplete,
    helperText,
    input: { name, value, ...input },
    InputProps,
    meta: { dirty, error, submitError, submitFailed, active },
    ...other
  } = props

  const text = dirty || submitFailed ? error || submitError : helperText
  const renderDay = (date, selectedDate, dayInCurrentMonth) => {
    return (
      <div
        css={{
          '.week': {
            position: 'absolute',
            fontSize: '0.6em',
            color: '#666',
          },
        }}
      >
        {date.day() === 1 && <div className={'week'}>{date.isoWeek()}</div>}
        <Day
          current={date.isSame(moment(), 'day')}
          hidden={!dayInCurrentMonth}
          selected={date.isSame(selectedDate, 'day')}
        >
          {date.date()}
        </Day>
      </div>
    )
  }
  return (
    <KeyboardDatePicker
      inputVariant="outlined"
      format="YYYY-MM-DD"
      placeholder="YYYY-MM-DD"
      name={name}
      autoOk
      renderDay={renderDay}
      minDate={minDate}
      css={
        dirty &&
        !error && {
          '>label': { color: green[700] },
          fieldset: { borderColor: green[700], borderWidth: '1.5px' },
        }
      }
      InputLabelProps={{
        shrink: active || Boolean(value),
      }}
      error={Boolean((dirty || submitFailed) && (error || submitError))}
      helperText={text || undefined}
      {...input}
      onChange={(m) => input.onChange(m.toISOString())}
      value={value || null}
      {...other}
    />
  )
}

FFDateField.displayName = 'FFDateField'

export default FFDateField
