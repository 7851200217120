import { useQuery } from '@apollo/client'
import { GET_COMPANY } from './queries'
import dotProp from 'dot-prop'

export default function useCompany({ companyId, fetchPolicy }) {
  const { data, loading } = useQuery(GET_COMPANY, {
    variables: { companyId },
    skip: !companyId,
    fetchPolicy,
  })
  const company = dotProp.get(data, 'company', {})
  return [company, { loading }]
}
