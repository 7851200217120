import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core'

const DangerDialog = ({ entity, open, setOpen, onClick, loading }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
      <DialogTitle>Confirm delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This action is permanent, are you sure?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="outlined"
          disabled={loading}
          onClick={onClick}
        >
          Delete {entity}
          {loading && (
            <div className="absolute flex items-center">
              <CircularProgress size={24} />
            </div>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DangerDialog
