import { encodeObject, decodeObject } from 'use-query-params'

/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
const FilterParam = {
  encode: (object) => encodeObject(object, ','),

  decode: (object) => decodeObject(object, ','),
}

export default FilterParam
