import dotProp from 'dot-prop'
import GlobalItemImage from '../../GlobalItem/GlobalItemImage'
import { ReactComponent as Symbol } from '~/assets/images/ib-symbol.svg'

const ItemImages = ({ item, onDelete }) => {
  const images = dotProp.get(item, 'images', [])
  if (images.length === 0) {
    return (
      <GlobalItemImage>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Symbol style={{ height: 30 }} />
        </div>
      </GlobalItemImage>
    )
  }

  return (
    <div style={{ display: 'flex' }}>
      {images.map((image) => (
        <GlobalItemImage
          key={image.id}
          id={image.id}
          url={image.url}
          onDelete={onDelete}
        />
      ))}
    </div>
  )
}

export default ItemImages
