import { Paper, IconButton } from '@material-ui/core'
import {
  DeleteOutline as DeleteIcon,
  MagnifyPlus as ZoomIcon,
} from 'mdi-material-ui'

const GlobalItemImage = ({ url, id, onDelete, onZoom, children }) => {
  return (
    <Paper
      css={{
        marginRight: '5px',
        marginBottom: '5px',
        border: '1px solid #005867',
        width: '100px',
        height: '100px',
        overflow: 'hidden',
        position: 'relative',
        '.overlay': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'rgba(0,0,0,.4)',
          transition: '300ms ease opacity',
          opacity: 0,
          '&:hover': {
            opacity: 1,
            pointerEvents: 'all',
          },
        },
      }}
      elevation={2}
    >
      {children || (
        <img
          style={{
            width: '100px',
            height: '100px',
          }}
          src={url}
        />
      )}
      {onDelete && (
        <div className="overlay">
          <IconButton onClick={(event) => onDelete({ event, url, id })}>
            <DeleteIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
      )}
      {onZoom && (
        <div className="overlay">
          <IconButton onClick={onZoom}>
            <ZoomIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
      )}
    </Paper>
  )
}

export default GlobalItemImage
