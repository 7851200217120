import { gql } from '@apollo/client'

export const FILTER_COUNTS = gql`
  query ItemFilterCounts($projectId: ID!, $showRootItemsOnly: Boolean) {
    project(id: $projectId) {
      id
      all: itemsCount(filter: all, showRootItemsOnly: $showRootItemsOnly)
      all_really: itemsCount(
        filter: all_really
        showRootItemsOnly: $showRootItemsOnly
      )
      due: itemsCount(filter: due, showRootItemsOnly: $showRootItemsOnly)
      may_return: itemsCount(
        filter: may_return
        showRootItemsOnly: $showRootItemsOnly
      )
      no_end: itemsCount(filter: no_end, showRootItemsOnly: $showRootItemsOnly)
      returned: itemsCount(
        filter: returned
        showRootItemsOnly: $showRootItemsOnly
      )
    }
  }
`

export const GET_PROJECT_ITEMS = gql`
  query itemsPaginated(
    $filter: SearchFilter
    $sortOrder: ItemSortOrder
    $searchString: String
    $projectId: ID!
    $after: String
    $reverseSort: Boolean
    $first: Int!
    $predictedStop: String
    $showRootItemsOnly: Boolean
    $showHidden: Boolean
    $showOnlyOwnItems: Boolean
    $showOnlyRentedItems: Boolean
  ) {
    project(id: $projectId) {
      id
      itemsPaginated(
        filter: $filter
        first: $first
        after: $after
        searchString: $searchString
        sortOrder: $sortOrder
        reverseSort: $reverseSort
        predictedStop: $predictedStop
        showRootItemsOnly: $showRootItemsOnly
        showHidden: $showHidden
        showOnlyOwnItems: $showOnlyOwnItems
        showOnlyRentedItems: $showOnlyRentedItems
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            shortDescription
            count
            start
            created
            actualStop
            stop
            predictedStop
            timeToFirstStopPrediction
            wasRentedInApp
            interval
            intervalAmount
            shouldBeReturned
            isHidden
            stockKeepingUnit
            itemIdRc
            sku
            syncId
            parentId
            tags {
              id
              name
              color
            }
            assignedUser {
              id
              initials
              name
            }
            itemSource {
              id
              dataSource {
                id
                name
                type
                connectionString
              }
            }
            rentalCompany {
              id
              name
              primaryCompany {
                id
                name
              }
            }
            project {
              id
              name
            }
            costs {
              before {
                format
                amount
              }
              after {
                format
                amount
              }
              total {
                format
                amount
              }
              daily {
                format
                amount
              }
              previousMonth {
                format
                amount
              }
              currentMonth {
                format
                amount
              }
            }
          }
        }
      }
    }
  }
`
