import { ReactElement, useCallback } from 'react'

import { IconButton } from '@material-ui/core'
import { ChevronRight } from 'mdi-material-ui'

export default function ExpandIcon({
  expandable,
  expanded,
  onExpand,
  indentSize,
  depth,
  ...rest
}): ReactElement {
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation()
      e.preventDefault()
      onExpand(!expanded)
    },
    [onExpand, expanded],
  )

  return (
    <IconButton
      {...rest}
      size="small"
      color="primary"
      onClick={expandable && onExpand ? handleClick : undefined}
      style={{
        transition: 'transform 0.15s ease-out',
        transform: `rotate(${expandable && expanded ? 90 : 0}deg)`,
        visibility: expandable ? 'visible' : 'hidden',
        marginRight: 8,
        padding: 0,
      }}
    >
      <ChevronRight />
    </IconButton>
  )
}
