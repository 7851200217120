import { forwardRef } from 'react'
import { NavLink as RRNavLink } from 'react-router-dom'

const NavLink = forwardRef(
  ({ to, children, isActive, color, activeColor, ...rest }, ref) => {
    if (!to) {
      throw new Error('NavLink needs "to" destination')
    }
    return (
      <RRNavLink
        css={{
          '.active': { color: color || '#145769' },
          borderRight: '4px solid transparent',
          'div>span': { fontWeight: '500', fontSize: '18px' },
        }}
        isActive={isActive}
        to={to}
        activeClassName="active"
        activeStyle={{
          color: '#145769',
          borderRightColor: activeColor || '#145769',
          backgroundColor: 'rgba(20,68,105,.08)',
        }}
        {...rest}
        innerRef={ref}
      >
        {children}
      </RRNavLink>
    )
  },
)

export default NavLink
