import { Fragment } from 'react'
import { useMatchContext } from '../MatchContext'
import {
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  Paper,
  Toolbar,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import useGlobalItem from '~/components/GlobalItem/useGlobalItem'
import GlobalItemImage from '~/components/GlobalItem/GlobalItemImage'
import EditGlobalItemButton from '~/components/GlobalItem/Edit/Button'

import { AllItemsList } from '../ProjectItemsList'
import { ChevronTripleLeft, ImageMove } from 'mdi-material-ui'

const Gallery = ({ images, editButton }) => {
  if (images.length === 0) {
    return (
      <Fragment>
        <b style={{ textTransform: 'uppercase', color: '#999' }}>No images</b>{' '}
        {editButton}
      </Fragment>
    )
  }
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {images.map(({ id, url }) => (
        <GlobalItemImage key={id} url={url} />
      ))}
    </div>
  )
}

const SelectedGlobalItem = () => {
  const {
    globalItemId,
    setProjectItemsFilter,
    setGlobalItemId,
    projectItemsFilter,
  } = useMatchContext()
  const { globalItem, loading } = useGlobalItem(globalItemId)

  if (!globalItemId) return null
  if (
    projectItemsFilter &&
    projectItemsFilter.globalItemId &&
    projectItemsFilter.globalItemId === globalItemId
  ) {
    return (
      <div style={{ padding: '15px 7px' }}>
        Select another global item to move items
      </div>
    )
  }

  if (loading) return <CircularProgress />

  if (!globalItem) return <div style={{ padding: '15px 7px' }}>Error</div>
  const editButton = <EditGlobalItemButton globalItem={globalItem} />

  return (
    <div
      style={{
        padding: '15px 0px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Card elevation={0}>
        <CardHeader
          title={
            <div style={{ display: 'flex' }}>
              <b>{globalItem.name}</b>
              <div style={{ flex: 1 }} />
              {editButton}
            </div>
          }
        ></CardHeader>
        <CardContent>
          <Gallery images={globalItem.images} editButton={editButton} />
        </CardContent>
      </Card>
      <Paper square elevation={2}>
        <Toolbar variant="dense" style={{ justifyContent: 'center' }}>
          <Tooltip title="Add this global item to project items list">
            <IconButton
              color="primary"
              variant="contained"
              onClick={() => {
                setProjectItemsFilter({ globalItemId: globalItem.id })
              }}
            >
              <ChevronTripleLeft />
            </IconButton>
          </Tooltip>
          <div style={{ width: '15px' }} />
          <Tooltip title="Open move connected items tool">
            <IconButton
              color="primary"
              variant="contained"
              onClick={() => {
                // setProjectItemsFilter({ globalItemId: globalItem.id })
                setGlobalItemId(null)
              }}
              component="a"
              href={`/global-items/${globalItemId}`}
              target="_BLANK"
              rel="noopener noreferrer"
            >
              <ImageMove />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </Paper>
      <div style={{ flex: 1 }}>
        <AllItemsList
          globalItemId={globalItem.id}
          emptyStateText={'No project items'}
        />
      </div>
    </div>
  )
}

export default SelectedGlobalItem
