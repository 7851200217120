import { useMutation, gql } from '@apollo/client'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Switch,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const mutation = gql`
  mutation SetProjectItemVisible($projectItemId: ID!, $isVisible: Boolean!) {
    hideProjectItem(projectItemId: $projectItemId, isVisible: $isVisible) {
      success
      message
    }
  }
`

const SetProjectItemHidden = ({ item }) => {
  const [mutate, { loading }] = useMutation(mutation, {
    refetchQueries: ['Item', 'itemsPaginated', 'ItemFilterCounts'],
    awaitRefetchQueries: true,
  })

  const toggle = () =>
    mutate({ variables: { projectItemId: item.id, isVisible: item.isHidden } })

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Hide/show item</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControlLabel
          control={
            <Switch
              disabled={loading}
              checked={item.isHidden || false}
              onClick={toggle}
            />
          }
          label={
            <>Hide project item {loading && <CircularProgress size={14} />}</>
          }
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default SetProjectItemHidden
