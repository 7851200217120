import { forwardRef } from 'react'

const Emoji = forwardRef(({ label, symbol, restProps }, ref) => (
  <span
    className="emoji"
    role="img"
    aria-label={label ? label : ''}
    title={label ? label : ''}
    aria-hidden={label ? 'false' : 'true'}
    {...restProps}
    ref={ref}
  >
    {symbol}
  </span>
))

export default Emoji
