import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'

import withItemFilter from '../withItemFilter'
import getRefetchQueries from './refetch-queries'
import { PROJECT_ITEM_FRAGMENT } from '~/components/Item/fragments'

const UPDATE_PROJECT_ITEMS = gql`
  mutation updateProjectItems(
    $ids: [ID!]!
    $updates: UpdateProjectItemsInput!
  ) {
    updateProjectItems(ids: $ids, updates: $updates) {
      success
      message
      projectItem {
        ...StandardProjectItem
      }
    }
  }
  ${PROJECT_ITEM_FRAGMENT}
`

export default function withActions(WrappedComponent) {
  return withItemFilter((props) => {
    return (
      <Mutation
        mutation={UPDATE_PROJECT_ITEMS}
        refetchQueries={getRefetchQueries(props)}
      >
        {(updateProjectItems, { loading, error }) => {
          return (
            <WrappedComponent
              updateProjectItems={updateProjectItems}
              loading={loading}
              error={error}
              {...props}
            />
          )
        }}
      </Mutation>
    )
  })
}
