import FileDropArea from './FileDropArea'

const FFDropArea = (props) => {
  const {
    preserve,
    input,
    meta: { dirty, error, submitError, submitFailed, active },
    ...other
  } = props

  const value = input.value.slice(0)
  return (
    <FileDropArea
      name={input.name}
      onDragEnter={() => input.onFocus()}
      onDragLeave={() => input.onBlur()}
      onFiles={(files) => {
        const nextFiles =
          value.length && preserve ? [...value, ...files] : Array.from(files)
        input.onChange(nextFiles)
        input.onBlur()
      }}
    />
  )
}
FFDropArea.displayName = 'FFDropArea'
export default FFDropArea
