import { gql } from '@apollo/client'

export const GET_ITEM = gql`
  query Item($itemId: ID!) {
    projectItem(id: $itemId) {
      id
      project {
        id
        name
        companyName
      }
      rentalCompany {
        id
        name
        primaryCompany {
          id
          name
        }
      }
      itemSource {
        id
        dataSource {
          id
          name
        }
      }
      children {
        id
        shortDescription
        count
        costs(includeCostsOfChildren: true) {
          daily {
            amount
          }
          interval
        }
      }
      attachments {
        createdAt
        createdBy {
          id
          name
        }
        extension
        id
        mimeType
        size
        name
        url
      }
      isHidden
      sku
      itemIdRc
      count
      shortDescription
      start
      stop
      returnClaimedAt {
        date
      }
      returnClaimedBy {
        id
        name
      }
      hasBeenReturned
      predictedStop
      isCancelled
      interval
      intervalAmount
      images(settings: { width: 100, height: 100 }) {
        id
        url
      }
      assignedUser {
        id
        name
        initials
        profilePic(settings: { width: 100, height: 100 }) {
          id
          url
        }
      }
      globalItem {
        id
        name
        images(settings: { width: 100, height: 100 }) {
          id
          url
        }
      }
      globalItemSuggestion {
        globalItem {
          id
          name
          images(settings: { width: 100, height: 100 }) {
            id
            url
          }
        }
        lastConnectedProjectItem {
          id
          description
          rentedFromCompany {
            id
            name
          }
        }
      }
      tags {
        id
        name
        color
      }
      parent {
        id
        description
      }
      rentedFromCompany {
        id
        name
      }
      createdAt: created
    }
  }
`
