import { Query } from '@apollo/client/react/components'

import { GET_ITEM } from './queries'
import dotProp from 'dot-prop'

export default function withItem(WrappedComponent, { skipLoading } = {}) {
  return (props) => {
    const { itemId } = props
    return (
      <Query query={GET_ITEM} variables={{ itemId }} skip={!itemId}>
        {({ data, loading, client }) => {
          if (loading && !skipLoading) return null

          return (
            <WrappedComponent
              item={dotProp.get(data, 'projectItem')}
              itemLoading={loading}
              {...props}
            />
          )
        }}
      </Query>
    )
  }
}
