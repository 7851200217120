import { Avatar, Chip, Tabs, Tab } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SwipeableViews from 'react-swipeable-views'
import moment from 'moment'

import { formatProjectName } from '~/containers/Project/lib/format-project-name'
import CopyToClipboardTooltip from '~/components/Mui/CopyToClipboardTooltip'
import useSelect from '~/lib/useSelect'

import SetProjectItemHidden from '../Tools/SetProjectItemHidden'
import DisconnectGlobalItem from '../Tools/DisconnectGlobalItem'
import ChangeHasBeenReturned from '../Tools/ChangeHasBeenReturned'
import ImageTools from '../Tools/ImageTools'
import getTranslatedReason from '../lib/getTranslatedReason'
import ItemChildren from './Children'
import ItemAttachments from './Attachments'
import Cost from './Cost'
import DangerZone from '~/components/DangerZone'

const constructionWeb = process.env.GATSBY_CONSTRUCTION_WEB_EMBED

const ItemTabs = ({ tabs, index, setIndex }) => (
  <Tabs
    className="px-4 bg-primary-light text-white"
    indicatorColor="secondary"
    textColor="inherit"
    value={index}
    onChange={(event, index) => setIndex(index)}
  >
    {tabs.map((tab, idx) => (
      <Tab key={idx} {...tab} />
    ))}
  </Tabs>
)

const Wrapper = ({ children, style, className }) => (
  <div
    css={{
      '>div': {
        backgroundColor: 'rgba(20,68,105,.00)',
      },
      '>div:nth-of-type(even)': {
        backgroundColor: 'rgba(20,68,105,.08)',
      },
    }}
    style={style}
  >
    {children}
  </div>
)

const LabelValue = ({ label, value, copyable }) => (
  <div className="flex flex-row items-center justify-start p-4">
    <span>{label}</span>
    <div className="w-4" />
    <b>{copyable && value ? <CopyToClipboardTooltip text={value} /> : value}</b>
  </div>
)

const AssignedUser = ({ user }) => {
  return user ? (
    <Chip
      variant="outlined"
      avatar={
        user.profilePic ? (
          <Avatar alt={user.name} src={user.profilePic.url} />
        ) : (
          <Avatar>{user.initials}</Avatar>
        )
      }
      label={
        <Link to={`/users/${user.id}`} target="_BLANK">
          {user.name}
        </Link>
      }
    />
  ) : (
    '-'
  )
}

const ReturnInfo = ({ item, t }) => {
  const { t: itemsT } = useTranslation('items')
  if (
    !(item.isMarkedAsReturnable || item.hasBeenReturned) ||
    !item.returnInfo
  ) {
    return null
  }
  const reason = getTranslatedReason(itemsT, item)
  const message = item.returnInfo.returnMessage
  const date = item.stop && moment(item.stop).format('YYYY-MM-DD HH:mm:ss')

  return (
    <>
      <LabelValue label={t('return_confirmed')} value={date || '-'} />
      <LabelValue label={t('return_reason')} value={reason || '-'} />
      <LabelValue label={t('return_message')} value={message || '-'} />
    </>
  )
}

const GlobalItemLink = ({ item }) => {
  const globalItem = item.globalItem
  if (!globalItem) return '-'
  return (
    <Link to={`/global-items?globalItemId=${globalItem.id}`} target="_BLANK">
      {globalItem.name}
    </Link>
  )
}

const ItemDetails = ({ depotId, item = {}, projectId }) => {
  const { t } = useTranslation('item')
  const tabs = [
    { label: t('information') },
    {
      label: t('children'),
    },
    { label: 'Tools' },
    { label: 'Comments' },
    { label: 'Attachments' },
  ]
  console.log(item)
  const { index, setIndex } = useSelect(tabs, 0)
  return (
    <>
      <ItemTabs tabs={tabs} index={index} setIndex={setIndex} />
      <SwipeableViews index={index}>
        <Wrapper>
          <LabelValue
            label={t('description')}
            copyable={item.shortDescription}
            value={item.shortDescription || '-'}
          />
          <LabelValue
            label={t('responsible')}
            value={<AssignedUser user={item.assignedUser} />}
          />
          <LabelValue
            label={t('project')}
            value={
              item && item.project ? (
                <Link
                  to={
                    item &&
                    item.project && {
                      pathname: `/projects/${item.project.id}`,
                    }
                  }
                >
                  {formatProjectName(item.project)}
                </Link>
              ) : (
                '-'
              )
            }
          />
          <LabelValue label={t('count')} value={item.count} />
          <LabelValue
            label={t('sku')}
            copyable={item.sku}
            value={item.sku || '-'}
          />
          <LabelValue
            label={t('item_id_rc')}
            copyable={item.itemIdRc}
            value={item.itemIdRc || '-'}
          />
          <LabelValue
            label={t('tags')}
            value={
              <div className="flex flex-row flex-wrap">
                {Array.isArray(item.tags)
                  ? item.tags.map((tag) => (
                      <span
                        key={tag.id}
                        style={{
                          color: tag.color,
                        }}
                        className="pr-1"
                      >
                        {tag.name}
                      </span>
                    ))
                  : '-'}
              </div>
            }
          />
          <LabelValue label={t('cost_per_unit')} value={<Cost item={item} />} />
          <LabelValue
            label="Global Item"
            value={<GlobalItemLink item={item} />}
          />
          <LabelValue
            label={t('hidden')}
            value={item.isHidden ? 'Yes' : 'No'}
          />
          <LabelValue
            label={t('returned')}
            value={item.hasBeenReturned ? 'Yes' : 'No'}
          />
          <LabelValue
            label={t('returnable')}
            value={item.shouldBeReturned ? 'Yes' : 'No'}
          />
          <ReturnInfo item={item} t={t} />
        </Wrapper>

        <Wrapper>
          <ItemChildren item={item} />
        </Wrapper>

        <Wrapper style={{ padding: '15px', maxWidth: '700px' }}>
          <SetProjectItemHidden item={item} />
          <ImageTools item={item} />
          <DisconnectGlobalItem item={item} projectId={projectId} />
          <ChangeHasBeenReturned item={item} />
        </Wrapper>

        <CommentsFrame itemId={item.id} projectId={projectId} />
        <Wrapper>
          <ItemAttachments item={item} />
        </Wrapper>
      </SwipeableViews>
    </>
  )
}

const CommentsFrame = ({ itemId, projectId }) => {
  if (!constructionWeb) {
    return (
      <DangerZone>
        You need to add GATSBY_CONSTRUCTION_WEB_EMBED to env, check example
      </DangerZone>
    )
  }

  if (!projectId) return null

  const src = `${constructionWeb}embed/comments?${encodeURI(
    `projectId=${projectId}&entityId=${itemId}&entityType=ProjectItem&isNativeApp=1`,
  )}`
  const href = `${constructionWeb}projects/${projectId}/items/${itemId}?filter=all&tabKey=comments&tab=2`

  return (
    <div className="w-full h-full p-8 flex flex-col max-w-xl">
      <iframe title="Comments" src={src} className="flex-1" />
      <a href={href} target="_BLANK">
        Open in Web App
      </a>
    </div>
  )
}

export default ItemDetails
