import { useMutation, gql } from '@apollo/client'

const CONNECT_ITEMS = gql`
  mutation connectProjectItemsToGlobalItem(
    $globalItemId: ID
    $projectItemIds: [ID!]!
  ) {
    connectProjectItemsToGlobalItem(
      projectItemIds: $projectItemIds
      globalItemId: $globalItemId
    ) {
      success
      message
      globalItem {
        id
        name
      }
      projectItems {
        id
        shortDescription
        images {
          id
          url
        }
        globalItem {
          id
          name
        }
      }
    }
  }
`

const useConnectProjectItemsToGlobalItem = ({
  refetchQueries = [],
  awaitRefetchQueries = false,
} = {}) => {
  const [mutate, { loading }] = useMutation(CONNECT_ITEMS, {
    refetchQueries,
    awaitRefetchQueries,
  })

  const connectProjectItemsToGlobalItem = async ({
    globalItemId,
    projectItemIds,
  }) => {
    await mutate({ variables: { globalItemId, projectItemIds } })
  }

  return [connectProjectItemsToGlobalItem, { loading }]
}

export default useConnectProjectItemsToGlobalItem
