import { useState, useMemo } from 'react'
import NewDataGrid from '~/components/NewDataGrid'
import EditProjectMenu from '~/components/Project/Edit/Menu'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Tabs, Tab, Toolbar, CircularProgress } from '@material-ui/core'
import { AllProjectsSortOrder } from '~/types/generated/globalTypes'

const getProjectPathname = ({ projectId }) => {
  return `/projects/${projectId}`
}

const useColumns = ({ includeArchivedProjects, companyId }) => {
  const { t } = useTranslation('projects')

  const userLinkRenderer = ({ rowData: project, cellData: label }) =>
    project.userConnectionsCount ? (
      <Link to={{ pathname: `/projects/${project.id}/users` }}>{label}</Link>
    ) : (
      label
    )

  const projectLinkRenderer = ({ rowData: project, cellData: label }) => (
    <Link to={{ pathname: getProjectPathname({ projectId: project.id }) }}>
      {label}
    </Link>
  )

  const companyLinkRenderer = ({ rowData: project, cellData: label }) =>
    project.primaryCompany ? (
      <Link to={{ pathname: `/companies/${project.primaryCompany.id}` }}>
        {label}
      </Link>
    ) : (
      label
    )

  const columns = [
    {
      key: AllProjectsSortOrder.name,
      dataKey: 'name',
      title: t('columns.name'),
      cellRenderer: projectLinkRenderer,
      width: 256,
      maxWidth: 512,
      sortable: true,
    },
    {
      key: 'projectCode',
      dataKey: 'projectCode',
      title: t('columns.projectCode'),
      width: 128,
    },
    {
      key: 'builderProjectIds',
      dataKey: 'builderProjectIds',
      title: t('columns.ids'),
      cellRenderer: ({ cellData }) => (cellData ? cellData.join(',') : ''),
      width: 128,
    },
    {
      key: 'primaryCompany.name',
      dataKey: 'primaryCompany.name',
      title: t('columns.company_name'),
      cellRenderer: companyLinkRenderer,
      width: 128,
    },
    {
      key: 'userConnectionsCount',
      dataKey: 'userConnectionsCount',
      title: t('columns.user_connections_count'),
      cellRenderer: userLinkRenderer,
      width: 96,
    },
    {
      key: AllProjectsSortOrder.created_at_desc,
      dataKey: 'createdAt',
      title: t('columns.createdAt'),
      type: 'date',
      width: 128,
      sortable: true,
    },
    includeArchivedProjects && {
      key: 'archivedAt',
      dataKey: 'archivedAt',
      title: 'Archived at',
      type: 'date',
      width: 128,
    },
    {
      key: 'eqlInvolvementTime',
      dataKey: 'eqlInvolvementTime',
      title: t('columns.eqlInvolvementTime'),
      type: 'date',
      width: 128,
    },
    {
      key: 'stop',
      dataKey: 'stop',
      title: t('columns.stop'),
      type: 'date',
      width: 128,
    },
    {
      key: 'edit',
      dataKey: 'edit',
      align: 'right',
      flexGrow: 1,
      title: null,
      width: 50,
      cellRenderer: ({ rowData }) => (
        <EditProjectMenu project={rowData} companyId={companyId} />
      ),
    },
  ].filter(Boolean)

  return columns
}

export function ProjectsTable({
  projects,
  includeArchivedProjects,
  companyId,
  fetchNextPage,
  sortState,
  setSortState,
}) {
  const hasToolbar = includeArchivedProjects

  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)

  const filteredProjects = useMemo(() => {
    return includeArchivedProjects
      ? tabIndex === 1
        ? projects.filter((project) => project.archivedAt)
        : projects.filter((project) => !project.archivedAt)
      : projects
  }, [projects, includeArchivedProjects, tabIndex])

  const columns = useColumns({ includeArchivedProjects, companyId })

  const overlayRenderer = () => {
    if (isLoadingMore) {
      return (
        <div className="absolute bottom-8 left-1/2 -translate-x-1/2 pointer-events-none select-none">
          <div className="flex items-center justify-center space-x-2 p-2 bg-gray-50 rounded-lg shadow-md">
            <CircularProgress size={16} />
            <span className="font-semibold">Loading more...</span>
          </div>
        </div>
      )
    }
  }

  const handleEndReached = async () => {
    if (!fetchNextPage) {
      return
    }

    setIsLoadingMore(true)
    await fetchNextPage()
    setIsLoadingMore(false)
  }

  return (
    <div className="h-full w-full flex-1">
      {hasToolbar ? (
        <Toolbar variant="dense">
          <Tabs
            onChange={(event, tabIndex) => setTabIndex(tabIndex)}
            value={tabIndex}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Ongoing" />
            <Tab label="Archived" />
          </Tabs>
        </Toolbar>
      ) : null}
      <div style={{ height: hasToolbar ? 'calc(100% - 48px)' : '100%' }}>
        <NewDataGrid
          data={filteredProjects}
          columns={columns}
          sortBy={sortState}
          onColumnSort={setSortState}
          isLoadingMore={isLoadingMore}
          setIsLoadingMore={setIsLoadingMore}
          fetchNextPage={fetchNextPage}
          onEndReachedThreshold={500}
          onEndReached={handleEndReached}
          overlayRenderer={overlayRenderer}
        />
      </div>
    </div>
  )
}
