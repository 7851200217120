import { useMutation, gql } from '@apollo/client'
import { Form, Field } from 'react-final-form'
import { TextField, Checkbox } from 'final-form-material-ui'
import {
  DialogContent,
  FormControlLabel,
  Grid,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import CompanyAutoSuggest from '~/components/Companies/AutoSuggest'
import { GET_PROJECTS } from '~/components/Projects/queries'

const CREATE_PROJECT = gql`
  mutation CreateProject($companyId: ID!, $input: CreateProjectInput!) {
    createProject(companyId: $companyId, input: $input) {
      project {
        name
        id
      }
    }
  }
`
const ADD_PROJECT_TO_COMPANY = gql`
  mutation AddProjectToCompany($projectId: ID!, $companyId: ID!) {
    addProjectToCompany(companyId: $companyId, projectId: $projectId) {
      success
      company {
        id
        name
        projects {
          id
          name
        }
      }
    }
  }
`

const CreateProjectForm = ({ companyId, isDemo, name, isArchived }) => {
  const [createProject, { loading: loadingCreateProject }] =
    useMutation(CREATE_PROJECT)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const refetchQuery = {
    query: GET_PROJECTS,
    variables: {
      includeDemoProjects: isDemo,
      includeArchivedProjects: isArchived,
    },
  }
  const onSubmit = async (values) => {
    if (!values.companyId) {
      // This should not happen with Create disabled, but just in case. :)
      enqueueSnackbar('Please select a company', { variant: 'error' })
      return
    }
    const {
      data: {
        createProject: { project },
      },
    } = await createProject({
      variables: {
        companyId: values.companyId,
        input: { name: values.name, isDemo: values.isDemo },
      },
      refetchQueries: values.companyId ? [] : [refetchQuery],
    })

    history.push(`/projects/${project.id}/edit`)
  }

  const loading = loadingCreateProject
  return (
    <Form
      validate={(values) => {
        let errors = {}
        if (!values.name) {
          errors.name = 'Required'
        }
        if (!values.companyId && values.isDemo !== true) {
          errors.companyId = 'Required'
        }

        return errors
      }}
      initialValues={{
        name: name || '',
        isDemo: Boolean(isDemo || false),
        companyId: companyId || null,
      }}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        pristine,
        invalid,
        values,
        submitting,
        form,
      }) => (
        <form onSubmit={handleSubmit}>
          <DialogTitle>Create project</DialogTitle>
          {loading && <CircularProgress />}
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={12} zeroMinWidth>
                <Field
                  render={({ input, meta }) => {
                    const showError =
                      ((meta.submitError && !meta.dirtySinceLastSubmit) ||
                        meta.error) &&
                      meta.touched

                    return (
                      <CompanyAutoSuggest
                        disabled={Boolean(companyId)}
                        companyId={input.value}
                        onChange={(companyId) =>
                          input.onChange({
                            target: { name: 'companyId', value: companyId },
                          })
                        }
                        error={showError && meta.error}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        fullWidth
                      />
                    )
                  }}
                  name="companyId"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  required
                  component={TextField}
                  variant="outlined"
                  name="name"
                  label="Name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Field type="checkbox" component={Checkbox} name="isDemo" />
                  }
                  label="Demo project"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={invalid || submitting || loading}
              type="submit"
              color="primary"
            >
              Create
            </Button>
          </DialogActions>
        </form>
      )}
    />
  )
}

export default CreateProjectForm
