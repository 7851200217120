import { useQuery, gql } from '@apollo/client'
import dotProp from 'dot-prop'

const GET_PROJECT_ITEMS = gql`
  query itemsPaginated(
    $filter: SearchFilter
    $sortOrder: ItemSortOrder
    $searchString: String
    $projectId: ID!
    $after: String
    $reverseSort: Boolean
    $first: Int!
    $predictedStop: String
    $hasGlobalItem: Boolean
    $showRootItemsOnly: Boolean
    $showOnlyRentedItems: Boolean
    $globalItemId: ID
  ) {
    project(id: $projectId) {
      id
      itemsPaginated(
        filter: $filter
        first: $first
        after: $after
        searchString: $searchString
        sortOrder: $sortOrder
        reverseSort: $reverseSort
        predictedStop: $predictedStop
        hasGlobalItem: $hasGlobalItem
        showRootItemsOnly: $showRootItemsOnly
        showOnlyRentedItems: $showOnlyRentedItems
        globalItemId: $globalItemId
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            count
            createdAt: created
            globalItem {
              id
            }
            hasBeenReturned
            id
            images(settings: { width: 100, height: 100 }) {
              id
              url
            }
            interval
            intervalAmount
            itemIdRc
            itemIdRc
            project {
              id
              name
              companyName
            }
            rentedFromCompany {
              name
            }
            shortDescription
            sku
            start
            stop
          }
        }
      }
    }
  }
`

const useProjectItems = ({
  projectId,
  searchString,
  hasGlobalItem,
  hasImages,
  showRootItemsOnly,
  filter,
  globalItemId,
  sortOrder,
  reverseSort,
  showOnlyRentedItems,
}) => {
  const variables = {
    filter: filter || 'all_really',
    first: 40,
    globalItemId,
    hasGlobalItem,
    hasImages,
    projectId,
    reverseSort: reverseSort || false,
    searchString,
    showOnlyRentedItems,
    showRootItemsOnly,
    sortOrder: sortOrder || 'description',
  }
  const { data, loading, fetchMore } = useQuery(GET_PROJECT_ITEMS, {
    variables,
    skip: !projectId,
  })

  const projectItems = dotProp
    .get(data, 'project.itemsPaginated.edges', [])
    .map((edge) => edge.node)

  const pageInfo = dotProp.get(data, 'project.itemsPaginated.pageInfo', {})
  const isLoading = !data || (loading && projectItems.length === 0)

  const fetchNextPage = () =>
    fetchMore({
      // note this is a different query than the one used in the
      // Query component
      query: GET_PROJECT_ITEMS,
      variables: { ...variables, after: pageInfo.endCursor },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.project.itemsPaginated.edges
        const newPageInfo = fetchMoreResult.project.itemsPaginated.pageInfo

        if (newEdges.length === 0) {
          console.warn('got 0', newEdges.length)
        }

        return newEdges.length
          ? {
              // Put the new comments at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              project: {
                ...previousResult.project,
                itemsPaginated: {
                  __typename: previousResult.project.itemsPaginated.__typename,
                  edges: [
                    ...previousResult.project.itemsPaginated.edges,
                    ...newEdges,
                  ],
                  pageInfo: newPageInfo,
                },
              },
            }
          : previousResult
      },
    })

  return { projectItems, pageInfo, loading, fetchNextPage }
}

export default useProjectItems
