import { useMutation, gql } from '@apollo/client'
import { useState } from 'react'
import * as React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ItemImages from '../Details/Images'
import dotProp from 'dot-prop'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const UPDATE_PROJECT_ITEM_IMAGES = gql`
  mutation UpdateProjectItemImages($projectItemId: ID!, $imageIds: [ID!]!) {
    updateProjectItemImages(projectItemId: $projectItemId, images: $imageIds) {
      success
      projectItem {
        id
        images(settings: { width: 100, height: 100 }) {
          id
          url
        }
      }
    }
  }
`

const Images = ({ item }) => {
  const [updateProjectItemImages] = useMutation(UPDATE_PROJECT_ITEM_IMAGES)
  const onDelete = async ({ event, id, url }) => {
    const globalItemImageIds = dotProp
      .get(item, 'globalItem.images', [])
      .map((image) => image.id)
    const isGlobalItemImage = globalItemImageIds.includes(id)

    if (isGlobalItemImage) {
      window.alert(
        'This image is from global item, please disconnect global item',
      )
    } else {
      if (!window.confirm('Are you sure you want to delete this image?')) {
        return
      }
      const imageIds = item.images.filter((image) => image.id !== id)
      await updateProjectItemImages({
        variables: { projectItemId: item.id, imageIds },
      })
    }
  }
  if (!item.images || item.images.length === 0) {
    return <span style={{ color: 'red' }}>Item has no images</span>
  }
  return <ItemImages item={item} onDelete={onDelete} />
}

const ImageTools = ({ item, style }) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const classes = useStyles()
  const panelOpen = expanded === 'panel1'

  return (
    <>
      <Accordion
        expanded={panelOpen}
        onChange={handleChange('panel1')}
        style={style}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Image Tools</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {panelOpen && <Images item={item} />}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default ImageTools
