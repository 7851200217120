import AutoSuggest from '~/components/AutoSuggest'
import withCompanies from '../withCompanies'
import { gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const ProjectsCount = ({ t, count, text }) => {
  return (
    <span
      style={{
        fontSize: '10px',
        textTransform: 'uppercase',
        color: text ? '#F45673' : '#999',
      }}
    >
      {text ||
        (count > 0 ? t('project_count', { count }) : t('project_count_zero'))}
    </span>
  )
}

const CompanyAutoSuggest = ({
  disabled,
  onChange,
  companyId,
  companies,
  placeholder,
  fullWidth,
  error,
  required,
  onBlur,
  onFocus,
  showNull,
}) => {
  const { t } = useTranslation('company')
  const companyOptions = companies.map((company) => ({
    label: company.name,
    value: company.id,
    projectCount: company.projectsCount,
  }))

  const options = showNull
    ? [{ label: 'None', value: 'null', warning: true }, ...companyOptions]
    : companyOptions

  const value = companyId
    ? options.find((option) => option.value === companyId)
    : null
  return (
    <div style={{ maxWidth: fullWidth ? null : '300px', flex: 1 }}>
      <AutoSuggest
        menuPortalTarget={document.body}
        value={value}
        onChange={(selected, action) => {
          const nextId = selected ? selected.value : undefined
          onChange(nextId)
        }}
        options={options}
        backspaceRemovesValue
        isClearable
        placeholder=" "
        TextFieldProps={{
          label: placeholder || 'Company',
          error: Boolean(error),
          helperText: error,
          required,
          onBlur,
          onFocus,
        }}
        isDisabled={disabled}
        formatOptionLabel={(option, props) => {
          if (props.context === 'menu' && option.projectCount !== null) {
            return (
              <div
                className="flex items-center w-full gap-2"
                style={option.warning ? { fontWeight: 'bold' } : null}
              >
                <span>{option.label}</span>
                <div style={{ flex: 1 }} />
                {Number.isInteger(option.projectCount) ? (
                  <ProjectsCount t={t} count={option.projectCount} />
                ) : (
                  <ProjectsCount t={t} text="Projects without company" />
                )}
              </div>
            )
          }

          if (props.context === 'value') {
            return <b>{option.label}</b>
          }

          return option.label
        }}
      />
    </div>
  )
}

const CompaniesWithProjectsCountQuery = gql`
  query CompaniesWithProjectsCount {
    companies {
      id
      name
      projectsCount
    }
  }
`

export default withCompanies(CompanyAutoSuggest, {
  query: CompaniesWithProjectsCountQuery,
})
