import { useState, useEffect, useRef } from 'react'
import { Prompt } from 'react-router'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const cuid = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15)

const useTrigger = () => {
  const [__trigger] = useState(() =>
    Symbol.for(`__PreventTransitionPrompt_${cuid()}`),
  )

  return __trigger
}

const PreventTransitionPrompt = ({ when, ...dialogProps }) => {
  const __trigger = useTrigger()
  const [open, setOpen] = useState(false)
  const allowTransitionCallbackRef = useRef(null)

  const show = (allowTransitionCallback) => {
    setOpen(true)
    allowTransitionCallbackRef.current = allowTransitionCallback
  }

  useEffect(() => {
    window[__trigger] = show
    return () => {
      delete window[__trigger]
    }
  }, [__trigger])

  const handleTransition = (location) => {
    if (location.pathname === '/logout') {
      return true
    }

    if (location.state && location.state.allowTransition) {
      return true
    }

    return Symbol.keyFor(__trigger)
  }

  const handleClose = () => {
    setOpen(false)
    allowTransitionCallbackRef.current(false)
  }

  const handleDiscard = () => {
    setOpen(false)
    allowTransitionCallbackRef.current(true)
  }

  return (
    <>
      <Prompt when={when} message={handleTransition} />
      <ConfirmDialog
        {...dialogProps}
        open={open}
        onClose={handleClose}
        onDiscard={handleDiscard}
      />
    </>
  )
}

const ConfirmDialog = ({ title, message, open = true, onClose, onDiscard }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onDiscard} style={{ color: '#F45673' }} autoFocus>
        OK
      </Button>
    </DialogActions>
  </Dialog>
)

export default PreventTransitionPrompt
