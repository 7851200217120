import { forwardRef } from 'react'

import { FixedSizeList as List } from 'react-window'
import { AutoSizer } from 'react-virtualized'
import InfiniteLoader from 'react-window-infinite-loader'

const DefultRow = ({ index, style, data, isScolling }) => {
  const item = data[index]
  return <div style={style}>Row {index}</div>
}

const FixedList = forwardRef(
  ({ data, itemSize = 35, width, height, Row = DefultRow, ...rest }, ref) => (
    <List
      itemData={data}
      itemCount={data.length}
      itemSize={itemSize}
      width={width}
      height={height}
      overscanCount={5}
      {...rest}
      ref={ref}
    >
      {Row}
    </List>
  ),
)

function itemKey(index, data) {
  // Find the item at the specified index.
  // In this case "data" is an Array that was passed to List as "itemData".
  const item = data[index]

  // Return a value that uniquely identifies this item.
  // Typically this will be a UID of some sort.
  return item.id
}

const InfiniteList = ({ data, itemSize, hasNextPage, loadMoreItems, Row }) => {
  const isItemLoaded = (index) => !hasNextPage || index < data.length
  // const isItemLoaded = index => false

  return (
    <AutoSizer>
      {({ width, height }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          loadMoreItems={loadMoreItems}
          itemCount={1000 * 10}
        >
          {({ onItemsRendered, ref }) => (
            <FixedList
              data={data}
              itemSize={itemSize}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={width}
              height={height}
              Row={Row}
              itemKey={itemKey}
            />
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  )
}

export default InfiniteList
