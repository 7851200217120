import { useTranslation } from 'react-i18next'
import useFormatAmount from '~/lib/useFormatAmount'

const Cost = ({ item }) => {
  const { t } = useTranslation('item')
  const formatAmount = useFormatAmount()
  if (!item || !item.interval || !Number.isFinite(item.intervalAmount)) {
    return t('price_missing')
  }
  return `${formatAmount(item.intervalAmount)} / ${t(
    `intervals.${item.interval}`,
  )}`
}

export default Cost
