import { gql } from '@apollo/client'

export const GET_DEPOTS = gql`
  query Depots {
    depots: rentalCompanies {
      id
      name
      isDemo
      primaryCompany {
        id
        name
      }
      logo(settings: { height: 128, width: 128 }) {
        id
        url
      }
      userConnections {
        user {
          id
        }
      }
    }
  }
`
