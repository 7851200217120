import { FILTER_COUNTS } from '../queries'

// We don't refetch item costs since the items are still in the list, it only refetches when you change filter
// import { ITEMS_COST } from '../Table/Summary/queries'

const getItemFilter = (props) => {
  const {
    projectId,
    returnUntagged,
    assignedUserId,
    filterTags,
    rentalCompanyId,
  } = props

  return {
    projectId,
    returnUntagged,
    assignedUserId,
    filterTags,
    rentalCompanyId,
  }
}

const getRefetchQueries = (props) => {
  const itemFilter = getItemFilter(props)
  return [
    {
      query: FILTER_COUNTS,
      variables: itemFilter,
    },
  ]
}

export default getRefetchQueries
