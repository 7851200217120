import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { SYNC_ITEM_SOURCE } from '~/components/Project/ItemSources/queries'
import formatError from '~/lib/formatError'

const useSyncItemSource = () => {
  const [syncItemSourceMutation, apolloBag] = useMutation(SYNC_ITEM_SOURCE, {
    refetchQueries: [
      'getSyncLogEntries',
      'ItemSources',
      'OnlySheetItemSources',
      'ProjectItemsWithoutImagesCount',
    ],
  })
  const { enqueueSnackbar } = useSnackbar()

  const syncItemSource = async (
    itemSource,
    setSending,
    { disableSnackbars = false } = {},
  ) => {
    try {
      setSending(itemSource.id)
      const { data } = await syncItemSourceMutation({
        variables: {
          itemSourceId: itemSource.id,
        },
      })
      if (!data.syncItemSource.success)
        throw new Error(data.syncItemSource.message)
      const message = itemSource
        ? `Item source ${itemSource.id} synced`
        : 'Item source synced'

      setSending(null)
      if (!disableSnackbars) {
        enqueueSnackbar(message, { variant: 'success' })
      }
    } catch (error) {
      setSending(null)
      if (!disableSnackbars) {
        enqueueSnackbar(`Error: ${error}`, { variant: 'error' })
      }
      console.error(error)
      return formatError(error)
    }
  }

  return [syncItemSource, apolloBag]
}

export default useSyncItemSource
