import { gql } from '@apollo/client'

export const ProjectListItemFragment = gql`
  fragment ProjectListItem on Project {
    id
    name

    start
    eqlInvolvementTime
    stop

    isArchived
    isDemo
    userConnectionsCount

    primaryCompany {
      id
      name
    }

    createdAt
    archivedAt

    coordinates {
      latitude
      longitude
    }

    builderProjectIds
    projectCode
  }
`

export const GET_PROJECTS_HEAVY = gql`
  query ProjectsHeavy(
    $includeArchivedProjects: Boolean
    $includeDemoProjects: Boolean
  ) {
    projects(
      includeArchivedProjects: $includeArchivedProjects
      includeDemoProjects: $includeDemoProjects
    ) {
      ...ProjectListItem
      currentCount: itemsCount(filter: all)
      doneCount: itemsCount(filter: may_return)
      dueCount: itemsCount(filter: due)
    }
  }
  ${ProjectListItemFragment}
`

export const GET_PROJECTS = gql`
  query Projects(
    $includeArchivedProjects: Boolean
    $includeDemoProjects: Boolean
  ) {
    projects(
      includeArchivedProjects: $includeArchivedProjects
      includeDemoProjects: $includeDemoProjects
    ) {
      ...ProjectListItem
    }
  }
  ${ProjectListItemFragment}
`
