import { useState, useEffect } from 'react'
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  Typography,
} from '@material-ui/core'
import { useField } from 'react-final-form'
import MapIcon from '@material-ui/icons/Map'
import MarkerIcon from '@material-ui/icons/Room'
import ReactMapGL, { FlyToInterpolator, Marker } from 'react-map-gl'
import MapboxAutocomplete from 'react-mapbox-autocomplete'
import 'mapbox-gl/dist/mapbox-gl.css'

import { mapboxPublicKey } from '~/lib/constants'

const Map = ({ coordinates, viewport, setViewport, setCoordinates }) => {
  const handleMapDoubleClick = (event) => {
    const [longitude, latitude] = event.lngLat
    setCoordinates({ longitude, latitude })
  }

  return (
    <ReactMapGL
      {...viewport}
      width="100%"
      height="60vh"
      onViewportChange={(viewport) => setViewport(viewport)}
      onDblClick={handleMapDoubleClick}
      doubleClickZoom={false}
      mapboxApiAccessToken={mapboxPublicKey}
    >
      {coordinates && coordinates.latitude && (
        <Marker {...coordinates}>
          <MarkerIcon color="primary" fontSize="large" />
        </Marker>
      )}
    </ReactMapGL>
  )
}

const MapSuggest = ({ onChange, coordinates, setCoordinates }) => {
  const [viewport, setViewport] = useState({
    latitude: coordinates.latitude || 59.335404,
    longitude: coordinates.longitude || 18.0713903,
    zoom: 8,
  })

  useEffect(() => {
    if (coordinates.latitude && coordinates.longitude) {
      setViewport({
        ...viewport,
        ...coordinates,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 'auto',
      })
    }
  }, [coordinates, coordinates.latitude, coordinates.longitude])

  return (
    <>
      <Map
        coordinates={coordinates}
        viewport={viewport}
        setViewport={setViewport}
        setCoordinates={setCoordinates}
      />
      <div
        style={{
          position: 'absolute',
          top: '25px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <MapboxAutocomplete
          publicKey={mapboxPublicKey}
          inputClass="form-control search"
          onSuggestionSelect={(result, latitude, longitude, text) => {
            const coordinates = {
              latitude: Number(latitude),
              longitude: Number(longitude),
            }
            onChange(coordinates)
            setViewport({
              ...viewport,
              ...coordinates,
              transitionInterpolator: new FlyToInterpolator(),
              transitionDuration: 'auto',
            })
          }}
          country="se"
          resetSearch={false}
          style={{ padding: '25px' }}
        />
      </div>
    </>
  )
}

const PickLocationButton = () => {
  const [open, setOpen] = useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)
  const {
    input: { onChange: onChangeCoordinates, value },
  } = useField('coordinates')

  const [coordinates, setCoordinates] = useState({
    latitude: null,
    longitude: null,
    ...value,
  })

  useEffect(() => {
    setCoordinates(value)
  }, [value])

  const saveCoordinates = (coordinates) =>
    onChangeCoordinates({ target: { value: coordinates } })

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <MapSuggest
          onChange={setCoordinates}
          coordinates={coordinates}
          setCoordinates={setCoordinates}
        />
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{ padding: '16px' }}
        >
          <Typography>
            Use autosuggest or double click at map to change selected location
          </Typography>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                saveCoordinates(coordinates)
                onClose()
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        size="large"
        style={{ height: '100%' }}
        onClick={onOpen}
      >
        <MapIcon />
        {'   '}Pick location
      </Button>
    </>
  )
}

export default PickLocationButton
