// Compile regex ahead of rendering
// const SHEET_ID_REGEX = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/([^\/]+)\/edit/
const SHEET_ID_REGEX =
  /^https:\/\/docs\.google\.com\/spreadsheets\/d\/([^/]+)\/edit/

export default function extractGoogleSheetsIdFromUrl(url) {
  try {
    const match = SHEET_ID_REGEX.exec(url)
    const id = match && match[1] ? match[1] : url
    return id
  } catch (error) {
    console.warn('error extracting sheet id from', url)
    return url
  }
}
