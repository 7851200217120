import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import dotProp from 'dot-prop'

import sortOn from 'sort-on'

import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import useFormatAmount from '~/lib/useFormatAmount'
const getChildrenCost = (items) => {
  if (!items || items.length === 0) return null

  const allIsWorkDay = !items.some((item) => item.costs.interval !== 'work_day')
  if (allIsWorkDay) {
    return items.reduce(
      (totalCount, child) =>
        totalCount + dotProp.get(child, 'costs.daily.amount', 0),
      0,
    )
  }
  return null
}

const ItemChild = ({ item, formatAmount }) => (
  <TableRow>
    <TableCell>{item.shortDescription}</TableCell>
    <TableCell numeric>{item.count || '-'}</TableCell>
    <TableCell numeric>
      {item.costs && item.costs.daily && item.costs.daily.amount
        ? formatAmount(item.costs.daily.amount)
        : '-'}
    </TableCell>
  </TableRow>
)

const ItemChildren = ({ item }) => {
  const { children } = item
  const { t } = useTranslation('item')
  const formatAmount = useFormatAmount()
  if (!children || !Array.isArray(children) || children.length === 0) {
    return <Typography>{t('no_children')}</Typography>
  }
  // const childrenDailyCost = getChildrenCost(children)
  // console.log('childrenDailyCost', childrenDailyCost)
  return (
    <>
      {/* <CardHeader
        title={`${children.length} rader tillbehör`}
        subheader={
          childrenDailyCost
            ? `${formatAmount(childrenDailyCost)} per arbetsdag`
            : null
        }
      /> */}
      <Table style={{ maxWidth: '80%' }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('description')}</TableCell>
            <TableCell numeric>{t('count')}</TableCell>
            <TableCell numeric>{t('cost_per_workday')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortOn(children, 'shortDescription').map((child) => (
            <ItemChild
              key={child.id}
              item={child}
              formatAmount={formatAmount}
            />
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default ItemChildren
