import { useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import sortOn from 'sort-on'

import { useForm } from 'react-hook-form'

import uploadAttachment from '~/lib/uploadAttachment'
import { ADD_ATTACHMENT_TO_PROJECT_ITEM } from '../mutations'

const ItemAttachments = ({ item }) => {
  const { register, handleSubmit } = useForm()
  const [addAttachmentToProjectItem] = useMutation(
    ADD_ATTACHMENT_TO_PROJECT_ITEM,
  )

  const onSubmitAttachment = async (data, e) => {
    if (!data.attachment[0]) {
      return false
    }
    const file = data.attachment[0]
    const attachmentId = await uploadAttachment(file)
    await addAttachmentToProjectItem({
      variables: {
        projectItemId: item.id,
        attachmentId,
      },
    })
    e.target.reset()
  }

  const AddAttachmentForm = () => (
    <form onSubmit={handleSubmit(onSubmitAttachment)}>
      <input {...register('attachment')} type="file" name="attachment"></input>
      <button type="submit">Add attachment</button>
    </form>
  )

  if (!item) {
    return null
  }
  const { attachments } = item
  if (!attachments || !Array.isArray(attachments) || attachments.length === 0) {
    return (
      <>
        <AddAttachmentForm />
      </>
    )
  }
  return (
    <>
      <AddAttachmentForm />
      <Table style={{ maxWidth: '80%' }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Extension</TableCell>
            <TableCell>Size (bytes)</TableCell>
            <TableCell>Created by</TableCell>
            <TableCell>Created at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortOn(attachments, 'name').map((attachment) => (
            <TableRow key={attachment.id}>
              <TableCell>
                <a href={attachment.url}>{attachment.name}</a>
              </TableCell>
              <TableCell>{attachment.extension}</TableCell>
              <TableCell>{attachment.size}</TableCell>
              <TableCell>
                {attachment.createdBy && (
                  <Link to={`/users/${attachment.createdBy.id}`}>
                    {attachment.createdBy.name}
                  </Link>
                )}
                {!attachment.createdBy && '-'}
              </TableCell>
              <TableCell>{attachment.createdAt}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default ItemAttachments
