import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ContentCopy as CopyIcon } from 'mdi-material-ui'
import useTimeout from '~/lib/useTimeout'
import { Tooltip } from '@material-ui/core'

const CopyToClipboardTooltip = ({ text, children }) => {
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  const onOpen = () => setOpen(true)

  useTimeout(onClose, open ? 1500 : 0)

  return (
    <Tooltip title="Copied!" open={open} placement="right">
      <CopyToClipboard text={text} onCopy={onOpen}>
        <span>
          {children || text}
          <CopyIcon
            color="primary"
            style={{
              marginLeft: '15px',
              width: '16px',
              height: '16px',
              cursor: 'pointer',
            }}
          />
        </span>
      </CopyToClipboard>
    </Tooltip>
  )
}

export default CopyToClipboardTooltip
