import { Fab as MuiFab, Portal } from '@material-ui/core'
import { Plus as PlusIcon } from 'mdi-material-ui'

const Fab = ({ text, icon = <PlusIcon />, setOpen }) => {
  return (
    <Portal>
      <MuiFab
        css={{
          position: 'fixed',
          bottom: '24px',
          right: '24px',
        }}
        variant="extended"
        size="large"
        color="secondary"
        onClick={() => setOpen(true)}
      >
        <div className="flex items-center justify-center uppercase">
          {icon}
          {text}
        </div>
      </MuiFab>
    </Portal>
  )
}

export default Fab
