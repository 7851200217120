import {
  Grid,
  Typography,
  IconButton,
  Toolbar,
  Paper,
  Button,
} from '@material-ui/core'
import { ArrowCollapseRight as CloseIcon, OpenInNew } from 'mdi-material-ui'
import { Link } from 'react-router-dom'
import dotProp from 'dot-prop'

import withItem from '~/components/Item/withItem'
import ItemDetails from '~/components/Item/Details'
import ItemDates from '~/components/Item/Dates'
import ItemImages from '~/components/Item/Details/Images'
import resolveUrl from '~/lib/resolve-url'

const Wrapper = ({ children, style }) => (
  <div css={{ margin: '0 30px' }} style={style}>
    {children}
  </div>
)

const ItemCard = ({
  itemId,
  item,
  itemLoading,
  style,
  closeLink,
  projectId,
}) => {
  return (
    <div className="flex flex-col ">
      <Paper elevation={1} square style={{ marginBottom: '15px' }}>
        <Toolbar>
          {closeLink && (
            <Link to={closeLink}>
              <IconButton>
                <CloseIcon />
              </IconButton>
            </Link>
          )}
          <Typography variant="h5">{item?.shortDescription || '-'}</Typography>

          <a
            href={`${resolveUrl(
              'pwa-construction',
            )}/projects/${projectId}/items/${itemId}`}
            className="text-white no-underline flex items-center pl-4"
            target="_BLANK"
          >
            <Button variant="contained" color="primary">
              Open in Web App
              <OpenInNew />
            </Button>
          </a>
        </Toolbar>
      </Paper>
      <div style={{ ...style }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Wrapper>
              <ItemImages item={item} />
              <div className="h-4" />
              <ItemDates item={item} />
            </Wrapper>
            <div className="h-4" />
            <ItemDetails item={item} projectId={projectId} />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default withItem(ItemCard, { skipLoading: true })
