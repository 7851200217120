import { useState } from 'react'

import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ContentCopy as CopyIcon } from 'mdi-material-ui'
import { useSnackbar } from 'notistack'
import moment from 'moment'

import DataTable from '~/components/DataTable'
// import AddToProject from '~/components/User/AddToProject'
// import ArchiveUserButton from '~/components/Users/ArchiveUserButton'
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing'

import Loading from './Loading'

const getUserPathname = ({ userId }) => {
  return `/users/${userId}`
}

const useColumns = ({ archiveUser, tab, loadHeavy }) => {
  const { enqueueSnackbar } = useSnackbar()
  const linkRenderer = ({ rowData: user, cellData: label }) => (
    <Link
      to={{
        pathname: getUserPathname({
          userId: user.id,
        }),
      }}
      onClick={(event) => event.stopPropagation()}
    >
      {label}
    </Link>
  )
  const columns = [
    {
      dataKey: 'id',
      label: 'ID',
      width: 50,
      cellRenderer: ({ cellData, rowData }) => (
        <Tooltip title="Copy user id">
          <CopyToClipboard
            text={cellData}
            onCopy={() =>
              enqueueSnackbar(
                `User ID ${
                  rowData.firstName
                    ? ` for ${rowData.firstName} ${rowData.lastName} `
                    : ' '
                }
                  copied!`,
                {
                  variant: 'success',
                },
              )
            }
          >
            <CopyIcon
              color="primary"
              style={{ width: '16px', height: '16px', cursor: 'pointer' }}
            />
          </CopyToClipboard>
        </Tooltip>
      ),
    },
    {
      dataKey: 'firstName',
      label: 'First name',
      cellRenderer: linkRenderer,
      width: 100,
      sortable: true,
    },
    {
      dataKey: 'lastName',
      label: 'Last name',
      cellRenderer: linkRenderer,
      width: 150,
      sortable: true,
    },
    {
      dataKey: 'phoneNumbers',
      label: 'Phone number',
      cellRenderer: ({ rowData: { phoneNumbers, id } }) => (
        <Link
          to={{
            pathname: getUserPathname({
              userId: id,
            }),
          }}
          onClick={(event) => event.stopPropagation()}
        >
          {(phoneNumbers || []).join(', ') || '-'}
        </Link>
      ),
      width: 150,
      sortable: false,
    },
    {
      dataKey: 'email',
      label: 'E-mail',
      cellRenderer: linkRenderer,
      width: 200,
      sortable: false,
    },
    // {
    //   dataKey: 'projectConnections',
    //   cellRenderer: ({ rowData: { projectConnections } }) =>
    //     projectConnections.length,
    //   width: 100,
    //   label: 'Projects',
    // },
    // {
    //   dataKey: 'addToProject',
    //   sortable: false,
    //   cellRenderer: (cellData) => (
    //     <AddToProject button={false} user={cellData.rowData} />
    //   ),
    //   width: 100,
    //   label: '',
    // },
    {
      dataKey: 'gdprConfirmed',
      label: 'GDPR',
      cellRenderer: ({ cellData }) => (cellData ? '✅' : '❌'),
      width: 100,
      // sortable: true,
    },
    loadHeavy && {
      dataKey: 'lastActive',
      cellRenderer: ({ cellData: lastActive }) => {
        if (lastActive) {
          const lastActiveMoment = moment(lastActive).format('YYYY-MM-DD')

          return <span>{lastActiveMoment}</span>
        }

        return <span style={{ color: 'red' }}>?</span>
      },
      label: 'Last active',
      width: 150,
      // sortable: true,
    },
    {
      dataKey: 'vacation',
      label: 'Vacation',
      sortable: false,
      cellRenderer: ({ rowData }) => {
        const { notificationsMutedStart, notificationsMutedEnd } = rowData
        if (!notificationsMutedStart || !notificationsMutedEnd) {
          return (
            <Tooltip title="No vacation planned">
              <span role="img" aria-label="NOT OK">
                ❌
              </span>
            </Tooltip>
          )
        }

        const start = notificationsMutedStart
          ? moment(notificationsMutedStart).format('YYYY-MM-DD')
          : 'No start date set'
        const end = notificationsMutedEnd
          ? moment(notificationsMutedEnd).format('YYYY-MM-DD')
          : 'No end date set'
        const title = [start, end].join(' - ')

        return (
          <Tooltip title={title}>
            <span role="img" aria-label="OK">
              ✅
            </span>
          </Tooltip>
        )
      },
      width: 100,
    },
    {
      label: 'Tracking',
      width: 100,
      dataKey: 'hasEnabledTrackingDeviceTokens',
      cellRenderer: ({ cellData }) =>
        cellData ? (
          <Tooltip title="Tool Tracker reporting is on">
            <PhonelinkRingIcon style={{ color: 'green' }} />
          </Tooltip>
        ) : (
          ''
        ),
    },
    {
      dataKey: 'isCompanyUser',
      label: 'Is company user',
      sortable: false,
      cellRenderer: ({ rowData }) => (
        <span>{rowData.isCompanyUser ? '✅' : '❌'}</span>
      ),
    },
    // {
    //   dataKey: 'archive',
    //   sortable: false,
    //   cellRenderer: (cellData) => (
    //     <ArchiveUserButton
    //       loadHeavy={loadHeavy}
    //       cellData={cellData}
    //       tab={tab}
    //     />
    //   ),
    //   width: 100,
    //   label: '',
    // },
  ].filter(Boolean)

  return columns
}

const UsersTable = ({
  users,
  onScroll,
  setSort,
  reverseSort = false,
  sortOrder,
  loading,
  tab,
  loadHeavy,
  searchString,
}) => {
  const [sortState, setSortState] = useState({
    sortOrder: sortOrder || 'first_name',
    reverseSort: reverseSort,
  })

  const columns = useColumns({ tab, loadHeavy })

  return (
    <div className="flex-1">
      <DataTable
        onSort={(prop) => {
          setSort(prop)
          setSortState(prop)
        }}
        {...sortState}
        onScroll={onScroll}
        data={users}
        columns={columns}
      />
      {loading && (
        <Loading
          color="primary"
          isLoadingAll={users === undefined}
          variant="indeterminate"
        />
      )}
    </div>
  )
}

export default UsersTable
