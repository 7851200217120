import { gql, useMutation } from '@apollo/client'
import { DeleteUser, DeleteUserVariables } from '~/types/generated/DeleteUser'

const DeleteUserMutation = gql`
  mutation DeleteUser($userId: ID!) {
    deleteUser(userId: $userId) {
      success
      user {
        id
      }
    }
  }
`

export default function useDeleteUser() {
  const [deleteUser, { loading, error }] = useMutation<
    DeleteUser,
    DeleteUserVariables
  >(DeleteUserMutation)

  return { deleteUser, error, loading }
}
