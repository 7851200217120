import { Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'

import useFormatAmount from '~/lib/useFormatAmount'

import ExpandIcon from './ExpandIcon'

const stringRenderer = ({ className, cellData }) => (
  <div title={cellData} className={className}>
    {cellData || '-'}
  </div>
)

const booleanRenderer = ({ className, cellData }) => (
  <div className={className}>{cellData ? '✅' : '❌'}</div>
)

const dateRenderer = ({ className, cellData }) => {
  const date = cellData ? moment(cellData).format('YYYY-MM-DD') : '-'

  return (
    <div title={date} className={className}>
      {date}
    </div>
  )
}

const amountRenderer = ({ className, cellData }) => {
  const formatAmount = useFormatAmount()
  const amount = cellData ? formatAmount(cellData) : '-'

  return (
    <div title={amount} className={clsx(className, 'flex-1 text-right')}>
      {amount}
    </div>
  )
}

const renderers = {
  string: stringRenderer,
  boolean: booleanRenderer,
  date: dateRenderer,
  amount: amountRenderer,
}

const TableCell = (cellProps) => {
  const type = cellProps.column.type || 'string'
  const renderer = renderers[type] || renderers.string

  return renderer(cellProps)
}

const TableHeaderCell = ({ className, column }) => {
  return (
    <Tooltip
      arrow
      title={column?.tooltip || column?.title || ''}
      placement="top"
    >
      <div className={className}>{column.title}</div>
    </Tooltip>
  )
}

export const components = {
  TableCell,
  TableHeaderCell,
  ExpandIcon,
}
