import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'

const GET_FILTER_STATE = gql`
  {
    itemFilter @client {
      projectId @client
      companyId @client
    }
  }
`

const decodeFilter = (filter = {}) => {
  const fixedFilter = { filterTags: [] }

  for (const [key, value] of Object.entries(filter)) {
    if (value === 'undefined') {
      fixedFilter[key] = undefined
    } else {
      fixedFilter[key] = value
    }
  }

  return fixedFilter
}

const encodeFilter = (filter = {}) => {
  const fixedFilter = {}

  for (const [key, value] of Object.entries(filter)) {
    if (value === undefined) {
      fixedFilter[key] = 'undefined'
    } else {
      fixedFilter[key] = value
    }
  }

  return fixedFilter
}

export default function withItemFilter(WrappedComponent) {
  return (props) => {
    return (
      <Query query={GET_FILTER_STATE}>
        {({ data, client }) => {
          return (
            <WrappedComponent
              setFilter={(filter) =>
                client.writeData({
                  data: {
                    itemFilter: { ...data.itemFilter, ...encodeFilter(filter) },
                  },
                })
              }
              {...decodeFilter(data.itemFilter)}
              {...props}
            />
          )
        }}
      </Query>
    )
  }
}
