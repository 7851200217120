import { useApolloClient, gql } from '@apollo/client'
import { useState } from 'react'
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListSubheader,
  Divider,
} from '@material-ui/core'

import { Link, useLocation } from 'react-router-dom'
import { DotsVertical as MenuIcon } from 'mdi-material-ui'
import { useSnackbar } from 'notistack'
import resolveUrl from '~/lib/resolve-url'
const REMOVE_PROJECT_FROM_COMPANY = gql`
  mutation removeProjectFromCompany($companyId: ID!, $projectId: ID!) {
    removeProjectFromCompany(companyId: $companyId, projectId: $projectId) {
      id
    }
  }
`

const EditProjectMenu = ({ project, companyId }) => {
  const location = useLocation()
  const client = useApolloClient()
  const { enqueueSnackbar } = useSnackbar()

  const projectId = project.id
  const [anchorEl, setAnchorEl] = useState(null)
  const [action, _setAction] = useState(null)
  const setAction = (action) => {
    setAnchorEl(null)
    _setAction(action)
  }
  const onOpen = (event) => setAnchorEl(event.target)
  const onClose = () => {
    setAction(null)
    setAnchorEl(null)
  }

  const removeFromCompany = async () => {
    await client.mutate({
      mutation: REMOVE_PROJECT_FROM_COMPANY,
      variables: { projectId: project.id, companyId },
      refetchQueries: ['Company'],
    })
    enqueueSnackbar(`Project "${project.name} removed from company`, {
      variant: 'success',
    })
    onClose()
  }

  return (
    <>
      <IconButton onClick={onOpen} size="small">
        <MenuIcon />
      </IconButton>

      <Popover
        onClose={onClose}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        elevation={10}
        BackdropProps={{
          invisible: false,
        }}
      >
        <List
          dense
          subheader={
            <ListSubheader
              style={{
                maxWidth: 160,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {project.name}
            </ListSubheader>
          }
        >
          <Divider />
          <ListItem
            button
            component={Link}
            replace
            to={{
              pathname: `/projects/${projectId}/edit`,
              state: {
                background: location,
              },
            }}
            onClick={onClose}
          >
            <ListItemText primary={'Edit details'} />
          </ListItem>
          <ListItem button component={Link} to={`/projects/${projectId}/users`}>
            <ListItemText primary={'Manage users'} />
          </ListItem>

          <ListItem button>
            <a
              href={`${resolveUrl('frontend-company')}/companies/${
                project?.primaryCompany?.id
              }/projects/${projectId}`}
              target="_BLANK"
              className="no-underline text-black"
            >
              <ListItemText primary={'Open in Company Admin'} />
            </a>
          </ListItem>
          <Divider />
          {project.isArchived === false && (
            <ListItem button onClick={() => setAction('archive')} disabled>
              <ListItemText
                primary={<span style={{ color: '#F45673' }}>Archive</span>}
              />
            </ListItem>
          )}
          {project.isArchived === true && (
            <ListItem button onClick={() => setAction('archive')} disabled>
              <ListItemText
                primary={<span style={{ color: '#F45673' }}>Unarchive</span>}
              />
            </ListItem>
          )}
          {companyId && (
            <ListItem button onClick={removeFromCompany}>
              <ListItemText
                primary={
                  <span style={{ color: '#F45673' }}>Remove from company</span>
                }
              />
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  )
}

export default EditProjectMenu
