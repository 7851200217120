import { useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  Fab,
} from '@material-ui/core'
import EditGlobalItemForm from './Form'
import { ImageEdit } from 'mdi-material-ui'

const EditGlobalItemButton = ({ onDone, globalItem }) => {
  const [open, setOpen] = useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  return (
    <>
      <Tooltip title="Edit global item">
        <Fab size="small" color="secondary" onClick={onOpen}>
          <ImageEdit />
        </Fab>
      </Tooltip>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle>Edit Global Item</DialogTitle>
        <DialogContent>
          <EditGlobalItemForm
            globalItem={globalItem}
            onDone={(globalItem) => {
              onClose()
              if (onDone) onDone(globalItem)
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default EditGlobalItemButton
