import { gql, useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import {
  AllProjects,
  AllProjectsVariables,
} from '~/types/generated/AllProjects'
import { ProjectListItemFragment } from '../queries'

const DEFAULT_LIMIT = 50

const AllProjectsQuery = gql`
  query AllProjects(
    $companyId: ID
    $includeArchivedProjects: Boolean
    $includeDemoProjects: Boolean
    $limit: Int
    $offset: Int
    $searchString: String
    $sortOrder: AllProjectsSortOrder
    $reverseSort: Boolean
    $hasCoordinates: Boolean
  ) {
    projectsCount(
      companyId: $companyId
      includeArchivedProjects: $includeArchivedProjects
      includeDemoProjects: $includeDemoProjects
      hasCoordinates: $hasCoordinates
    )
    projects(
      companyId: $companyId
      includeArchivedProjects: $includeArchivedProjects
      includeDemoProjects: $includeDemoProjects
      limit: $limit
      offset: $offset
      searchString: $searchString
      sortOrder: $sortOrder
      reverseSort: $reverseSort
      hasCoordinates: $hasCoordinates
    ) {
      ...ProjectListItem
    }
  }

  ${ProjectListItemFragment}
`

export function useProjects({
  companyId,
  includeArchivedProjects,
  includeDemoProjects,
  searchString,
  sortOrder,
  reverseSort,
  hasCoordinates,
  fetchPolicy = 'cache-and-network',
  skip,
  disablePagination,
}: AllProjectsVariables & {
  fetchPolicy?: WatchQueryFetchPolicy
  skip?: boolean
  disablePagination?: boolean
}) {
  const variables = useMemo(
    () => ({
      companyId,
      includeArchivedProjects,
      includeDemoProjects,
      searchString,
      offset: 0,
      limit: disablePagination ? 0 : DEFAULT_LIMIT,
      sortOrder,
      reverseSort,
      hasCoordinates,
    }),
    [
      companyId,
      includeArchivedProjects,
      includeDemoProjects,
      searchString,
      sortOrder,
      reverseSort,
      disablePagination,
    ],
  )

  const { data, loading, error, refetch, fetchMore } = useQuery<
    AllProjects,
    AllProjectsVariables
  >(AllProjectsQuery, {
    fetchPolicy,
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
    skip,
    variables,
  })

  const projects = data?.projects || []
  const projectsCount = data?.projectsCount

  const fetchNextPage = useCallback(async () => {
    if (!projects?.length || projects?.length < DEFAULT_LIMIT) {
      return
    }

    await fetchMore({
      variables: { offset: projects?.length },
    })
  }, [projects])

  return { projects, projectsCount, loading, error, refetch, fetchNextPage }
}
