import { useQuery, gql } from '@apollo/client'
import dotProp from 'dot-prop'

const GET_PROJECT_ITEMS = gql`
  query GlobalItemProjectItems(
    $after: String
    $first: Int
    $globalItemId: ID
    $hasImage: Boolean
    $searchString: String
  ) {
    imagelessEquipment(
      after: $after
      first: $first
      globalItemId: $globalItemId
      hasImage: $hasImage
      searchString: $searchString
    ) {
      pageInfo {
        beginCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          count
          createdAt: created
          globalItem {
            id
          }
          hasBeenReturned
          id
          images(settings: { width: 100, height: 100 }) {
            id
            url
          }
          interval
          intervalAmount
          itemIdRc
          itemIdRc
          project {
            id
            name
            companyName
          }
          rentedFromCompany {
            name
          }
          shortDescription
          sku
          start
          stop
        }
      }
    }
  }
`

const useGlobalItemProjectItems = ({
  first,
  globalItemId,
  hasGlobalItem: hasImage,
  searchString,
}) => {
  const variables = {
    first,
    globalItemId,
    hasImage,
    searchString,
  }
  const { data, loading, fetchMore } = useQuery(GET_PROJECT_ITEMS, {
    variables,
  })

  const projectItems = dotProp
    .get(data, 'imagelessEquipment.edges', [])
    .map((edge) => edge.node)

  const pageInfo = dotProp.get(data, 'imagelessEquipment.pageInfo', {})
  const isLoading = !data || (loading && projectItems.length === 0)

  const fetchNextPage = () =>
    pageInfo.hasNextPage
      ? fetchMore({
          query: GET_PROJECT_ITEMS,
          variables: { ...variables, after: pageInfo.endCursor },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = [
              ...previousResult.imagelessEquipment.edges,
              ...fetchMoreResult.imagelessEquipment.edges,
            ]
            const previousPageInfo = previousResult.imagelessEquipment.pageInfo
            const morePageInfo = fetchMoreResult.imagelessEquipment.pageInfo
            const newPageInfo = {
              hasPreviousPage: previousPageInfo.hasPreviousPage,
              hasNextPage: morePageInfo.hasNextPage,
              beginCursor: previousPageInfo.beginCursor,
              endCursor: morePageInfo.endCursor
                ? morePageInfo.endCursor
                : previousPageInfo.endCursor,
            }
            return newEdges.length
              ? {
                  imagelessEquipment: {
                    edges: newEdges,
                    pageInfo: newPageInfo,
                  },
                }
              : previousResult
          },
        })
      : null
  return {
    projectItems,
    pageInfo,
    loading: loading || isLoading,
    fetchNextPage,
  }
}

export default useGlobalItemProjectItems
