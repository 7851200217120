import { Dialog } from '@material-ui/core'

const TransparentDialog = ({ noBorder, ...props }) => (
  <Dialog
    BackdropProps={{
      invisible: true,
    }}
    // hideBackdrop
    // disableEnforceFocus
    // disableBackdropClick
    // disableAutoFocus
    // disableEscapeKeyDown
    css={{
      zIndex: 1,
    }}
    PaperProps={{
      elevation: 0,
      style: noBorder ? null : { border: '1px solid #145769' },
    }}
    {...props}
  />
)

export default TransparentDialog
