import { useCallback, useMemo } from 'react'
import withItems from '../withItems'
import DataGrid from '~/components/DataGrid'
import AutoResizer from 'react-base-table/lib/AutoResizer'
import { Link, NavLink, useHistory } from 'react-router-dom'
import dotProp from 'dot-prop'
import moment from 'moment'
import styled from '@emotion/styled'
import { CircularProgress, Button, Tooltip } from '@material-ui/core'
import withSortState from '~/components/DataTable/lib/withSortState'
import { Tunnel } from 'react-tunnels'
import ExportCsvButton from './ExportCsvButton'
import useFormatAmount from '~/lib/useFormatAmount'

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const useInitialColumns = ({ projectId, filter }) => {
  const formatAmount = useFormatAmount()
  const initialColumns = useMemo(
    () => [
      {
        dataKey: 'description',
        width: 400,
        minWidth: 400,
        className: 'item-link',
        title: 'Description',
        flexGrow: 1,
        enabled: true,
        sortable: true,
        cellDataGetter: ({ rowData: item }) => item.shortDescription,
        cellRenderer: ({ rowData: item }) => {
          return (
            <span style={item.isHidden ? { opacity: 0.5 } : null}>
              <NavLink
                to={`/projects/${projectId}/items/${item.id}?filter=${filter}`}
                style={{
                  textDecoration: 'none',
                  color: '#175B6A',
                }}
                activeStyle={{
                  color: 'black',
                  textDecoration: 'underline',
                }}
              >
                {item.shortDescription}
              </NavLink>
            </span>
          )
        },
      },
      { dataKey: 'count', width: 100, title: 'Count', sortable: true },
      {
        dataKey: 'assigned_user',
        width: 100,
        title: 'Assigned user',
        sortable: true,
        cellRenderer: ({ rowData: item }) => {
          const { assignedUser } = item
          if (assignedUser && assignedUser.id) {
            return (
              <Tooltip title={assignedUser.name}>
                <Link
                  target="_BLANK"
                  to={`/users/${assignedUser.id}`}
                  rel="noopener noreferrer"
                >
                  {assignedUser.initials}
                </Link>
              </Tooltip>
            )
          }
          return '-'
        },
      },
      {
        dataKey: 'start_date',
        width: 100,
        title: 'Start',
        type: 'date',
        enabled: true,
        sortable: true,
        cellDataGetter: ({ rowData }) => rowData.start,
        cellRenderer: ({ rowData }) =>
          rowData.start ? moment(rowData.start).format('YYYY-MM-DD') : '-',
      },
      {
        dataKey: 'return_date',
        cellDataGetter: ({ rowData }) => rowData.stop,
        cellRenderer: ({ rowData: item }) =>
          item.stop ? moment(item.stop).format('YYYY-MM-DD') : '-',
        width: 100,
        title: 'Stop',
        type: 'date',
        sortable: true,
      },
      {
        dataKey: 'predicted_stop',
        cellDataGetter: ({ rowData }) => rowData.predictedStop,
        cellRenderer: ({ rowData: item }) =>
          item.predictedStop
            ? moment(item.predictedStop).format('YYYY-MM-DD')
            : '-',
        width: 100,
        title: 'Predicted stop',
        tooltip: 'Predicted stop time set by user',
        type: 'date',
        enabled: true,
      },
      {
        dataKey: 'created',
        width: 100,
        title: 'Created at',
        tooltip: 'When the item was created in our database',
        type: 'date',
        enabled: false,
      },
      {
        dataKey: 'shouldBeReturned',
        width: 100,
        title: 'Returnable',
        tooltip: 'Marked as returnable',
        type: 'boolean',
      },
      {
        dataKey: 'costs.previousMonth.amount',
        width: 130,
        title: 'Previous month',
        enabled: true,
        type: 'amount',
        cellDataGetter: ({ rowData: item, dataKey }) =>
          dotProp.get(item, dataKey),
      },
      {
        dataKey: 'costs.currentMonth.amount',
        width: 120,
        title: 'Current month',
        enabled: false,
        type: 'amount',
        cellDataGetter: ({ rowData: item, dataKey }) =>
          dotProp.get(item, dataKey),
      },
      {
        dataKey: 'past_cost',
        width: 100,
        title: 'Past cost',
        enabled: false,
        type: 'amount',
        sortable: true,

        cellDataGetter: ({ rowData: item }) => {
          const cost = dotProp.get(item, 'costs.before.amount')
          if (cost) return cost
          return null
        },
        cellRenderer: ({ rowData: item }) => {
          const cost = dotProp.get(item, 'costs.total.amount')
          if (cost) return formatAmount(cost)
          return '-'
        },
      },
      {
        dataKey: 'costs.after.amount',
        width: 100,
        title: 'Future cost',
        enabled: false,
        type: 'amount',
        cellDataGetter: ({ rowData: item, dataKey }) =>
          dotProp.get(item, dataKey),
      },
      {
        dataKey: 'assumed_total_cost',
        width: 100,
        title: 'Assumed total cost',
        enabled: true,
        sortable: true,
        type: 'amount',
        cellDataGetter: ({ rowData }) =>
          dotProp.get(rowData, 'costs.total.amount'),
        cellRenderer: ({ rowData: item }) => {
          const cost = dotProp.get(item, 'costs.total.amount')
          if (cost) return formatAmount(cost)
          return '-'
        },
      },
      {
        dataKey: 'daily',
        width: 100,
        title: 'Calculated daily cost',
        enabled: true,
        sortable: true,
        type: 'amount',
        cellDataGetter: ({ rowData }) =>
          dotProp.get(rowData, 'costs.daily.amount'),
        cellRenderer: ({ rowData: item }) => {
          const cost = dotProp.get(item, 'costs.daily.amount')
          if (cost) return formatAmount(cost)
          return '-'
        },
      },
      {
        dataKey: 'interval',
        width: 100,
        title: 'Interval (price list)',
        sortable: true,
        enabled: true,
      },
      {
        dataKey: 'interval_amount',
        cellDataGetter: ({ rowData: item }) => item.intervalAmount,
        cellRenderer: ({ rowData: item }) => {
          const cost = dotProp.get(item, 'costs.daily.amount')
          if (cost) return formatAmount(cost)
          return '-'
        },
        width: 100,
        title: 'Amount (price list)',
        sortable: true,
        enabled: true,
      },
      {
        dataKey: 'sku',
        width: 100,
        title: 'Article Number / SKU',
        enabled: true,
        sortable: true,
        cellDataGetter: ({ rowData }) => (rowData.sku ? rowData.sku : ''),
      },
      {
        dataKey: 'item_id_rc',
        width: 100,
        title: 'Item Id Rc',
        tooltip: 'ID given by the rental company',
        enabled: true,
        sortable: true,
        cellDataGetter: ({ rowData: item }) => item.itemIdRc || '',
      },
      {
        dataKey: 'source',
        title: 'Data source',
        width: 200,
        cellDataGetter: ({ rowData: item }) => {
          const dataSource = dotProp.get(item, 'itemSource.dataSource')
          if (!dataSource) return null
          return `${dataSource.name || 'N/A'} - ${dataSource.id}`
        },
        cellRenderer: ({ rowData: item }) =>
          item.itemSource ? (
            <Link
              target="_BLANK"
              to={`/data-sources/${item.itemSource.dataSource.id}`}
            >
              <span>
                {item.itemSource.dataSource.name} (
                {item.itemSource.dataSource.type})
              </span>
            </Link>
          ) : (
            ' - '
          ),
      },
      {
        dataKey: 'data_source_type',
        title: 'Data source type',
        width: 200,
        cellDataGetter: ({ rowData: item }) =>
          dotProp.get(item, 'itemSource.dataSource.type'),
        cellRenderer: ({ rowData: item }) =>
          dotProp.get(item, 'itemSource.dataSource.type'),
        enabled: false,
      },
      {
        dataKey: 'syncId',
        title: 'Sync ID',
        width: 300,
        enabled: false,
      },
      {
        dataKey: 'timeToFirstStopPrediction',
        title: 'Time to first prediction',
        width: 200,
        tooltip:
          'Time from appearing in the app to predicted stop set for the first time (seconds)',
        cellDataGetter: ({ rowData: item, isExport }) => {
          if (!item.timeToFirstStopPrediction) {
            if (isExport) return ''
            return '-'
          }
          const seconds = Math.round(item.timeToFirstStopPrediction / 1000)
          if (isExport) return seconds
          if (seconds <= 1) return 'Less than 1 second'
          return moment.duration(seconds, 'seconds').humanize()
        },
      },
      {
        dataKey: 'rental_company',
        sortable: true,
        title: 'Rental depot',
        width: 120,
        enabled: false,
        cellDataGetter: ({ rowData }) =>
          dotProp.get(rowData, 'rentalCompany.name'),
        cellRenderer: ({ rowData: item }) => {
          const depot = dotProp.get(item, 'rentalCompany')
          if (depot && depot.name) {
            return (
              <Link target="_BLANK" to={`/depots/${depot.id}`}>
                {depot.name}
              </Link>
            )
          }
          return '-'
        },
      },
      {
        dataKey: 'project_name',
        sortable: false,
        title: 'Project name',
        width: 120,
        enabled: false,
        cellDataGetter: ({ rowData }) => dotProp.get(rowData, 'project.name'),
        cellRenderer: ({ rowData: item }) => {
          const project = dotProp.get(item, 'project')
          if (project && project.name) {
            return (
              <Link
                target="_BLANK"
                to={`/projects/${project.id}`}
                rel="noopener noreferrer"
              >
                {project.name}
              </Link>
            )
          }
          return '-'
        },
      },
      {
        dataKey: 'company_name',
        sortable: true,
        title: 'Rental company',
        width: 120,
        enabled: false,
        cellDataGetter: ({ rowData }) =>
          dotProp.get(rowData, 'rentalCompany.primaryCompany.name'),
        cellRenderer: ({ rowData: item }) => {
          const company = dotProp.get(item, 'rentalCompany.primaryCompany')
          if (company && company.name) {
            return (
              <Link
                target="_BLANK"
                to={`/companies/${company.id}`}
                rel="noopener noreferrer"
              >
                {company.name}
              </Link>
            )
          }
          return '-'
        },
      },
      {
        dataKey: 'parentId',
        width: 100,
        title: 'Has parent',
        type: 'boolean',
        enabled: false,
      },
    ],
    [projectId, filter],
  )

  return initialColumns
}
export { useInitialColumns }

const ProjectItemsGrid = ({
  projectId,
  items,
  columns,
  fetchNextPage,
  loading,
  width: fixedWidth,
  sortState,
  setSortState,
  pageInfo,
  filter,
  project,
}) => {
  const history = useHistory()
  const handleEndReached = () => {
    fetchNextPage()
  }
  const loadingMore = loading && items.length > 0

  const rowEventHandlers = useMemo(
    () => ({
      onClick: ({ event, rowData: item }) => {
        const isItem =
          event.target &&
          event.target.classList &&
          event.target.classList.value.includes('item-link')
        if (!isItem) return
        history.push(`/projects/${projectId}/items/${item.id}?filter=${filter}`)
      },
    }),
    [filter, projectId],
  )

  const renderFooter = useCallback(() => {
    if (!loadingMore) return null
    return (
      <Footer>
        <CircularProgress />
      </Footer>
    )
  }, [])

  const handleColumnSort = useCallback((sort) => {
    const { key, column } = sort
    const sortOrder = key
    setSortState((sortState) => ({
      sortOrder,
      reverseSort:
        sortState.sortOrder === sortOrder ? !sortState.reverseSort : false,
    }))
  }, [])
  return (
    <>
      <Tunnel id="Toolbar">
        <ExportCsvButton
          items={items}
          columns={columns}
          fetchMore={fetchNextPage}
          isLoading={loading}
          pageInfo={pageInfo}
          project={project}
        />
      </Tunnel>
      <AutoResizer>
        {({ width, height }) => (
          <DataGrid
            key={filter}
            width={fixedWidth || width}
            height={height}
            data={items}
            columns={columns}
            onEndReachedThreshold={500}
            onEndReached={handleEndReached}
            footerHeight={loadingMore ? 50 : 0}
            footerRenderer={renderFooter}
            onColumnSort={handleColumnSort}
            rowEventHandlers={rowEventHandlers}
            sortBy={{
              key: sortState.sortOrder,
              order: sortState.reverseSort ? 'asc' : 'desc',
            }}
          />
        )}
      </AutoResizer>
    </>
  )
}

export default withSortState(withItems(ProjectItemsGrid))
