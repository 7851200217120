import { lazy, Suspense, useMemo, useState } from 'react'
import { useDebounce } from '@equipmentloop/utils'
import {
  Typography,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@material-ui/core'

import { BooleanParam, StringParam, useQueryParams } from 'use-query-params'

import { useProjects } from '~/components/Projects/hooks/useProjects'
// import Wrapper from '~/components/Layout/AdminLayout/Wrapper'
import PageHeader from '~/components/Layout/components/PageHeader'
// import ProjectCards from '~/components/Projects/Cards'
import { ProjectsTable } from '~/components/Projects/Table'
import CreateProjectFab from '~/components/Project/Create/Fab'
import SearchField from '~/components/Mui/SearchField'
import CompanyAutoSuggest from '~/components/Companies/AutoSuggest'

import ViewMode, {
  // CARDS_VIEWMODE,
  MAP_VIEWMODE,
  TABLE_VIEWMODE,
} from './components/ViewMode'
import { AllProjectsSortOrder } from '~/types/generated/globalTypes'

const ProjectsMap = lazy(() => import('~/components/Projects/Map'))

const Header = ({
  projectsCount,
  searchString,
  setSearchString,
  companyId,
  toolbar,
  setQuery,
  includeArchivedProjects,
  includeDemoProjects,
}) => {
  const setCompanyId = (companyId) => setQuery({ companyId })

  const setIncludeArchivedProjects = (includeArchivedProjects) => {
    setQuery({ includeArchivedProjects })
  }
  const setIncludeDemoProjects = (includeDemoProjects) => {
    setQuery({ includeDemoProjects })
  }

  return (
    <PageHeader
      title="Projects"
      count={projectsCount}
      toolbar={
        <>
          <FormControlLabel
            control={
              <Switch
                checked={includeArchivedProjects}
                onChange={() => {
                  setIncludeArchivedProjects(!includeArchivedProjects)
                }}
                value={includeArchivedProjects}
                color="primary"
              />
            }
            label="Include archived"
          />
          <FormControlLabel
            control={
              <Switch
                checked={includeDemoProjects}
                onChange={() => {
                  setIncludeDemoProjects(!includeDemoProjects)
                }}
                value={includeDemoProjects}
                color="primary"
              />
            }
            label="Include demo"
          />
          {toolbar}
          <div style={{ width: '20px' }} />
          <CompanyAutoSuggest
            showNull
            companyId={companyId}
            onChange={setCompanyId}
          />
          <div style={{ width: '20px' }} />
          <SearchField value={searchString} onChangeText={setSearchString} />
        </>
      }
    />
  )
}

export const ProjectsEmptyState = ({ isSearching, clearSearch }) => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <>
        <Typography variant="h5">No projects found</Typography>
        {isSearching && (
          <Button
            style={{ marginTop: '20px' }}
            variant="contained"
            color="secondary"
            onClick={clearSearch}
          >
            Clear search
          </Button>
        )}
      </>
    </div>
  )
}

const Projects = () => {
  const [
    {
      includeArchivedProjects,
      includeDemoProjects,
      companyId,
      viewMode = TABLE_VIEWMODE,
    },
    setQuery,
  ] = useQueryParams({
    includeArchivedProjects: BooleanParam,
    includeDemoProjects: BooleanParam,
    companyId: StringParam,
    viewMode: StringParam,
  })

  const [sortState, setSortState] = useState({
    key: AllProjectsSortOrder.created_at_desc,
    order: 'desc',
  })
  const [searchString, setSearchString] = useState('')
  const debouncedSearchString = useDebounce(searchString, 500)

  const { projects, projectsCount, loading, error, fetchNextPage } =
    useProjects({
      companyId: companyId === 'null' ? null : companyId,
      includeArchivedProjects,
      includeDemoProjects,
      searchString: debouncedSearchString,
      sortOrder: sortState.key,
      reverseSort: sortState.order === 'asc',
      hasCoordinates: viewMode === MAP_VIEWMODE || undefined,
      disablePagination: viewMode === MAP_VIEWMODE,
    })

  const clearSearch = () => {
    setQuery({ companyId: undefined })
    setSearchString('')
  }

  const projectsView = useMemo(() => {
    if (error) {
      return (
        <div className="flex-1 flex flex-col items-center justify-center">
          <Typography variant="h5">Something went wrong</Typography>
          <p>{error?.message}</p>
        </div>
      )
    }

    if (!loading && projects.length === 0) {
      return (
        <ProjectsEmptyState
          isSearching={debouncedSearchString}
          clearSearch={clearSearch}
        />
      )
    }

    switch (viewMode) {
      case MAP_VIEWMODE:
        return (
          <div className="w-full h-full flex items-center justify-center">
            <Suspense fallback={<CircularProgress />}>
              <ProjectsMap projects={projects} />
            </Suspense>
          </div>
        )

      // TODO:
      // remove the cards view later
      // case CARDS_VIEWMODE:
      //   return (
      //     <Wrapper scrollable>
      //       <ProjectCards
      //         projects={projects}
      //         isArchived={includeArchivedProjects}
      //       />
      //     </Wrapper>
      //   )

      case TABLE_VIEWMODE:
      default:
        return (
          <div className="flex-1">
            <ProjectsTable
              companyId={companyId}
              projects={projects}
              includeArchivedProjects={includeArchivedProjects}
              fetchNextPage={fetchNextPage}
              sortState={sortState}
              setSortState={setSortState}
            />
          </div>
        )
    }
  }, [
    viewMode,
    projects,
    loading,
    error,
    fetchNextPage,
    includeArchivedProjects,
    companyId,
    sortState,
    debouncedSearchString,
  ])

  return (
    <div className="h-full w-full flex flex-1 flex-col">
      <CreateProjectFab
        companyId={companyId}
        name={searchString}
        isDemo={includeDemoProjects}
        isArchived={includeArchivedProjects}
      />
      <Header
        projectsCount={projectsCount}
        searchString={searchString}
        setSearchString={setSearchString}
        companyId={companyId}
        includeArchivedProjects={includeArchivedProjects}
        includeDemoProjects={includeDemoProjects}
        toolbar={<ViewMode viewMode={viewMode} setQuery={setQuery} />}
        setQuery={setQuery}
      />
      {projectsView}
    </div>
  )
}

export default Projects
