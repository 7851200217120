import { useMutation, gql } from '@apollo/client'
const CREATE_GLOBAL_ITEM = gql`
  mutation createGlobalItem($name: String!, $images: [String]) {
    createGlobalItem(input: { name: $name, images: $images }) {
      success
      message
      globalItem {
        id
        name
        images(settings: { width: 100, height: 100 }) {
          id
          url
        }
      }
    }
  }
`

const useCreateGlobalItem = () => {
  const [mutate] = useMutation(CREATE_GLOBAL_ITEM)

  const createGlobalItem = async ({ name, images }) => {
    const {
      data: {
        createGlobalItem: { success, message, globalItem },
      },
    } = await mutate({ variables: { name, images } })

    return { success, message, globalItem }
  }

  return [createGlobalItem]
}

export default useCreateGlobalItem
