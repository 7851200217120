import { IconButton } from '@material-ui/core'
import { Close as CloseIcon } from 'mdi-material-ui'

const DialogHeader = ({ title, onClose }) => (
  <div className="p-4 w-full flex items-center justify-between">
    <div className="text-xl font-semibold">{title}</div>

    <IconButton
      size="small"
      onClick={onClose}
      style={{ marginTop: '0px', marginRight: '0px' }}
    >
      <CloseIcon style={{ color: '#145869' }} />
    </IconButton>
  </div>
)

export default DialogHeader
