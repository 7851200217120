import { Dialog } from '@material-ui/core'
import CreateDepotForm from './Form'

const CreateDepotDialog = ({ companyId, name, isDemo, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <CreateDepotForm
        companyId={companyId}
        name={name}
        isDemo={isDemo}
        onClose={onClose}
      />
    </Dialog>
  )
}

export default CreateDepotDialog
