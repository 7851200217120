import { gql } from '@apollo/client'

export const TransportBookingFragment = gql`
  fragment TransportBookingFragment on TransportBooking {
    id
    user {
      id
      name
    }
    project {
      id
      name
      isDemo
      isArchived
      primaryCompany {
        id
        name
      }
    }
    depot {
      id
      name
    }
    rentalCompany {
      id
      name
    }
    equipmentLoopBookingReference
    pickupDate
    pickupTime
    pickupExtraInfo
    status
    remarksFromRental
    cancelReason
    created
    updated
    emailInfo
    bookingResponseUrl
    constructionWebUrl
  }
`
