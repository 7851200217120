import { createElement, Component } from 'react'
import 'react-phone-number-input/style.css'
import ReactPhoneNumberInput from 'react-phone-number-input'
import { css } from '@emotion/react'
import { MenuDown as DropDownIcon } from 'mdi-material-ui'
import SmartInput from 'react-phone-number-input/smart-input'
import clsx from 'clsx'

const overrides = css`
  .react-phone-number-input__icon {
    position: relative;
  }

  .react-phone-number-input__icon-image {
    top: 0;
    left: 0;
    position: absolute;
  }

  .react-phone-number-input__input {
  }

  .phone--mui .react-phone-number-input__row {
    border-color: rgba(0, 0, 0, 0.23);
    transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    input {
      transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      border-color: rgba(0, 0, 0, 0.23);
      &:focus {
        border-color: rgba(0, 0, 0, 0.23);
      }
    }
    &:hover {
      border-color: rgba(0, 0, 0, 0.87);
      input {
        border-color: rgba(0, 0, 0, 0.87);
      }
    }
    &:focus-within {
      border-color: rgba(0, 0, 0, 0.87);
    }
    input:focus {
      border-color: rgba(0, 0, 0, 0.87);
    }
  }
  .phone--error .react-phone-number-input__row {
    border-color: #f45673;
    input {
      border-color: #f45673;
    }
  }
  .react-phone-number-input__row {
    border: 1px solid #145769;
    border-radius: 4px;
    overflow: hidden;
    input {
      background: transparent !important;
      border: none;
      border-left: 1px solid;
      color: #145769;
      padding: 24px 8px;
      &:focus {
        border-color: #145769;
      }
    }
  }
`

class CountrySelect extends Component {
  render() {
    const { name, value, onChange, options } = this.props
    const selectedOption = options.find((option) => option.value === value)

    return (
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          padding: '0 12px',
          paddingRight: 0,
        }}
      >
        <span>
          {selectedOption
            ? createElement(selectedOption.icon, { value })
            : value}
        </span>
        <DropDownIcon style={{ color: '#145769' }} />
        <select
          name={name}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          css={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            cursor: 'pointer',
            opacity: 0,
          }}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    )
  }
}
const PhoneInput = ({
  placeholder = '070 000 000',
  label = 'Ditt telefonnummer',
  value: _value,
  onChange,
  onFocus,
  onBlur,
  autoFocus,
  error,
  mui,
  ...restProps
}) => {
  let value = _value
  if (typeof _value === 'object') {
    value = value.phoneNumber
  }

  return (
    <div css={overrides}>
      <ReactPhoneNumberInput
        {...restProps}
        className={clsx({ 'phone--mui': mui, 'phone--error': Boolean(error) })}
        country="SE"
        label={label}
        placeholder={placeholder}
        autoFocus={autoFocus}
        value={value}
        onChange={(value) => onChange({ target: { value } })}
        onFocus={onFocus}
        onBlur={onBlur}
        inputComponent={SmartInput}
        countrySelectComponent={CountrySelect}
      />
    </div>
  )
}

export default PhoneInput
