import { useState } from 'react'
import { Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import CreateGlobalItemForm from './Form'

const CreateGlobalItemButton = ({ name, onDone }) => {
  const [open, setOpen] = useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  return (
    <>
      <Button
        onClick={onOpen}
        variant="outlined"
        color={name ? 'secondary' : 'primary'}
      >
        Create
      </Button>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle>Create Global Item</DialogTitle>
        <DialogContent>
          <CreateGlobalItemForm
            name={name}
            onDone={(globalItem) => {
              onClose()
              if (onDone) onDone(globalItem)
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CreateGlobalItemButton
