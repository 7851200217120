import { useState } from 'react'

import Fab from '~/components/Mui/Fab'

import CreateDepotDialog from './Dialog'

const CreateDepotFab = ({ companyId, name, isDemo }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <CreateDepotDialog
        companyId={companyId}
        name={name}
        isDemo={isDemo}
        open={open}
        onClose={() => setOpen(false)}
      />
      <Fab text="Add depot" setOpen={setOpen} />
    </>
  )
}

export default CreateDepotFab
