import PropTypes from 'prop-types'
import { Switch, FormControlLabel } from '@material-ui/core'
import { green } from '~/lib/colors'

function FFSwitch(props) {
  const {
    input: { value, ...input },
    InputProps,
    meta: { dirty, error, submitError, submitFailed, active },
    label,
    ...other
  } = props

  return (
    <FormControlLabel
      css={{ '.label--changed': { color: green[700] } }}
      classes={{ label: dirty && !error ? 'label--changed' : '' }}
      control={<Switch checked={Boolean(value)} {...input} />}
      label={label}
      {...other}
    />
  )
}

FFSwitch.displayName = 'FFSwitch'

FFSwitch.propTypes = {
  helperText: PropTypes.node,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  InputProps: PropTypes.object,
  meta: PropTypes.shape({
    dirty: PropTypes.bool.isRequired,
    error: PropTypes.string,
    submitError: PropTypes.string,
    submitFailed: PropTypes.bool.isRequired,
  }).isRequired,
}

export default FFSwitch
