import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'

import { CircularProgress } from '@material-ui/core'

export const GET_PROJECTITEMS = gql`
  query withProjectItems($projectId: ID!, $assignedUserId: ID!) {
    project(id: $projectId) {
      id
      items(assignedUserId: $assignedUserId) {
        id
      }
      userConnections {
        user {
          id
          name
        }
      }
    }
  }
`
export default function withProjectItems(
  WrappedComponent,
  { skipLoading } = {},
) {
  return (props) => {
    return (
      <Query
        query={GET_PROJECTITEMS}
        skip={!props.projectId}
        variables={{
          projectId: props.projectId,
          assignedUserId: props.user.id,
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, loading, client }) => {
          if (!data || (loading && !skipLoading)) {
            return <CircularProgress />
          }

          return <WrappedComponent project={data.project} {...props} />
        }}
      </Query>
    )
  }
}
