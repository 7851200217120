import { Query } from '@apollo/client/react/components'

import { IS_LOGGED_IN } from '../AuthRoute'

export default function withUser(WrappedComponent, { skipLoading } = {}) {
  return (props) => {
    return (
      <Query query={IS_LOGGED_IN} fetchPolicy="cache-first">
        {({ data, loading, client }) => {
          if (!data || (loading && !skipLoading)) return null

          return <WrappedComponent user={data.getUser} {...props} />
        }}
      </Query>
    )
  }
}
