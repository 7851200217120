import clsx from 'clsx'
import Select from 'react-select'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import {
  Cancel as CancelIcon,
  Close as ClearIcon,
  MenuDown as DropdownIcon,
} from 'mdi-material-ui'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'

export const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 14,
  },
  placeholder: {
    position: 'absolute',
    left: '16px',
    bottom: '50%',
    transform: 'translateY(50%)',
    fontSize: 14,
  },
  paper: {
    minWidth: 'min-content',
  },
  divider: {
    height: theme.spacing(2),
  },
  dropdownIndicator: {},
  indicatorsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
  },
})
const useStyles = makeStyles(styles)

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
}

function inputComponent({ inputRef, value, ...props }) {
  return <div ref={inputRef} {...props} />
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

function Control(props) {
  return (
    <TextField
      fullWidth
      variant="outlined"
      InputLabelProps={props.hasValue ? { shrink: props.hasValue } : null}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      value={props.hasValue ? ' ' : ''}
      {...props.selectProps.TextFieldProps}
    />
  )
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
}
function IndicatorsContainer(props) {
  return (
    <div
      className={props.selectProps.classes.indicatorsContainer}
      {...props.innerProps}
    >
      {props.children}
    </div>
  )
}

IndicatorsContainer.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
}

const ClearIndicator = ({ clearValue }) => (
  <IconButton onClick={clearValue}>
    <ClearIcon />
  </IconButton>
)
const DropdownIndicator = ({ clearValue, isDisabled, ...props }) => {
  if (isDisabled) return null
  return (
    <DropdownIcon className={props.selectProps.classes.dropdownIndicator} />
  )
}

export const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  ClearIndicator,
  DropdownIndicator,
  IndicatorsContainer,
}

const AutoSuggest = ({
  placeholder,
  options,
  value,
  onChange,
  disabled,
  ...restProps
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const selectStyles = {
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    menuPortal: (base, state) => {
      let overrideProps = {
        zIndex: 9999,
      }
      // do something here
      return Object.assign(base, overrideProps)
    },
  }

  return (
    <Select
      classes={classes}
      styles={selectStyles}
      options={options}
      components={components}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isDisabled={disabled}
      disabled={disabled}
      {...restProps}
    />
  )
}

export default AutoSuggest
