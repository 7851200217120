import { gql } from '@apollo/client'
import {
  FullProjectFragment,
  ProjectUserConnectionsFragment,
} from './fragments'

export const GET_PROJECT = gql`
  query Project($projectId: ID!) {
    project(id: $projectId) {
      ...FullProjectFragment
      ...ProjectUserConnectionsFragment

      currentCount: itemsCount(filter: all)
    }
  }
  ${FullProjectFragment}
  ${ProjectUserConnectionsFragment}
`

export const GET_RENTAL_COMPANIES = gql`
  query RentalCompanies {
    rentalCompanies {
      id
      name
    }
  }
`

export const GET_PROJECT_SYNC_STATUS = gql`
  query ProjectSyncStatus($projectId: ID!) {
    project(id: $projectId) {
      id
      itemSources {
        id
        active
        syncLog(first: 1) {
          success
        }
        archived
        dataSource {
          id
          dataImportStatus {
            status
          }
        }
      }
    }
  }
`
