import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Form, Field } from 'react-final-form'
import { Button, Grid, InputAdornment, MenuItem } from '@material-ui/core'
import { TextField as FFTextField, Select } from 'final-form-material-ui'

import useUpdateProject from './useUpdateProject'
import FFDateField from '~/components/Mui/FFDateField'
import { green } from '~/lib/colors'
import PreventTransitionPrompt from '~/components/Mui/PreventTransitionPrompt'
import moment from 'moment'
import createDecorator from 'final-form-focus'

import PickLocationButton from './PickLocationButton'
import SectionDivider from '~/components/Mui/SectionDivider'
import FFSwitch from '~/components/Mui/FFSwitch'
import FFAmountField from '~/components/Mui/FFAmountField'
import classnames from 'clsx'
import { Tunnel } from 'react-tunnels'
import { GET_COMPANIES } from '../../Companies/queries'
import dotProp from 'dot-prop'

import { isLat, isLng } from '~/lib/helpers'

const focusOnError = createDecorator()

const TextField = ({ alignRight, ...props }) => {
  const changed = props.meta.dirty && !props.meta.error
  const type = props.input.type
  const isNumber = type === 'number' && alignRight
  return (
    <FFTextField
      {...props}
      classes={{ root: classnames({ changed, isNumber }) }}
      css={{
        '&.changed': {
          '>label': { color: green[700] },
          fieldset: { borderColor: green[700], borderWidth: '1.5px' },
        },
        '&.isNumber': {
          input: {
            textAlign: 'right',
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
              appearance: 'none',
            },
          },
        },
      }}
    />
  )
}

const requiredValidator = (value) => {
  if (!value) return 'Required field'
}

const dateValidator = (value) => {
  if (value && !moment(value).isValid()) return 'Invalid date'
}

const latValidator = (value) => {
  if (value && !isLat(value)) return 'Invalid Latitude'
}

const lngValidator = (value) => {
  if (value && !isLng(value)) return 'Invalid Longitude'
}

const cannotBeRemoved = (value, allValues, meta) => {
  if (!value && meta.dirty) {
    return 'This field can not be cleared'
  }
}

const composeValidators =
  (...validators) =>
  (value, allValues, meta) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValues, meta),
      undefined,
    )

const cantBeRemovedDate = composeValidators(cannotBeRemoved, dateValidator)

const FormControl = ({ onClose, props, loading }) => (
  <Grid container spacing={2} justify="flex-end">
    <Grid item xs justify="flex-end" container>
      {onClose ? (
        <Button
          onClick={() => {
            onClose({ allowTransition: true })
          }}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
      ) : (
        !props.pristine && (
          <Button
            onClick={() => props.form.reset()}
            disabled={props.pristine || props.submitting}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
        )
      )}

      <div style={{ width: '15px' }} />
      <Button
        type="submit"
        disabled={props.pristine || props.submitting}
        variant="contained"
        color="secondary"
        onClick={props.form.submit}
      >
        Save
      </Button>
    </Grid>
  </Grid>
)

const EditProjectForm = ({ project, onClose, tunnelFormControl }) => {
  const [updateProject, { loading, error }] = useUpdateProject()
  const { data: companiesData } = useQuery(GET_COMPANIES)
  const companies = useMemo(() => {
    let companies
    if (companiesData?.companies) {
      companies = companiesData.companies
    } else if (project.primaryCompany) {
      companies = [project.primaryCompany]
    } else {
      companies = []
    }
    return companies?.length
      ? companies.slice(0).sort((c1, c2) => c1?.name?.localeCompare(c2?.name))
      : companies
  }, [companiesData])

  return (
    <Form
      decorators={[focusOnError]}
      onSubmit={async (updates) => {
        const errors = await updateProject(project.id, {
          ...updates,
        })
        if (errors) return errors
        if (onClose) onClose()
      }}
      initialValues={{
        name: project.name,
        start: project.start,
        stop: project.stop,
        eqlInvolvementTime: project.eqlInvolvementTime,
        coordinates: project.coordinates,
        isDemo: project.isDemo,
        isArchived: project.isArchived,
        grossFloorArea: project.grossFloorArea,
        projectSize: project.projectSize,
        rentalBudget: project.rentalBudget,
        builderProjectIds: project.builderProjectIds.join(','),
        projectCode: project.projectCode,
        companyId: dotProp.get(project, 'primaryCompany.id', ''),
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <PreventTransitionPrompt
            when={props.dirty}
            title="Discard changes?"
            message="If you leave before saving, your changes will be lost."
          />

          {tunnelFormControl ? (
            <Tunnel id="FormControl">
              <FormControl onClose={onClose} props={props} loading={loading} />
            </Tunnel>
          ) : (
            <FormControl onClose={onClose} props={props} loading={loading} />
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionDivider title="Details" first />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="name"
                label="Project name"
                component={TextField}
                variant="outlined"
                required
                fullWidth
                format={(value) => value && value.trim()}
                formatOnBlur
                validate={requiredValidator}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="builderProjectIds"
                label="Project id(s) (comma-separated if more than one)"
                component={TextField}
                variant="outlined"
                fullWidth
                formatOnBlur
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="projectCode"
                label="Project code"
                component={TextField}
                variant="outlined"
                fullWidth
                formatOnBlur
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="companyId"
                label="Company"
                component={Select}
                formControlProps={{ fullWidth: true, variant: 'outlined' }}
                fullWidth={true}
                disabled
              >
                <MenuItem key="none" value="">
                  <em>None</em>
                </MenuItem>
                {companies.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Field>

              <span className="text-red-600">
                Its not possible to change company on project
              </span>
            </Grid>
            <Grid item xs={12}>
              <SectionDivider title="Dates" />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Field
                name="start"
                label="Begins"
                component={FFDateField}
                variant="outlined"
                required={Boolean(project.start)}
                validate={cantBeRemovedDate}
                fullWidth
                allowNull
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Field
                name="stop"
                label="Ends"
                component={FFDateField}
                variant="outlined"
                required={Boolean(project.stop)}
                validate={cantBeRemovedDate}
                fullWidth
                allowNull
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Field
                name="eqlInvolvementTime"
                label="Began using EqL"
                component={FFDateField}
                variant="outlined"
                required={Boolean(project.eqlInvolvementTime)}
                validate={cantBeRemovedDate}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <SectionDivider title="Calculations" />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Field
                name="projectSize"
                label="Project size (SEK)"
                type="number"
                component={FFAmountField}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Field
                name="rentalBudget"
                label="Rental budget (SEK)"
                type="number"
                component={FFAmountField}
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item lg={4} md={6} xs={12}>
              <Field
                name="grossFloorArea"
                label="Gross floor area"
                component={TextField}
                variant="outlined"
                type="number"
                alignRight
                parse={(value) => (value ? Number(value) : 0)}
                format={(value) => value || ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <b>m²</b>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <SectionDivider title="Location" />
            </Grid>
            <Grid item lg={4} md={12} xs={12}>
              <PickLocationButton />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Field
                name="coordinates.latitude"
                label="Latitude"
                component={TextField}
                variant="outlined"
                type="number"
                step=".000000000001"
                validate={latValidator}
                fullWidth
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Field
                name="coordinates.longitude"
                label="Longitude"
                component={TextField}
                variant="outlined"
                type="number"
                step=".000000000001"
                validate={lngValidator}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <SectionDivider title="Switches" />
            </Grid>

            <Grid item md={6} xs={12} container justify="center">
              <Field
                name="isDemo"
                label="Demo project"
                component={FFSwitch}
                type="checkbox"
              />
            </Grid>
            <Grid item md={6} xs={12} container justify="center">
              <Field
                name="isArchived"
                label="Archived project"
                component={FFSwitch}
                type="checkbox"
              />
            </Grid>

            {error && (
              <Grid item xs={12}>
                <pre style={{ overflow: 'scroll' }}>
                  {JSON.stringify(error, null, 2)}
                </pre>
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </Form>
  )
}

export default EditProjectForm
