import BaseTable, { Column } from 'react-base-table'
import 'react-base-table/styles.css'
import './styles.css'
import useFormatAmount from '~/lib/useFormatAmount'
import moment from 'moment'

const Amount = ({ children }) => {
  const formatAmount = useFormatAmount()
  return formatAmount(children)
}

const stringRenderer = ({ className, cellData }) => (
  <div title={cellData} className={className}>
    {cellData}
  </div>
)
const booleanRenderer = ({ className, cellData }) => (
  <div className={className}>{cellData ? '✅' : '❌'}</div>
)
const dateRenderer = ({ className, cellData }) => (
  <div title={cellData ? cellData : '-'} className={className}>
    {cellData ? moment(cellData).format('YYYY-MM-DD') : '-'}
  </div>
)

const amountRenderer = ({ className, cellData }) => (
  <div title={cellData} className={className}>
    <Amount>{cellData}</Amount>
  </div>
)

const renderers = {
  string: stringRenderer,
  boolean: booleanRenderer,
  date: dateRenderer,
  amount: amountRenderer,
}

const TableCell = (cellProps) => {
  const type = cellProps.column.type || 'string'
  const renderer = renderers[type] || renderers.string

  return renderer(cellProps)
}

const components = {
  TableCell,
}

const DataGrid = ({ data, width, height, columns, ...rest }) => {
  return (
    <BaseTable
      data={data}
      width={width}
      height={height}
      columns={columns}
      components={components}
      {...rest}
    />
  )
}

export default DataGrid
