import { Divider, Typography } from '@material-ui/core'

const SectionDivider = ({ title, first, ...props }) => {
  return (
    <div
      css={{
        position: 'relative',
        margin: '30px 0 5px 0',
        userSelect: 'none',
        '.section-title': {
          position: 'absolute',

          transform: 'translate(-50%, -50%)',
          left: '50%',
          fontSize: '12px',
          background: 'white',
          padding: '0 10px',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.25)',
        },
      }}
      style={first ? { marginTop: '5px' } : null}
    >
      <Typography className="section-title">{title}</Typography>
      <Divider {...props} />
    </div>
  )
}

export default SectionDivider
