import moment from 'moment'
import dotProp from 'dot-prop'

export const dateCellDataGetter = ({ rowData, dataKey }) => {
  const date = dotProp.get(rowData, dataKey)
  if (!date) return null
  return moment(date).format('YYYY-MM-DD')
}

export const dotPropCellDataGetter = ({ rowData, dataKey }) =>
  dotProp.get(rowData, dataKey)
