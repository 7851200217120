import { useMutation, gql } from '@apollo/client'

const UpdateProjectItemsMutation = gql`
  mutation UpdateProjectItems(
    $ids: [ID!]!
    $updates: UpdateProjectItemsInput!
  ) {
    updateProjectItems(ids: $ids, updates: $updates) {
      success
      message
      projectItem {
        id
      }
    }
  }
`
const useUpdateProjectItems = () => {
  const [updateProjectItems, { loading }] = useMutation(
    UpdateProjectItemsMutation,
  )

  return { updateProjectItems, loading }
}

export default useUpdateProjectItems
