import { TunnelPlaceholder } from 'react-tunnels'
import { Link as RouterLink } from 'react-router-dom'
import { Breadcrumbs as MuiBreadcrumbs, Link } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const StyledBreadcrumbs = withStyles({
  root: {
    color: 'white',
    '& ol li:last-child a': { color: 'white' },
    '& a': { color: 'rgba(255,255,255,.8)', '&:hover': { color: 'white' } },
    fontSize: '14px',
  },
})(MuiBreadcrumbs)
const Breadcrumbs = () => {
  return (
    <TunnelPlaceholder id="breadcrumb" multiple>
      {({ items }) => (
        <StyledBreadcrumbs aria-label="Breadcrumb" maxItems={5} separator="›">
          {items.map(
            ({ children, to, component: Component, ...props }, index) => {
              if (Component) {
                return (
                  <Component key={index} to={to} {...props}>
                    {children}
                  </Component>
                )
              }
              if (!children) return null
              return (
                <Link key={index} to={to} component={RouterLink}>
                  {children}
                </Link>
              )
            },
          )}
        </StyledBreadcrumbs>
      )}
    </TunnelPlaceholder>
  )
}

export default Breadcrumbs
