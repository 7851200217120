import React, { useState } from 'react'
import {
  Button,
  ButtonProps,
  Checkbox,
  Dialog,
  DialogProps,
  FormControlLabel,
} from '@material-ui/core'

export function ConfirmationDialog({
  variant = 'alert',
  ButtonComponent,
  buttonProps,
  buttonTitle,
  dialogTitle,
  dialogContent,
  dialogProps,
  includeCheckboxConfirmation,
  onConfirm,
  onClose,
  onOpen,
}: {
  variant?: 'alert' | 'default'
  ButtonComponent?: React.ComponentType<any>
  buttonProps?: ButtonProps
  buttonTitle: string
  dialogTitle: string
  dialogContent: React.ReactNode | string
  dialogProps?: DialogProps
  includeCheckboxConfirmation?: boolean
  onConfirm: () => void
  onClose?: () => void
  onOpen?: () => void
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const onDialogOpen = () => {
    onOpen && onOpen()
    setIsDialogOpen(true)
  }

  const onDialogClose = () => {
    onClose && onClose()
    setIsDialogOpen(false)
  }

  return (
    <>
      {ButtonComponent ? (
        <ButtonComponent onClick={onDialogOpen} />
      ) : (
        <Button
          onClick={onDialogOpen}
          color={variant === 'alert' ? 'inherit' : 'primary'}
          variant={variant === 'alert' ? 'contained' : 'outlined'}
          style={
            variant === 'alert' && !buttonProps?.disabled
              ? {
                  color: 'white',
                  borderColor: '#CC2200',
                  backgroundColor: '#CC2200',
                }
              : undefined
          }
          {...buttonProps}
        >
          {buttonTitle}
        </Button>
      )}

      <Dialog open={isDialogOpen} onClose={onDialogClose} {...dialogProps}>
        <div className="flex flex-col gap-12 px-6 py-8">
          <h2 className="m-0">{dialogTitle}</h2>
          <div className="flex flex-col gap-4">
            <p className="m-0 text-base">{dialogContent}</p>
            {includeCheckboxConfirmation ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={(event) => {
                      setIsChecked(event.target.checked)
                    }}
                  />
                }
                label="I understand that this action cannot be undone."
              />
            ) : null}
          </div>
          <div className="flex items-center self-end gap-4">
            <Button onClick={onDialogClose}>Cancel</Button>
            <Button
              variant="contained"
              color={variant === 'alert' ? 'inherit' : 'primary'}
              style={
                variant === 'alert' &&
                (includeCheckboxConfirmation ? isChecked : true)
                  ? {
                      color: 'white',
                      borderColor: '#CC2200',
                      backgroundColor: '#CC2200',
                    }
                  : undefined
              }
              disabled={includeCheckboxConfirmation ? !isChecked : false}
              onClick={onConfirm}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  )
}
