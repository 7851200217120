import { gql } from '@apollo/client'

export const paginatedGlobalItems = gql`
  query paginatedGlobalItems(
    $first: Int!
    $after: String
    $searchString: String
  ) {
    globalItems(first: $first, after: $after, searchString: $searchString) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
          images(settings: { width: 100, height: 100 }) {
            id
            url
          }
        }
      }
    }
  }
`
