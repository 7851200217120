import { useMutation, gql } from '@apollo/client'

const UPDATE_GLOBAL_ITEM = gql`
  mutation updateGlobalItem($input: UpdateGlobalItemInput!) {
    updateGlobalItem(input: $input) {
      success
      message
      globalItem {
        id
        name
        images(settings: { width: 100, height: 100 }) {
          id
          url
        }
      }
    }
  }
`

const useUpdateGlobalItem = () => {
  const [mutate] = useMutation(UPDATE_GLOBAL_ITEM)

  const updateGlobalItem = async ({ id, name, images }) => {
    const {
      data: {
        updateGlobalItem: { success, message, globalItem },
      },
    } = await mutate({ variables: { input: { id, name, images } } })

    return { success, message, globalItem }
  }

  return [updateGlobalItem]
}

export default useUpdateGlobalItem
