import { useQuery } from '@apollo/client'
import { paginatedGlobalItems } from './queries'
import dotProp from 'dot-prop'

const defaultLimit = 50
const useGlobalItems = ({ searchString = '', first = defaultLimit } = {}) => {
  const { data, loading, fetchMore, refetch } = useQuery(paginatedGlobalItems, {
    fetchPolicy: 'cache-and-network',
    variables: { first, searchString },
    notifyOnNetworkStatusChange: true,
  })

  const hasNextPage = dotProp.get(
    data,
    'globalItems.pageInfo.hasNextPage',
    false,
  )

  const loadNextPage = () =>
    fetchMore({
      // note this is a different query than the one used in the
      // Query component
      variables: {
        after: data.globalItems.pageInfo.endCursor,
        first,
        searchString,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.globalItems.edges
        const pageInfo = fetchMoreResult.globalItems.pageInfo

        return newEdges.length
          ? {
              // Put the new comments at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              globalItems: {
                __typename: previousResult.globalItems.__typename,
                edges: [...previousResult.globalItems.edges, ...newEdges],
                pageInfo,
              },
            }
          : previousResult
      },
    })

  const globalItems = dotProp
    .get(data, 'globalItems.edges', [])
    .map((edge) => edge.node)
  return [
    globalItems,
    { loadNextPage, hasNextPage, refetch, isNextPageLoading: loading, loading },
  ]
}

export default useGlobalItems
