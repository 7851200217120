import { Query } from '@apollo/client/react/components'

import { GET_PROJECT } from './queries'
import dotProp from 'dot-prop'

export default function withProject(WrappedComponent, { skipLoading } = {}) {
  return (props) => {
    const { projectId } = props
    return (
      <Query query={GET_PROJECT} variables={{ projectId }} skip={!projectId}>
        {({ data, loading, client, error, refetch }) => {
          if (!skipLoading && (!data || loading)) return null

          return (
            <WrappedComponent
              project={dotProp.get(data, 'project')}
              refetch={refetch}
              {...props}
              error={error}
              loading={loading || props.loading}
            />
          )
        }}
      </Query>
    )
  }
}
