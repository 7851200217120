import { gql } from '@apollo/client'
export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      success
      message
      user {
        id
        name
        firstName
        lastName
        phoneNumbers
        email
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        name
        isSuperAdmin
        phoneNumbers
        email
      }
    }
  }
`
