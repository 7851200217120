import { gql } from '@apollo/client'
import { FullProjectFragment } from './fragments'

export const DELETE_PROJECT = gql`
  mutation deleteProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      id
    }
  }
`

export const UPDATE_PROJECT = gql`
  mutation updateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      project {
        ...FullProjectFragment
      }
    }
  }
  ${FullProjectFragment}
`
