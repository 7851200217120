import { CircularProgress, Grid } from '@material-ui/core'
const Loading = () => {
  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      style={{ minHeight: '580px' }}
    >
      <Grid item xs={12} container justify="center">
        <CircularProgress />
      </Grid>
    </Grid>
  )
}

export default Loading
