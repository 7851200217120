import { gql } from '@apollo/client'

export const PROJECT_USER = gql`
  query ProjectUser($projectId: ID!, $userId: ID!) {
    project(id: $projectId) {
      id
      name
      userConnection(userId: $userId) {
        projectId
        userId
        isMuted
        user {
          id
          name
        }
      }
    }
  }
`

export const PROJECT_USER_NOTIFICATIONS_PREVIEW = gql`
  query ProjectUser($projectId: ID!, $userId: ID!) {
    project(id: $projectId) {
      id
      name
      userConnection(userId: $userId) {
        projectId
        userId
        isMuted
        user {
          id
          name
          language
          notificationSettings {
            id
            type
            title
            description
            days {
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            }
            time
            email
            push
          }
        }
        projectOverviewEmail: notificationPreview(
          templateType: PROJECT_OVERVIEW
          channel: EMAIL
        ) {
          title
          body
          bodyText
          skip
        }
        projectOverviewPush: notificationPreview(
          templateType: PROJECT_OVERVIEW
          channel: PUSH
        ) {
          body
          skip
        }
        dueItemsReminderEmail: notificationPreview(
          templateType: DUE_ITEMS_REMINDER
          channel: EMAIL
        ) {
          title
          body
          bodyText
          skip
        }
        dueItemsReminderPush: notificationPreview(
          templateType: DUE_ITEMS_REMINDER
          channel: PUSH
        ) {
          body
          skip
        }
      }
    }
  }
`
