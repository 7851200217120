const formatError = (error) => {
  try {
    const formattedErrors = error.graphQLErrors
      .map(({ message }) => {
        return message
      })
      .filter((message) => message.includes('JSON validation failed:'))
      .map((str) => {
        const errors = JSON.parse(
          str.replace('JSON validation failed:', '').trim(),
        )

        return errors
      })
      .reduce((errors, moreErrors) => [...errors, ...moreErrors], [])

    const formErrors = {}
    for (const fieldError of formattedErrors) {
      if (fieldError.field) {
        const key = fieldError.field.replace(/^data\./, '')
        formErrors[key] = fieldError.message
      }
    }

    return formErrors
  } catch (err) {
    console.warn('Error formatting error', err, error)
  }
}
export default formatError
