import { useMutation } from '@apollo/client'
import { UPDATE_PROJECT } from '../mutations'
import { useSnackbar } from 'notistack'
import dotProp from 'dot-prop'
import formatError from '~/lib/formatError'

const removeEmpty = (obj) => {
  const clean = {}

  for (const [key, value] of Object.entries(obj)) {
    if (value !== null || key === 'companyId') {
      if (value instanceof Object) {
        clean[key] = removeEmpty(value)
      } else {
        clean[key] = value
      }
    }
  }

  return clean
}

const formatProjectUpdates = (updates) => {
  let cleanedUpdates = {
    ...updates,
    builderProjectIds: Object.values(
      updates.builderProjectIds
        .split(',')
        .map((id) => id.trim())
        .filter(Boolean),
    ),
  }

  if (updates.coordinates && updates.coordinates.latitude) {
    cleanedUpdates = {
      ...cleanedUpdates,
      coordinates: {
        latitude: Number(updates.coordinates.latitude),
        longitude: Number(updates.coordinates.longitude),
      },
    }
  } else {
    cleanedUpdates = {
      ...cleanedUpdates,
      coordinates: null,
    }
  }

  return cleanedUpdates
}

const useUpdateProject = () => {
  const [updateProjectMutation, apolloBag] = useMutation(UPDATE_PROJECT)
  const { enqueueSnackbar } = useSnackbar()

  const updateProject = async (projectId, updates) => {
    try {
      const { error, data } = await updateProjectMutation({
        variables: {
          input: {
            id: projectId,
            ...formatProjectUpdates(removeEmpty(updates)),
          },
        },
      })
      const project = dotProp.get(data, 'updateProject.project')
      const message = project
        ? `Project ${project.name} saved`
        : 'Project saved'
      enqueueSnackbar(message, { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(`Error: ${error}`, { variant: 'error' })
      console.error(error)
      return formatError(error)
    }
  }

  return [updateProject, apolloBag]
}

export default useUpdateProject
