import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import FFDropArea from '~/components/Mui/FFDropArea'
import useCreateGlobalItem from './useCreateGlobalItem'
import { Button, LinearProgress } from '@material-ui/core'

import GlobalItemImage from '~/components/GlobalItem/GlobalItemImage'
import uploadImage from '~/lib/uploadImage'

const CreateGlobalItemForm = ({ name, onDone }) => {
  const [createGlobalItem] = useCreateGlobalItem()
  const onSubmit = async (values, form) => {
    const { name, files } = values
    const images = await Promise.all(files.map(uploadImage))
    const { success, message, globalItem } = await createGlobalItem({
      name,
      images,
    })
    if (success) {
      if (onDone) onDone(globalItem)
    } else {
      throw new Error(`Error creating global item: ${message}`)
    }
  }
  return (
    <Form initialValues={{ name, files: [] }} onSubmit={onSubmit}>
      {(props) => {
        return (
          <form onSubmit={props.handleSubmit}>
            <Field
              name="name"
              label="Name"
              component={TextField}
              variant="outlined"
              fullWidth
              autoFocus
            />
            <div style={{ height: '15px' }} />
            <Field name="files" component={FFDropArea} preserve />
            <div style={{ height: '15px' }} />
            {props.values.files.map((file) => (
              <GlobalItemImage
                onDelete={() =>
                  props.form.change(
                    'files',
                    props.values.files.filter(
                      (f) => f.preview !== file.preview,
                    ),
                  )
                }
                key={file.preview}
                url={file.preview}
                width="200px"
              />
            ))}
            <div style={{ height: '15px' }} />
            {props.submitting && <LinearProgress />}
            <div style={{ height: '15px' }} />
            <Button
              variant="contained"
              type="submit"
              color="secondary"
              disabled={props.submitting}
              fullWidth
            >
              Create
            </Button>
          </form>
        )
      }}
    </Form>
  )
}

export default CreateGlobalItemForm
