import { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import DynamicJsonView from 'react-json-tree'

const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#0f202d',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#2a7ed3',
  base0E: '#ae81ff',
  base0F: '#cc6633',
}

export default class RawJSON extends Component {
  state = { open: false }
  static propTypes = {
    data: PropTypes.any,
  }
  toggle = () => this.setState(({ open }) => ({ open: !open }))
  close = () => this.setState({ open: false })
  open = () => this.setState({ open: true })

  renderTitle() {
    const { data } = this.props
    if (!data || typeof data !== 'object' || !data['__typename']) return
    const type = data['__typename']
    return (
      <h2 style={{ fontWeight: 'light', color: 'white' }}>
        {type} {data.id}
      </h2>
    )
  }
  render() {
    const { label } = this.props
    const { open } = this.state
    return (
      <div>
        <Button
          color="primary"
          disabled={!this.props.data || open}
          onClick={this.open}
        >
          JSON Explorer{label ? ` (${label})` : null}
        </Button>
        <Dialog open={open} onClose={this.close} maxWidth="md">
          {/* <pre style={{ overflow: 'auto', maxHeight: '100px' }}>
            {JSON.stringify(this.props.json, null, 2)}
          </pre> */}
          <div
            style={{
              padding: '50px',
              height: '90vh',
              width: '800px',
              backgroundColor: theme.base00,
              overflow: 'scroll',
            }}
          >
            {this.renderTitle()}
            <DynamicJsonView
              data={this.props.data}
              theme={theme}
              invertTheme={false}
              style={{ height: '100%', width: '100%' }}
              getItemString={(type, data, itemType, itemString) => (
                <span>
                  {itemType} {itemString}
                  {data && typeof data === 'object' && data.url ? (
                    <img src={data.url} width={200} />
                  ) : null}
                </span>
              )}
            />
          </div>
          <style jsx global>{`
            .copy-icon {
              display: none !important;
            }
          `}</style>
        </Dialog>
      </div>
    )
  }
}
