import request from 'superagent'

const uploadImage = async (input) => {
  const file = input.original || input
  const req = request.post(process.env.GATSBY_UPLOAD_ENDPOINT).withCredentials()

  req.attach(file.name.replace('.jpeg', '.jpg'), file)

  return new Promise((resolve, reject) => {
    req.end((error, res) => {
      if (error || !res.ok) {
        console.error('Oh no! Error: ' + JSON.stringify(error))
        reject(error)
      } else {
        const [image] = res.body.files
        resolve(image.id)
      }
    })
  })
}

export default uploadImage
