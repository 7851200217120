import { useState } from 'react'

export default function withSortState(WrappedComponent) {
  return (props) => {
    const [sortState, setSortState] = useState({
      sortOrder: 'description',
      reverseSort: false,
    })
    return (
      <WrappedComponent
        sortState={sortState}
        setSortState={setSortState}
        {...props}
      />
    )
  }
}
