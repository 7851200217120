import { Fragment } from 'react'

import {
  ViewHeadline as TableListIcon,
  ViewGrid as CardListIcon,
  MapMarker as MapIcon,
} from 'mdi-material-ui'
import Tooltip from '@material-ui/core/Tooltip'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

export const TABLE_VIEWMODE = 'table'
// export const CARDS_VIEWMODE = 'cards'
export const MAP_VIEWMODE = 'map'

const ViewMode = ({ viewMode, setQuery }) => {
  return (
    <Fragment>
      <ToggleButtonGroup size="large">
        <Tooltip title="View projects as table" placement="bottom">
          <ToggleButton
            value="table"
            selected={viewMode === TABLE_VIEWMODE}
            onClick={() => setQuery({ viewMode: TABLE_VIEWMODE })}
          >
            <TableListIcon />
          </ToggleButton>
        </Tooltip>

        {/* <Tooltip title="View projects as cards" placement="bottom">
          <ToggleButton
            value="cards"
            selected={viewMode === CARDS_VIEWMODE}
            onClick={() => setQuery({ viewMode: CARDS_VIEWMODE })}
          >
            <CardListIcon />
          </ToggleButton>
        </Tooltip> */}

        <Tooltip title="View projects on a map" placement="bottom">
          <ToggleButton
            value="map"
            selected={viewMode === MAP_VIEWMODE}
            onClick={() => setQuery({ viewMode: MAP_VIEWMODE })}
          >
            <MapIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    </Fragment>
  )
}

export default ViewMode
