import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import FFDropArea from '~/components/Mui/FFDropArea'
import useUpdateGlobalItem from './useUpdateGlobalItem'
import { Button, LinearProgress } from '@material-ui/core'
import GlobalItemImage from '~/components/GlobalItem/GlobalItemImage'
import partition from 'lodash.partition'
import uploadImage from '~/lib/uploadImage'

const EditGlobalItemForm = ({ globalItem, onDone }) => {
  const [updateGlobalItem] = useUpdateGlobalItem()

  const onSubmit = async (values, form) => {
    const { name, files } = values
    const [existing, needsUpload] = partition(values.files, 'uploaded')
    const uploaded = await Promise.all(needsUpload.map(uploadImage))

    const images = [...existing.map((image) => image.id), ...uploaded]

    const {
      success,
      message,
      globalItem: updatedGlobalItem,
    } = await updateGlobalItem({
      id: globalItem.id,
      name,
      images,
    })
    if (success) {
      if (onDone) onDone(updatedGlobalItem)
    } else {
      throw new Error(`Error creating global item: ${message}`)
    }
  }

  return (
    <Form
      initialValues={{
        name: globalItem.name,
        files: globalItem.images.map((image) => ({
          id: image.id,
          preview: image.url,
          uploaded: true,
        })),
      }}
      onSubmit={onSubmit}
    >
      {(props) => {
        return (
          <form onSubmit={props.handleSubmit}>
            <Field
              name="name"
              label="Name"
              component={TextField}
              variant="outlined"
              fullWidth
            />
            <div style={{ height: '15px' }} />
            <Field name="files" component={FFDropArea} preserve />
            <div style={{ height: '15px' }} />
            {props.values.files.map((file) => (
              <GlobalItemImage
                onDelete={() =>
                  props.form.change(
                    'files',
                    props.values.files.filter(
                      (f) => f.preview !== file.preview,
                    ),
                  )
                }
                key={file.preview}
                url={file.preview}
                width="200px"
              />
            ))}
            <div style={{ height: '15px' }} />
            {props.submitting && <LinearProgress />}
            <div style={{ height: '15px' }} />
            <Button
              variant="contained"
              type="submit"
              color="secondary"
              disabled={props.submitting}
              fullWidth
            >
              Save
            </Button>
          </form>
        )
      }}
    </Form>
  )
}

export default EditGlobalItemForm
