import { Dialog } from '@material-ui/core'
import CreateProjectForm from './Form'

const CreateProjectDialog = ({
  companyId,
  name,
  isDemo,
  isArchived,
  open,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <CreateProjectForm
        companyId={companyId}
        name={name}
        isDemo={isDemo}
        isArchived={isArchived}
        onClose={onClose}
      />
    </Dialog>
  )
}

export default CreateProjectDialog
