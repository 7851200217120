import { CircularProgress, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
const Loading = ({ isLoadingAll, cancelLoadAll }) => {
  const { t } = useTranslation('items')
  return (
    <div
      css={{
        position: 'absolute',
        bottom: '15px',
        width: '100%',
        pointerEvents: isLoadingAll ? null : 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        css={{
          color: '#FFF',
          backgroundColor: '#145769',
          fontWeight: '600',
          padding: '12px 24px',
          borderRadius: '5px',
          display: 'flex',

          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress
          style={{ color: '#55C484', marginRight: '15px' }}
          size={24}
        />{' '}
        {isLoadingAll ? t('loading_all_rows') : t('loading_more_rows')}
        {cancelLoadAll && (
          <Button
            style={{ marginLeft: '15px' }}
            onClick={cancelLoadAll}
            variant="contained"
            color="secondary"
          >
            {t('cancel')}
          </Button>
        )}
      </div>
    </div>
  )
}

export default Loading
