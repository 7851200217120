import { useQuery } from '@apollo/client'
import { GET_GLOBAL_ITEM } from './queries'
import dotProp from 'dot-prop'

const useGlobalItem = (id) => {
  const { data, loading } = useQuery(GET_GLOBAL_ITEM, {
    skip: !id,
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })

  const globalItem = dotProp.get(data, 'globalItem')

  return { globalItem, loading }
}

export default useGlobalItem
