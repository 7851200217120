import { Query } from '@apollo/client/react/components'
import { CircularProgress } from '@material-ui/core'

import dotProp from 'dot-prop'
import { GET_COMPANIES } from './queries'

export default function withCompanies(
  WrappedComponent,
  { skipLoading, query } = {},
) {
  return (props) => {
    return (
      <Query query={query || GET_COMPANIES}>
        {({ data, loading, client, ...rest }) => {
          if (loading) {
            if (skipLoading) return null

            return (
              <div className="flex items-center justify-center w-full h-full">
                <CircularProgress />
              </div>
            )
          }

          if (!data) return null

          const companies = dotProp.get(data, 'companies', [])

          return <WrappedComponent companies={companies} {...props} />
        }}
      </Query>
    )
  }
}
